// -*- coding: utf-8 -*-

import React from 'react';
import { Link, useLocation } from 'react-router-dom';

const Breadcrumbs = (props) => {

  let location = useLocation();
  //console.log(location.pathname, "location.pathname");
  const urlParts = (location.pathname !== "/") ? location.pathname.split('/') : [""];
  const urlSearch = location.search;
  const urlLastPart = urlParts[urlParts.length-1];
  //console.log(location, "location");
  //console.log(urlParts, "urlParts");
  //console.log("urlLastPart", urlLastPart, urlParts.length-1);

  //fonction recursive
  function breadcrumbsItemBuild(localUrlParts){
    if (localUrlParts.length === 1){
      if (urlParts[0] === ""){
        //console.log("Home", localUrlParts.length);
        return [(<BreadcrumbsItem key="Home" urlLastPart={urlLastPart} urlSearch={urlSearch} urlNow="/" currentElement="Home"/>)];
      }else{
        //console.log("Next", localUrlParts.length);
        const urlNow = localUrlParts[0];
        return [(<BreadcrumbsItem key={urlNow} urlLastPart={urlLastPart} urlSearch={urlSearch} urlNow={urlNow} currentElement={urlNow}/>)];
      }
    }
    else{
      //console.log("Recurse", localUrlParts.length);
      const localCopyUrlParts = Array.from(localUrlParts);
      const currentElement = localCopyUrlParts.shift();
      //console.log("localCopyUrlParts", localCopyUrlParts);
      const urlNow = localUrlParts.reverse().join("/");
      const element = (<BreadcrumbsItem key={currentElement} urlLastPart={urlLastPart} urlSearch={urlSearch} urlNow={urlNow} currentElement={currentElement}/>);
      return breadcrumbsItemBuild(localCopyUrlParts).concat(" / ").concat(element);
    }
  }

  return (
    <div className="breadcrumbs">
      { breadcrumbsItemBuild(urlParts.reverse()) }
    </div>
  );
};

const BreadcrumbsItem = ({ urlLastPart, urlSearch, urlNow, currentElement }) => {
  const doLink = ! ['Policies', 'Support', 'Info'].includes(currentElement)
  const isLast = currentElement === urlLastPart && !urlSearch;
  const isAuth = currentElement.startsWith('Auth');
  const displayName = isAuth ? currentElement.substr(4, 99) : currentElement;
  //console.log(`currentElement=${currentElement}, displayName=${displayName}`);

  if (isLast || !doLink)
    return (
      <span className={isLast ? 'breadcrumb-active' : undefined} id={currentElement}>
        {displayName}
      </span>
    );
  else
    return (
      <span id={currentElement}>
        <Link to={urlNow}>{displayName}</Link>
      </span>
    );
}

export default Breadcrumbs;
