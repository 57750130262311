// -*- coding: utf-8 -*-

import React, {useState, useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import FooterSupport from '../comp/FooterSupport';
import HeaderUserInfo from '../comp/HeaderUserInfo';

import { useAuth0 } from "../auth/react-auth0-wrapper";

import { waveDownload, getDocCard, getUserDownloadedDocument, addDate } from '../lib-util.js';
import Moment from 'moment';

const DownloadHistory = ({waves, hideSearch, documentMetadata, photos_map, children, footer_support}) => {
  const { userProfile, getTokenSilently } = useAuth0();
  const [getErrorMsg, setErrorMsg] = useState();
  const [dataDownloaded, setDataDownloaded] = useState([]);

  //get all data from the pdf data_downloaded repository to build a card list
  const nb_img_per_line = 4;
  const min_nb_wave_on_page = 3;

  function compareDate(a, b) {
    if (a.downloadDate < b.downloadDate) {
      return 1;
    }
    if (a.downloadDate > b.downloadDate) {
      return -1;
    }
    // a must be equal to b
    return 0;
  }

  useEffect(() => {
    async function getDataDownloaded(){
      Moment.locale('en');

      try{
        const token = await getTokenSilently();

        if (!userProfile)
        {
          setErrorMsg("User's account incomplete.");
          return [];
        }
        const result = await getUserDownloadedDocument(token, userProfile)
        .then(dataDownloaded => {
          setErrorMsg();

          //console.log("getUserDownloadedDocument");
          //console.log(dataDownloaded);
          //console.log(documentMetadata);
          if (!dataDownloaded){
            return []
          }
          const dataDownloadedDocKeyA = dataDownloaded.map(dd=>[dd.docKey, dd.downloadDate]);

          //console.log(dataDownloadedDocKeyA);
          const dataDownloadedDocKey = new Map(dataDownloadedDocKeyA);

          const dataDownloadedDoc = documentMetadata.filter(d => dataDownloadedDocKey.has(d.docKey)).map(d => addDate(Moment, d, dataDownloadedDocKey.get(d.docKey)));
          return dataDownloadedDoc.sort(compareDate);
        })
        .catch(function (error) {
            //console.log(error);
            setErrorMsg(error.message)
        });
        setDataDownloaded(result);

      } catch (error) {
        //console.error(error);
        setErrorMsg(error.message)
      }

      return [];
    }

    getDataDownloaded();
  }, [documentMetadata, getTokenSilently, userProfile]);

  //console.log("dataDownloaded");
  //console.log(dataDownloaded);

  function get_obj(i){
    const idx = i / 3

    if (dataDownloaded && dataDownloaded.length >0 && idx < dataDownloaded.length){
      const obj = getDocCard(nb_img_per_line, dataDownloaded, photos_map);

      return obj[idx]
    }
    return
  }

  const nb_wave_to_display = dataDownloaded ? Math.max(Math.ceil(dataDownloaded.length/nb_img_per_line)*3, min_nb_wave_on_page) : 0;

  //const wave_footer = waves.footer;

  const text = (
    <React.Fragment>
      <HeaderUserInfo/>
      {!userProfile && (
        <Row>
          <Col xs={2}/>
          <Col xs={10}>
            <div className="user_account_error">{getErrorMsg}</div>
          </Col>
        </Row>
      )}
      {waveDownload(3, nb_wave_to_display, waves, get_obj, 40)}
      {footer_support ?
        (<FooterSupport/>):null
      }
    </React.Fragment>
  );

  return text;
}

export default DownloadHistory;
