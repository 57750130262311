// -*- coding: utf-8 -*-

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Mailto from 'react-protected-mailto';
import { ContactMail } from '../const.js';

import Support from './Support';

const Contact = ({waves, hideSearch}) => {

  const text = (
    <Row className="information_header_container">
      <Row className="information_text_header">
        <Col xs={2}/>
        <Col xs={10}>
            CONTACT
        </Col>
      </Row>
      <Row className="wave_container_gap_header"/>
      <Row className="information_text_container_contact">
        <Col xs={2}/>
        <Col xs={9} className="information_text_container information_text_container_contact information_text_scroll">
            <p/>
            <div className="information_text">
            If you need any information, feel free to contact us!
            Please use the address that best suits your needs.
            </div>
            <div className="information_title">
            For questions related to…
            </div>
            <div className="information_text">
            <ul>
              <li>the handling of your account</li>
              <li>the downloading process</li>
            </ul>
            &nbsp;&nbsp; &#x25BA; write us at: <Mailto className="information_important" email='account@gameleon.ch'/>
            </div>

            <div className="information_title">
            For questions related to…
            </div>
            <div className="information_text">
            <ul>
              <li>errors in the content of a PDF file (typos, lay-out, etc…)</li>
            </ul>
            &nbsp;&nbsp; &#x25BA; write us at: <Mailto className="information_important" email='pdf@gameleon.ch'/>
            </div>

            <div className="information_title">
            For questions related to…
            </div>
            <div className="information_text">
            <ul>
              <li>problems with the website</li>
              <li>the malfunctioning of the search tool</li>
              <li>errors in the breadcrumb trail</li>
            </ul>
            &nbsp;&nbsp; &#x25BA; write us at: <Mailto className="information_important" email='webmaster@gameleon.ch'/>
            </div>

            <div className="information_title">
            For questions related to…
            </div>
            <div className="information_text">
            <ul>
              <li>our data protection statement</li>
              <li>the use of your personal data</li>
            </ul>
            &nbsp;&nbsp; &#x25BA; write us at: <Mailto className="information_important" email='data@gameleon.ch'/>
            </div>

            <div className="information_title">
            And for any other questions…
            </div>
            <div className="information_text">
            &nbsp;&nbsp; &#x25BA; You can write us at: <ContactMail/>
            </div>

        </Col>
        <Col xs={1}/>
      </Row>
    </Row>
  );

  return (
    <Support no_extension_color waves={waves} hideSearch={hideSearch} children={text}/>
  );
}

export default Contact;
