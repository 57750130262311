// -*- coding: utf-8 -*-

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import { AddressOneLine } from '../const.js';
import Button from 'react-bootstrap/Button';

const FooterSupport = ({children, footer_style}) => {
  return (
    <Row className="wave_footer_menu" style={footer_style}>
      <Col className="phone_hide" xs={0} sm={1}/>
      <Col xs={5} sm={5}>
        <LinkContainer className="FooterLinkContainer" to="/About"><Button variant="link">ABOUT</Button></LinkContainer>
        <LinkContainer className="FooterLinkContainer" to="/Contact"><Button variant="link">CONTACT</Button></LinkContainer>
        <LinkContainer className="FooterLinkContainer" to="/Support/GeneralInformation"><Button variant="link">SUPPORT</Button></LinkContainer>
        <LinkContainer className="FooterLinkContainer" to="/Policies/GeneralTermsAndConditions"><Button variant="link">POLICIES</Button></LinkContainer>
      </Col>
      <Col xs={7} sm={5}>
        <AddressOneLine/>
      </Col>
      <Col className="phone_hide" xs={0} sm={1}>
      </Col>
    </Row>
  );
}
export default FooterSupport;
