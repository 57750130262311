// -*- coding: utf-8 -*-

//import React, { useEffect } from "react";
import Row from 'react-bootstrap/Row';
import Button from 'react-bootstrap/Button';
import { useAuth0 } from "../auth/react-auth0-wrapper";

import {LinkContainer} from 'react-router-bootstrap';
import { useLocation } from "react-router-dom";

/*
const ReloadUser = () => {
  const { loginWithRedirect, setReloadUserProfile } = useAuth0();

  return (
      <>
        <Button className="LinkContainer header_button" variant="light"
          onClick={() => loginWithRedirect({}) }>Relogin</Button>
        <Button className="LinkContainer header_button" variant="light"
          onClick={() => setReloadUserProfile({}) }>Reload user</Button>
      </>
  );
}
*/

const AuthNavbar = () => {
  const { isAuthenticated, loginWithRedirect, logout, loading, user, userProfile } = useAuth0();

  //get parameters
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const userVerified = query.get("verified");
  //console.log(`AuthNavbar, userVerified=${userVerified}`);

  if (isAuthenticated && userVerified==="1") {
    //console.log(user, `AuthNavbar, before`);
    //force a relogin to update the user validation status
    logout();
    return undefined;
  }

/*
  useEffect(() => {
    const checkUserEmailVerified = async () => {
      if (isAuthenticated && userVerified==="1") {
        //console.log(user, `AuthNavbar, before`);
        //force a relogin to update the user validation status
        await logout();

        //only trying to get the user do not work
        // getUser()
        // .then(user_local => {
        //   console.log(user_local, `AuthNavbar, after getUser`);
        //   setUser(user_local);
        // })
        // .catch (error => {
        //     //console.error("getUserInfo user");
        //     //console.error(error);
        //     return;
        // });
      }
    }

    checkUserEmailVerified();
  }, [getUser, setUser, isAuthenticated, userVerified, logout]);
*/

  if (!isAuthenticated) {
    return (
        <Button className="LinkContainer header_button" variant="light"
          onClick={() =>
            loginWithRedirect({})
          }
        >LOG IN
        </Button>
    );
  }

  if (loading || !user) {
    return (
      <div className="login_container">
        <div className="login">
          Loading user details
        </div>
      </div>
    );
  }

  //console.log(`AuthNavbar isAuthenticated=${isAuthenticated}, user=${user}, userProfile=${userProfile}`);
  if (isAuthenticated && user && !userProfile) {
    return (
      <div className="login_container">
        <div className="login">
          <LinkContainer className="LinkContainer header_button" to="/AuthAccount">
            <div className="login_name" >{user.name} {user.nickname}</div>
          </LinkContainer>
          <LinkContainer className="LinkContainer header_button" to="/AuthAccount">
            <div className="style_red_bold">User's account incomplete, click Me</div>
          </LinkContainer>

          <Button className="login_LinkContainer header_button" variant="outline-dark" onClick={() => logout()}>LOG OUT</Button>
        </div>
      </div>
    );
  }

  if(isAuthenticated && userProfile){
    var name = userProfile.name;
    name = name && name.trunc(30);
    return (
      <div key={userProfile.login} className="login_container">
        <Row className="login">
          <LinkContainer className="LinkContainer header_button" to="/AuthAccount">
            <span className="login_name" >{name}</span>
          </LinkContainer>
        </Row>
        <Row className="logout">
          <Button className="login_LinkContainer header_button" variant="outline-dark" onClick={() => logout()}>LOG OUT</Button>
        </Row>
      </div>
      )
  }
};

export default AuthNavbar;
