// -*- coding: utf-8 -*-

import { useState } from 'react';
//import { getAllDocumentMetadata } from '../lib-util.js';
import Cookies from 'js-cookie';

function useCart() {
  //https://github.com/fastify/fastify-cookie
  //get cart cookies if it exists
  //console.log("cookieCart");
  const cookieCart = Cookies.get('gameleonCart', { secure: true });
  //console.log(cookieCart);
  const cookieCartJson = typeof cookieCart === 'undefined' ? [] : JSON.parse(cookieCart);
  //console.log(cookieCartJson);

  const [cart, setCart] = useState(cookieCartJson);

  function changeCart(cart){
    setCart(cart);

    const cartJson = JSON.stringify(cart);
    Cookies.set('gameleonCart', cartJson, { expires: 7, secure: true, sameSite: 'lax' })
    //console.log(cart);
  }

  function addToCart(docKey){
    //console.log("addToCart");
    if (! cart.includes(docKey) ){
      const newCart = Array.from(cart);
      newCart.push(docKey)
      changeCart(newCart);
      //console.log(newCart);
    }
  }

  function removeFromCart(docKey){
    //console.log("removeFromCart");
    //console.log(cart);
    const newCart = cart.filter(element => element !== docKey)
    changeCart(newCart);
  }

  function purgeCart(){
    changeCart([]);
  }

  function removeAllDocFromCart(docKeyList){
    //console.log("removeFromCart");
    //console.log(cart);
    const newCart = cart.filter(element => !docKeyList.includes(element))
    changeCart(newCart);
  }

/*
  useEffect(() => {

  }, []);
*/
  //console.log("useServerData documentMetadata");
  //console.log(documentMetadata);

  return [cart, addToCart, removeFromCart, removeAllDocFromCart, purgeCart];
}

export { useCart };
