// -*- coding: utf-8 -*-

import "core-js"; //polyfills for ECMAScript up to 2021
import React, { useEffect } from "react";
import { redirect } from 'react-router-dom';
import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "@auth0/auth0-spa-js";

import Layout from "./Layout";

import PrivateRoute from "./auth/PrivateRoute";
import Wave from './pages/Wave';
import SearchResult from './pages/SearchResult';
import DownloadPdf from './pages/DownloadPdf';

import GeneralInformation from './pages/GeneralInformation';
import GeneralTermsAndConditions from './pages/GeneralTermsAndConditions';
import DataProtectionStatement from './pages/DataProtectionStatement';
import Licensing from './pages/Licensing';

import UserAccount from './pages/UserAccount';
import MyStuff from './pages/MyStuff';
import DownloadHistory from './pages/DownloadHistory';
import ShoppingCart from './pages/ShoppingCart';

import About from './pages/About';
import Contact from './pages/Contact';
//import Test from './pages/Test';


import { getPhotoArray } from './lib-util.js';

import {wave_picture, wave_quick_games, wave_boardgames, wave_module} from './const-wave.js';
import {wave_dark_green, wave_light_green, wave_yellow, wave_orange, wave_dark_violet} from './const-wave-color.js';

import {
  download_build_it_up, download_remember_where, download_have_a_guess, download_down_or_across
  } from './const-download-quick_games.js';
import {
  download_characters, download_scenes, download_any_others
} from './const-download-pictures.js';
import {
    download_crazy_animals_games, download_iamanalien_games, download_absurd_inventions_games, download_secretpizza_games,
    download_treasureisland_games, download_almost_christmas_games, download_so_much_stuff_games, download_who_killed_the_cat_games,
} from './const-download-board_games.js';
import {
    download_crazy_animals, download_dressmeup, download_absurd_inventions, download_so_much_stuff,
} from './const-download-modules.js';

import { useApp } from "./AppContext";

const photos_map_build_it_up = getPhotoArray(download_build_it_up);
const photos_map_remember_where = getPhotoArray(download_remember_where);
const photos_map_have_a_guess = getPhotoArray(download_have_a_guess);
const photos_map_down_or_across = getPhotoArray(download_down_or_across);

const photos_map_characters = getPhotoArray(download_characters);
const photos_map_scenes = getPhotoArray(download_scenes);
const photos_map_any_others = getPhotoArray(download_any_others);

const photos_map_crazy_animals_games = getPhotoArray(download_crazy_animals_games);
const photos_map_iamanalien_games = getPhotoArray(download_iamanalien_games);
const photos_map_absurd_inventions_games = getPhotoArray(download_absurd_inventions_games);
const photos_map_secretpizza_games = getPhotoArray(download_secretpizza_games);
const photos_map_treasureisland = getPhotoArray(download_treasureisland_games);
const photos_map_almost_christmas = getPhotoArray(download_almost_christmas_games);
const photos_map_so_much_stuff_games = getPhotoArray(download_so_much_stuff_games);
const photos_map_who_killed_the_cat_games = getPhotoArray(download_who_killed_the_cat_games);

const photos_map_crazy_animals = getPhotoArray(download_crazy_animals);
const photos_map_dressmeup = getPhotoArray(download_dressmeup);
const photos_map_absurd_inventions = getPhotoArray(download_absurd_inventions);
const photos_map_so_much_stuff = getPhotoArray(download_so_much_stuff);

const photos_map = new Map([
  ...photos_map_build_it_up, ...photos_map_remember_where, ...photos_map_have_a_guess, ...photos_map_down_or_across
  , ...photos_map_characters, ...photos_map_scenes, ...photos_map_any_others
  , ...photos_map_crazy_animals_games, ...photos_map_iamanalien_games, ...photos_map_absurd_inventions_games, ...photos_map_secretpizza_games
  , ...photos_map_treasureisland, ...photos_map_almost_christmas, ...photos_map_so_much_stuff_games, ...photos_map_who_killed_the_cat_games
  , ...photos_map_crazy_animals, ...photos_map_dressmeup, ...photos_map_absurd_inventions, ...photos_map_so_much_stuff
]);

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) { 
    // Update state so the next render will show the fallback UI.    
    return { hasError: true };  }
  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service    
    //console.log(error, errorInfo);
  }
  render() {
    if (this.state.hasError) {      // You can render any custom fallback UI      
      return <h1>Something went wrong.</h1>;    
    }
    return this.props.children; 
  }
}

const AppRouter = () => {
  const { 
    setSearchVisible,
    searchActivated,

    cart, addToCart, removeFromCart, removeAllDocFromCart, purgeCart,
    checkoutUrl, setCheckoutUrl,
    documentMetadata, searchFacets, dataBoughtDoc, setDataBoughtDocReload,
    searchFacetFilterContext,

    hideSearch,
  } = useApp();

  const redirect_loader = async () => {
    if (checkoutUrl) {
      const checkoutUrl_l = checkoutUrl;
      setCheckoutUrl(undefined);
      setDataBoughtDocReload(true);
      return redirect(checkoutUrl_l);
    }
    return null;
  };

  useEffect(()=>{
    //console.log("App searchFacets");

    if (typeof searchFacets !== 'undefined' && typeof searchFacets.activities !== 'undefined')
    {
      //console.log(searchFacets);
      searchFacetFilterContext.initSearchFacets();
    }
    // eslint-disable-next-line
  }, [documentMetadata]);

  const level34Data = { hideSearch: hideSearch, documentMetadata: documentMetadata, addToCart: addToCart, removeFromCart: addToCart, dataBoughtDoc: dataBoughtDoc, setDataBoughtDocReload: setDataBoughtDocReload };

  const router = createBrowserRouter([
    { path: "/", element: <Layout home={true}/> },
    { element: <Layout home={false}/>, errorElement: <ErrorBoundary />, children:[

      { path: "SearchResult", Component: () => <SearchResult waves={wave_dark_violet} documentMetadata={documentMetadata} photos_map={photos_map} searchFacetFilterContext={searchFacetFilterContext}
        setSearchVisible={setSearchVisible} searchActivated={searchActivated}/> },
  
      { path: "ShoppingCart", Component: () => <ShoppingCart waves={wave_dark_violet} documentMetadata={documentMetadata} dataBoughtDoc={dataBoughtDoc} hideSearch={hideSearch} cart={cart} photos_map={photos_map}
        removeFromCart={removeFromCart} removeAllDocFromCart={removeAllDocFromCart} purgeCart={purgeCart} checkoutUrl={checkoutUrl} setCheckoutUrl={setCheckoutUrl}/>,
        loader: redirect_loader
      },
  
      /* UserInfo under login */
      { path: "AuthMyStuff", Component: () => <PrivateRoute path="AuthMyStuff" render={(props) => (
        <MyStuff waves={wave_dark_violet} dataBoughtDoc={dataBoughtDoc} hideSearch={hideSearch} setDataBoughtDocReload={setDataBoughtDocReload}/>)}/>},
      { path: "AuthAccount", Component: () => <PrivateRoute path="AuthAccount" render={(props) => (
        <UserAccount hideSearch={hideSearch} waves={wave_dark_violet}/>)}/>},
      { path: "AuthDownloadHistory", Component: () => <PrivateRoute path="AuthDownloadHistory" render={(props) => (
        <DownloadHistory documentMetadata={documentMetadata} photos_map={photos_map} hideSearch={hideSearch} waves={wave_dark_violet}/>)}/>},
  
      /* niveau 3 & 4 */
    
      /* Pictures */
      { path: "Pictures", children:[
        { path: "", Component: () => <Wave config={wave_picture} waves={wave_orange} hideSearch={hideSearch}/> },
        { path: download_characters.link, Component: () => <DownloadPdf config={download_characters} waves={wave_orange} {...level34Data}/> },
        { path: download_scenes.link, Component: () => <DownloadPdf config={download_scenes} waves={wave_orange} {...level34Data}/> },
        { path: download_any_others.link, Component: () => <DownloadPdf config={download_any_others} waves={wave_orange} {...level34Data}/> },
        ]
      },
      /* QuickGames */
      { path: "QuickGames", children:[
        { path: "", Component: () => <Wave config={wave_quick_games} waves={wave_yellow} hideSearch={hideSearch}/> },
        { path: download_build_it_up.link, Component: () => <DownloadPdf config={download_build_it_up} waves={wave_yellow} {...level34Data}/> },
        { path: download_remember_where.link, Component: () => <DownloadPdf config={download_remember_where} waves={wave_yellow} {...level34Data}/> },
        { path: download_have_a_guess.link, Component: () => <DownloadPdf config={download_have_a_guess} waves={wave_yellow} {...level34Data}/> },
        { path: download_down_or_across.link, Component: () => <DownloadPdf config={download_down_or_across} waves={wave_yellow} {...level34Data}/> },
        ]
      },
      /* BoardGames */
      { path: "BoardGames", children:[
        { path: "", Component: () => <Wave config={wave_boardgames} waves={wave_light_green} hideSearch={hideSearch}/> },
        { path: download_iamanalien_games.link, Component: () => <DownloadPdf config={download_iamanalien_games} waves={wave_light_green} {...level34Data}/> },
        { path: download_crazy_animals_games.link, Component: () => <DownloadPdf config={download_crazy_animals_games} waves={wave_light_green} {...level34Data}/> },
        { path: download_absurd_inventions_games.link, Component: () => <DownloadPdf config={download_absurd_inventions_games} waves={wave_light_green} {...level34Data}/> },
        { path: download_secretpizza_games.link, Component: () => <DownloadPdf config={download_secretpizza_games} waves={wave_light_green} {...level34Data}/> },
        { path: download_treasureisland_games.link, Component: () => <DownloadPdf config={download_treasureisland_games} waves={wave_light_green} {...level34Data}/> },
        { path: download_almost_christmas_games.link, Component: () => <DownloadPdf config={download_almost_christmas_games} waves={wave_light_green} {...level34Data}/> },
        { path: download_so_much_stuff_games.link, Component: () => <DownloadPdf config={download_so_much_stuff_games} waves={wave_light_green} {...level34Data}/> },
        { path: download_who_killed_the_cat_games.link, Component: () => <DownloadPdf config={download_who_killed_the_cat_games} waves={wave_light_green} {...level34Data}/> },
        ]
      },
      /* Modules */
      { path: "Modules", children:[
        { path: "", Component: () => <Wave config={wave_module} waves={wave_dark_green} hideSearch={hideSearch}/> },
        { path: download_crazy_animals.link, Component: () => <DownloadPdf config={download_crazy_animals} waves={wave_dark_green} {...level34Data}/> },
        { path: download_dressmeup.link, Component: () => <DownloadPdf config={download_dressmeup} waves={wave_dark_green} {...level34Data}/> },
        { path: download_absurd_inventions.link, Component: () => <DownloadPdf config={download_absurd_inventions} waves={wave_dark_green} {...level34Data}/> },
        { path: download_so_much_stuff.link, Component: () => <DownloadPdf config={download_so_much_stuff} waves={wave_dark_green} {...level34Data}/> },
        ]
      },    
    
      /* Info */
      { path: "About", Component: () => <About waves={wave_dark_violet} hideSearch={hideSearch}/> },
      { path: "Contact", Component: () => <Contact waves={wave_dark_violet} hideSearch={hideSearch}/> },
    
      /* Support, licensing and other text */
      { path: "Support", children:[
        { path: "GeneralInformation", Component: () => <GeneralInformation waves={wave_dark_violet} hideSearch={hideSearch}/> },
        ]
      },
      { path: "Policies", children:[
        { path: "GeneralTermsAndConditions", Component: () => <GeneralTermsAndConditions waves={wave_dark_violet} hideSearch={hideSearch}/> },
        { path: "DataProtectionStatement", Component: () => <DataProtectionStatement waves={wave_dark_violet} hideSearch={hideSearch}/> },
        { path: "Licensing", Component: () => <Licensing waves={wave_dark_violet} hideSearch={hideSearch}/> },
        ]
      },
      ]
    },
  ])

  return (
    <RouterProvider router={router} />
  )
}

export default AppRouter;
