// -*- coding: utf-8 -*-

import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { useAuth0 } from "../auth/react-auth0-wrapper";

const PrivateRoute = ({ children, path, render, ...rest }) => {
  const { isAuthenticated, loginWithRedirect } = useAuth0();

  useEffect(() => {
    const fn = async () => {
      if (!isAuthenticated) {
        await loginWithRedirect({
          appState: { targetUrl: path }
        });
      }
    };
    fn();
  }, [isAuthenticated, loginWithRedirect, path]);

  //console.log(rest, "PrivateRoute rest");
  if (isAuthenticated === true){
    //console.log(children, "PrivateRoute isAuthenticated");

    if (typeof children !== "undefined"){
      //Route container with children
      //console.log(`PrivateRoute children for ${path}`);
      //console.log(children);
      return children;
    }else{
      //Route Component
      //console.log(`PrivateRoute Component for ${path}`);
      //console.log(component);
      //console.log(path);
      //return component({...rest});
      return render(rest);
      //return <Route path={path} render={(props) => { return component({...props, ...rest}) }  } />;
    }
  }else{
    //console.log("PrivateRoute not isAuthenticated");
    return (<Navigate to="/" />);
  }
};

export default PrivateRoute;
