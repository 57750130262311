// -*- coding: utf-8 -*-

import React, { useState } from "react";
import Table from 'react-bootstrap/Table'
import Moment from 'moment';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';

import { useAuth0 } from "../auth/react-auth0-wrapper";
import { formatDateToString, sortOrderCompare, warningBox, downloadDocumentEvt, getProductName } from '../lib-util.js';

import FooterSupport from '../comp/FooterSupport';
import Support from './Support';

const MyStuff = ({waves, hideSearch, dataBoughtDoc, footer_support, setDataBoughtDocReload, children}) => {

  Moment.locale('en');

  const { user, getTokenSilently, setReloadUserProfile, userProfile } = useAuth0();
  const [getErrorMsg, setErrorMsg] = useState();
  const [show, setShow] = useState(false);

  const doDisplayWarning = (typeof userProfile !== 'undefined') && userProfile.documentWarning;

  //console.log("MyStuff");

  const title = (
    <React.Fragment>
      <Row className="information_header_container">
        <Row className="information_text_header">
          <Col xs={2}/>
          <Col xs={10}>
              MY STUFF
          </Col>
        </Row>
        <Row className="wave_container_gap_header"/>
      </Row>
    </React.Fragment>
  );

  //d.docKey, d.downloadDate, d.orderNumber, d.paymentId, d.costInCredit
  function getBoughtDocument(doc){
    //console.log("getBoughtDocument");
    //console.log(doc);

    return ({
      type : -1,
      seq : doc.paymentId,
      key : doc.docKey,
      productName : getProductName(doc),
      downloadDate : doc.downloadDate ? formatDateToString(new Date(doc.downloadDate)) : '',
      lastDownloadedDate : doc.lastDownloadedDate ? formatDateToString(new Date(doc.lastDownloadedDate)) : '',
      price : doc.costInCredit,
      link : doc.link+"?download="+doc.docKey,
      status : 'bought',
    });
  }

  //mixe credit and download to have the complete history of the user activity
  let allObjects = [];

  if (dataBoughtDoc && dataBoughtDoc.length > 0){
    allObjects.push(dataBoughtDoc.map(getBoughtDocument));
    allObjects = allObjects.flat();
  }
  allObjects.sort(sortOrderCompare)
  //console.log("dataBoughtDoc");
  //console.log(dataBoughtDoc);
  //console.log(allObjects);

  const allObjectsSum = allObjects;

  //console.log(allObjectsSum);

  //const wave_container = waves.wave_container;
  //const footer = waves.footer;

  function getDataFormatted(data){
    return (
      <tr key={data.key} className="information_text">
        <td>{data.seq}</td>
        <td>
          <Link className="link_visible" to={data.link}>{data.productName}</Link>
        </td>
        <td>{data.downloadDate}</td>
        {/*
          <td>{data.price ? `${data.price} CHF` : ''}</td>
        */}
        <td>
          <Button className="download_button" onClick={(evt) =>
            downloadDocumentEvt(evt, doDisplayWarning, data.key, user, getTokenSilently, setErrorMsg, setDataBoughtDocReload, setShow)} variant="light">
            Download
          </Button>
        </td>
        <td>{data.lastDownloadedDate ? data.lastDownloadedDate : 'Not yet'}</td>
      </tr>
    );
  }

  //https://react-bootstrap.github.io/components/table/
  const text = (
    <React.Fragment>
      <Row className="information_text_container_cart">
        <Col xs={1} sm={1} lg={2}/>
        <Col xs={10} sm={10} lg={8} className="information_text_container_base">
            <div className="information_text_container_base">
              <div className="user_account_error">{getErrorMsg}</div>
              <div className="text">
                <Table striped bordered hover responsive="sm" className="text cart">
                  <thead>
                    <tr>
                      <th className="mystuff_seq"># Doc number</th>
                      <th className="cart_name">Product name</th>
                      <th className="cart_date">Selection Date</th>
                      {/*
                        <th className="cart_total_price">Price</th>
                      */}
                      <th className="cart_download">Download</th>
                      <th className="cart_date">Downloaded</th>
                    </tr>
                  </thead>
                  <tbody>
                  {allObjectsSum && allObjectsSum.map(d => getDataFormatted(d))}
                  </tbody>
                </Table>
              </div>
            </div>
        </Col>
        <Col xs={1} sm={1} lg={2}/>
      </Row>
      {show && warningBox(user, getTokenSilently, show, setShow, setReloadUserProfile)}

      {footer_support ?
        (<FooterSupport/>):null
      }
    </React.Fragment>
  );

  return (<Support waveNoLink waves={waves} hideSearch={hideSearch} children={text} title={title}/>);
}

export default MyStuff;
