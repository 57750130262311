// -*- coding: utf-8 -*-

import { useState, useEffect } from 'react';
import { getAllDocumentMetadata, getUserBoughtDocument } from '../lib-util.js';
import Moment from 'moment';

import { useAuth0 } from "../auth/react-auth0-wrapper";

function useServerData() {
  const [documentMetadata, setDocumentMetadata] = useState({});
  const [dataBoughtDoc, setDataBoughtDoc] = useState([]);
  const [dataBoughtDocReload, setDataBoughtDocReload] = useState(true);
  const { user, getTokenSilently, userProfile, loading } = useAuth0();

  Moment.locale('en');

  const sortToTheEnd = ['undetermined', 'No skills', 'No grammar', 'Miscellaneous'];

  function sortOrderCompare(a, b) {
    //a wins b smaller
    if (sortToTheEnd.includes(a)) {
      return 1;
    }
    //b wins, a smaller
    if (sortToTheEnd.includes(b)) {
      return -1;
    }
    return a.localeCompare(b)
  }

  function getLabels(element_name){
    if (!documentMetadata || typeof documentMetadata.flatMap === 'undefined'){
      return []
    }
    const result = Array.from(new Set(documentMetadata.flatMap((e)=>e[element_name])));

    result.sort(sortOrderCompare)

    return result;
  }

  const searchFacets = {
      activities: {
        name: 'Activities',
        labels: getLabels('activities')
      },
      vocabulary: {
        name: 'Vocabulary',
        labels: getLabels('vocabulary')
      },
      grammar: {
        name: 'Grammar',
        labels: getLabels('grammar')
      },
      skills: {
        name: 'Skills & more',
        labels: getLabels('skills')
      },
      time: {
        name: 'Time needed',
        labels: getLabels('time')
        //['5-10 min.', '10-15 min.', '10-20 min.', 'more than 20 min.', 'undetermined']
      },
      languageLevel: {
        name: 'Language level',
        labels: getLabels('languageLevel')
        //['Beginner (approx. A 1.1)', 'False beginner (approx. A 1.2)', 'Pre-intermediate (approx. A 2.1)', 'Intermediate (B.1)']
      },
/*
      taskDifficulty: {
        name: 'Task difficulty',
        labels: getLabels('taskDifficulty')
        //['Easy (level 1 \u2022)', 'Medium (level 2 \u2022\u2022)', 'Difficult (level 3 \u2022\u2022\u2022)']
      },
*/
  };
  //console.log(searchFacets);

  useEffect(() => {
    if (!loading){
      getAllDocumentMetadata()
      .then(documentMetadata => {
        //console.log("useServerData useEffect");
        //console.log(documentMetadata);
        setDocumentMetadata(documentMetadata);
      })
      .catch(function (error) {
        //TODO
        console.log(error);
        //setErrorMsg(error.message)
      });
    }
  }, [loading]);

  //get document bought history
  useEffect(() => {
    async function getDataDocumentBought(){
      try{
        if (!userProfile)
        {
          return [];
        }
        const token = await getTokenSilently();
        getUserBoughtDocument(token, userProfile)
        .then(dataBoughtDoc => {
          //setErrorMsg();

          //console.log("userServerData getDataDocumentBought");
          //console.log(dataBoughtDoc);
          if (!dataBoughtDoc){
            return []
          }
          const docBought = dataBoughtDoc.map(d=>({ docKey: d.docKey,
            downloadDate: Moment(d.downloadDate), lastDownloadedDate: d.lastDownloadedDate ? Moment(d.lastDownloadedDate) : undefined,
            orderNumber: d.orderNumber, paymentId: d.paymentId, costInCredit: d.costInCredit,
            activities: d.activities, title: d.title, text: d.text, link: d.link }));

          //console.log("userServerData getDataDocumentBought ok");
          //console.log(docBought);
          setDataBoughtDoc(docBought);
          setDataBoughtDocReload(false);
          return docBought;
        })
        .catch(function (error) {
          //TODO
          //console.log(error);
          //setErrorMsg(error.message)
        });

      } catch (error) {
        //TODO
        //console.error(error);
        //setErrorMsg(error.message)
      }

      return [];
    }
    if (dataBoughtDocReload){
      getDataDocumentBought();
    }
  }, [getTokenSilently, user, userProfile, dataBoughtDocReload, setDataBoughtDocReload]);

  //console.log("useServerData documentMetadata");
  //console.log(documentMetadata);

  return [ documentMetadata, searchFacets, dataBoughtDoc, setDataBoughtDocReload ];
}

export { useServerData };
