// -*- coding: utf-8 -*-

import {
  warningCardTextStyle, warningCardStyle,
  LicenceNoAdapt,
  //LicenceForAdapt,
  } from './const.js';

import {
    download_crazy_animals, download_absurd_inventions, download_so_much_stuff,
} from './const-download-modules.js';

/************

Board Games

*/
const download_crazy_animals_games = {
  name: 'download_crazy_animals_games',
  link: 'CrazyAnimalsGame',
  header: {
    activity: 'Board Games',
    main: 'Crazy animals',
    text: 'Card games',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 4,
  photos_set: [
    {
      name: 'intro Crazy animals',
      img: require('static/illustrations/4_dragon_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['Both card games involve matching pictures with descriptions. In game 1, students read descriptions to find the corresponding animals. In game 2, students speak about animals to keep their cards.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in each pack.', 'style_text_bigger'],
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    download_crazy_animals.photos_set[5],
    download_crazy_animals.photos_set[6],
  ],
}

const warningSimilar1to2 = ['Game 1 is an alternative to game 2. It is a similar game with a less difficult task.', 'style_red'];
const warningSimilar2to1 = ['Game 2 is an alternative to game 1. It is a similar game with a more difficult task.', 'style_red'];

const download_iamanalien_games =  {
  name: 'download_iamanalien_games',
  link: 'IMAnAlienGame',
  header: {
    activity: 'Board Games',
    main: 'I\'M an alien',
    text: 'Board games',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/5_alien_couleur_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['In this series of board games, students choose an alien identity. They progress on the game board by speaking and making physical descriptions about themselves or other aliens.'+
        ' In game 1, they make simple descriptions. Game 2 is an alternative to game 1 in which students make complex descriptions, using more adjectives.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in each pack.', 'style_text_bigger'],
        ['', 'style_space'],
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 'game1a4',
      samples3: ['3_g1_1', require('static/swipe_PDFs/3_board_games_im_an_alien/Game_1_A4_PDF.png')],
      samples4: [
        ['swipe_g1_3', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_1_A4_3.jpg')],
        ['swipe_g1_1', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_1_A4_1.jpg')],
        ['swipe_g1_2', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_1_A4_2.jpg')],
        ['swipe_g1_4', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_1_A4_4.jpg')],
      ],
      title: 'GAME 1 (A4)',
      textf: [
        ['6 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 identity cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Making simple physical descriptions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar1to2,
        ['', 'style_space'],
        warningCardTextStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'im_an_alien_game_1_A4',
    },
    {
      name: 'game1a3',
      samples3: ['3_g2_1', require('static/swipe_PDFs/3_board_games_im_an_alien/Game_1_A3_PDF.png')],
      samples4: [
        ['swipe_g13_3', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_1_A3_3.jpg')],
        ['swipe_g13_1', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_1_A3_1.jpg')],
        ['swipe_g13_2', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_1_A3_2.jpg')],
      ],
      title: 'GAME 1 (A3)',
      textf: [
        ['5 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 identity cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Making simple physical descriptions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar1to2,
        ['', 'style_space'],
        warningCardTextStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'im_an_alien_game_1_A3',
    },
    { name: 'blk1'},
    { name: 'blk2'},
    {
      name: 'game2a4',
      samples3: ['3_g3_1', require('static/swipe_PDFs/3_board_games_im_an_alien/Game_2_A4_PDF.png')],
      samples4: [
        ['swipe_g24_3', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_2_A4_3.jpg')],
        ['swipe_g24_1', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_2_A4_1.jpg')],
        ['swipe_g24_2', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_2_A4_2.jpg')],
        ['swipe_g24_4', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_2_A4_4.jpg')],
      ],
      title: 'GAME 2 (A4)',
      textf: [
        ['6 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 identity cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Making complex physical descriptions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar2to1,
        ['', 'style_space'],
        warningCardTextStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'im_an_alien_game_2_A4',
    },
    {
      name: 'game4',
      samples3: ['3_g4_1', require('static/swipe_PDFs/3_board_games_im_an_alien/Game_2_A3_PDF.png')],
      samples4: [
        ['swipe_g14_3', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_2_A3_3.jpg')],
        ['swipe_g14_1', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_2_A3_1.jpg')],
        ['swipe_g14_2', require('static/swipe_PDFs/4_board_games_im_an_alien/GAME_2_A3_2.jpg')],
      ],
      title: 'GAME 2 (A3)',
      textf: [
        ['5 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 identity cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Making complex physical descriptions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar2to1,
        ['', 'style_space'],
        warningCardTextStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'im_an_alien_game_2_A3',
    },
    { name: 'blk3'},
 ],
}


const download_absurd_inventions_games = {
  name: 'download_absurd_inventions_games',
  link: 'AbsurdInventionsGame',
  header: {
    activity: 'Board Games',
    main: 'Absurd inventions',
    text: 'Card game',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 4,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/3_chat_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['This card game includes two options which involve matching pictures with descriptions. '+
        'In the first option, students read descriptions to find the corresponding inventions. In the second option, students make their own cards. They draw inventions and write their descriptions. Then they play the game.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in the pack.', 'style_text_bigger'],
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    download_absurd_inventions.photos_set[6],
  ],
}



const download_secretpizza_games =  {
  name: 'download_secretpizza_games',
  link: 'SecretPizzaGame',
  header: {
    activity: 'Board Games',
    main: 'Secret Pizza',
    text: 'Board game & card game',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/5_pizzaiolo_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['In game 1, students progress on the game board and speak about the ingredients they have or not in order to find the ingredients of the secret pizza.', 'style_text_bigger'],
        ['', 'style_space'],
        ['In game 2, students speak about the ingredients they need or not to make their secret pizza while trying to discover the other secret pizzas as well.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in each pack.', 'style_text_bigger'],
        ['', 'style_space'],
      ],
      blocStyle: { zIndex: '4', top: '00px', left: '00px',},
    },
    {
      name: 'game1a4',
      samples3: ['3_g1_1', require('static/swipe_PDFs/3_board_games_secret_pizza/GAME_1_A4_PDF.png')],
      samples4: [
        ['swipe_g1_4', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_A4_4.jpg')],
        ['swipe_g1_1', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_A4_1.jpg')],
        ['swipe_g1_2', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_A4_2.jpg')],
        ['swipe_g1_3', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_A4_3.jpg')],
        ['swipe_g1_5', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_A4_5.jpg')],
      ],
      title: 'GAME 1 (A4)',
      textf: [
        ['7 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 ingredient cards (2 sheets in A4 size)', 'style_red'],
        ['\u2022 list of ingredients (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Speaking about pizza ingredients', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'secret_pizza_game_1_A4',
    },
    {
      name: 'game1a3',
      samples3: ['3_g2_1', require('static/swipe_PDFs/3_board_games_secret_pizza/GAME_1_A3_PDF.png')],
      samples4: [
        ['swipe_g2_4', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_A3_4.jpg')],
        ['swipe_g2_1', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_A3_1.jpg')],
        ['swipe_g2_2', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_A3_2.jpg')],
        ['swipe_g2_3', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_A3_3.jpg')],
      ],
      title: 'GAME 1 (A3)',
      textf: [
        ['6 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 ingredient cards (2 sheets in A4 size)', 'style_red'],
        ['\u2022 list of ingredients (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Speaking about pizza ingredients', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'secret_pizza_game_1_A3',
    },
    { name: 'blk1'},
    { name: 'blk2'},
    {
      name: 'game2',
      samples3: ['3_g3_1', require('static/swipe_PDFs/3_board_games_secret_pizza/GAME_2_PDF.png')],
      samples4: [
        ['swipe_g3_3', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_2_3.jpg')],
        ['swipe_g3_1', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_2_1.jpg')],
        ['swipe_g3_2', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_2_2.jpg')],
        ['swipe_g3_4', require('static/swipe_PDFs/4_board_games_secret_pizza/GAME_2_4.jpg')],
      ],
      title: 'GAME 2',
      textf: [
        ['8 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 menu (1 sheet in A4 size)', 'style_red'],
        ['\u2022 pizza cards (3 sheets in A4 size)', 'style_red'],
        ['\u2022 ingredient cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 list of ingredients (1 sheet in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Card game', 'style_text_bigger_bold'],
        ['Speaking about pizza ingredients', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'secret_pizza_game_2',
    },
    { name: 'blk3'},
 ],
}


const download_treasureisland_games =  {
  name: 'download_treasureisland',
  link: 'TreasureIslandGame',
  header: {
    activity: 'Board Games',
    main: 'Treasure Island',
    text: 'Board games',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/3_treasure_island_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['In this series of board games, students progress on the game board in order to get their share of gold coins back to the pirate ship.', 'style_text_bigger'],
        ['', 'style_space'],
        ['In game 1, students read about the obstacles to overcome and follow the instructions given, using their object cards.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Game 2 is an alternative to game 1 in which students also read about the obstacles to overcome, but they make their own decisions:'+
        ' they speak about what they want to do, choosing an object card.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in each pack.', 'style_text_bigger'],
        ['', 'style_space'],
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 'game1a4',
      samples3: ['3_g1_1', require('static/swipe_PDFs/3_board_games_treasure_island/GAME_1_A4_PDF.png')],
      samples4: [
        ['swipe_g1_3', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_1_A4_1.jpg')],
        ['swipe_g1_1', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_1-2_A3-A4.jpg')],
        ['swipe_g1_2', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_1_A3-A4.jpg')],
        ['swipe_g1_4', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_1_A4_2.jpg')],
      ],
      title: 'GAME 1 (A4)',
      textf: [
        ['10 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 objects cards (2 sheets in A4 size)', 'style_red'],
        ['\u2022 map instructions (3 sheets in A4 size)', 'style_red'],
        ['\u2022 backpack cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Following instructions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar1to2,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'treasure_island_game_1_A4',
    },
    {
      name: 'game1a3',
      samples3: ['3_g2_1', require('static/swipe_PDFs/3_board_games_treasure_island/GAME_1_A3_PDF.png')],
      samples4: [
        ['swipe_g2_3', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_1_A3.jpg')],
        ['swipe_g2_1', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_1-2_A3-A4.jpg')],
        ['swipe_g2_2', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_1_A3-A4.jpg')],
      ],
      title: 'GAME 1 (A3)',
      textf: [
        ['9 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 objects cards (2 sheets in A4 size)', 'style_red'],
        ['\u2022 map instructions (3 sheets in A4 size)', 'style_red'],
        ['\u2022 backpack cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Following instructions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar1to2,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'treasure_island_game_1_A3',
    },
    { name: 'blk1' },
    { name: 'blk2' },
    {
      name: 'game2a4',
      samples3: ['3_g3_1', require('static/swipe_PDFs/3_board_games_treasure_island/GAME_2_A4_PDF.png')],
      samples4: [
        ['swipe_g3_3', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_2_A4_1.jpg')],
        ['swipe_g3_1', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_1-2_A3-A4.jpg')],
        ['swipe_g3_2', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_2_A3-A4.jpg')],
        ['swipe_g3_4', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_2_A4_2.jpg')],
      ],
      title: 'GAME 2 (A4)',
      textf: [
        ['10 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 objects cards (2 sheets in A4 size)', 'style_red'],
        ['\u2022 map instructions (3 sheets in A4 size)', 'style_red'],
        ['\u2022 backpack cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Making decisions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar2to1,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'treasure_island_game_2_A4',
    },
    {
      name: 'game2a3',
      samples3: ['3_g4_1', require('static/swipe_PDFs/3_board_games_treasure_island/GAME_2_A3_PDF.png')],
      samples4: [
        ['swipe_g4_3', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_2_A3.jpg')],
        ['swipe_g4_1', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_1-2_A3-A4.jpg')],
        ['swipe_g4_2', require('static/swipe_PDFs/4_board_games_treasure_island/GAME_2_A3-A4.jpg')],
      ],
      title: 'GAME 2 (A3)',
      textf: [
        ['9 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 objects cards (2 sheets in A4 size)', 'style_red'],
        ['\u2022 map instructions (3 sheets in A4 size)', 'style_red'],
        ['\u2022 backpack cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Making decisions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar2to1,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'treasure_island_game_2_A3',
    },
 ],
}


const download_almost_christmas_games = {
  name: 'download_almost_christmas_games',
  link: 'AlmostChristmasGame',
  header: {
    activity: 'Board Games',
    main: 'Almost Christmas',
    text: 'Advent calendar games',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/4_deer_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['These games can be played in small or large groups of students. The aim is to discover the place of hidden objects on a picture.', 'style_text_bigger'],
        ['', 'style_space'],
        ['In game 1, each student reads the description of an object to the class.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Game 2 is an alternative of game 1 in which each student speaks about an object to the class by inventing a description.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in each pack.', 'style_text_bigger'],
        ['', 'style_space'],
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 'game1a4',
      samples3: ['3_g1_1', require('static/swipe_PDFs/3_board_games_almost_christmas/Game_1_A4_PDF.png')],
      samples4: [
        ['swipe_g1_1', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A4_1.jpg')],
        ['swipe_g1_2', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A4_2.jpg')],
        ['swipe_g1_5', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A4_5.jpg')],
        ['swipe_g1_6', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A4_6.jpg')],
        ['swipe_g1_3', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A4_3.jpg')],
        ['swipe_g1_4', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A4_4.jpg')],
//        ['swipe_g1_7', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A4_7.jpg')],
//        ['swipe_g1_8', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A4_8.jpg')],
      ],
      title: 'GAME 1 (A4)',
      textf: [
        ['15 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 advent calendar in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['\u2022 objects cards (6 sheets in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 answer sheet A in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['\u2022 answer sheet B in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Advent calendar game', 'style_text_bigger_bold'],
        ['Describing everyday objects', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar1to2,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'almost_christmas_game_1_A4',
    },
    {
      name: 'game1a3',
      samples3: ['3_g2_1', require('static/swipe_PDFs/3_board_games_almost_christmas/Game_1_A3_PDF.png')],
      samples4: [
        ['swipe_g2_1', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A3_1.jpg')],
        ['swipe_g2_4', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A3_4.jpg')],
//        ['swipe_g2_5', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A3_5.jpg')],
        ['swipe_g2_2', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A3_2.jpg')],
        ['swipe_g2_3', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_1_A3_3.jpg')],
      ],
      title: 'GAME 1 (A3)',
      textf: [
        ['12 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 advent calendar (1 sheet in A3 size)', 'style_red'],
        ['\u2022 objects cards (6 sheets in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 answer sheet A (1 sheet in A3 size)', 'style_red'],
        ['\u2022 answer sheet B (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Advent calendar game', 'style_text_bigger_bold'],
        ['Describing everyday objects', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar1to2,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'almost_christmas_game_1_A3',
    },
    { name: 'blk1' },
    { name: 'blk2' },
    {
      name: 'game2a4',
      samples3: ['3_g3_1', require('static/swipe_PDFs/3_board_games_almost_christmas/Game_2_A4_PDF.png')],
      samples4: [
        ['swipe_g3_1', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A4_1.jpg')],
        ['swipe_g3_2', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A4_2.jpg')],
        ['swipe_g3_5', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A4_5.jpg')],
        ['swipe_g3_6', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A4_6.jpg')],
        ['swipe_g3_3', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A4_3.jpg')],
        ['swipe_g3_4', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A4_4.jpg')],
//        ['swipe_g3_7', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A4_7.jpg')],
//        ['swipe_g3_8', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A4_8.jpg')],
      ],
      title: 'GAME 2 (A4)',
      textf: [
        ['15 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 advent calendar in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['\u2022 objects cards (6 sheets in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 answer sheet A in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['\u2022 answer sheet B in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Advent calendar game', 'style_text_bigger_bold'],
        ['Describing everyday objects', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar2to1,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'almost_christmas_game_2_A4',
    },
    {
      name: 'game2a3',
      samples3: ['3_g4_1', require('static/swipe_PDFs/3_board_games_almost_christmas/Game_2_A3_PDF.png')],
      samples4: [
        ['swipe_g4_1', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A3_1.jpg')],
        ['swipe_g4_4', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A3_4.jpg')],
//        ['swipe_g4_5', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A3_5.jpg')],
        ['swipe_g4_2', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A3_2.jpg')],
        ['swipe_g4_3', require('static/swipe_PDFs/4_board_games_almost_christmas/GAME_2_A4_4.jpg')],
      ],
      title: 'GAME 2 (A3)',
      textf: [
        ['12 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 advent calendar (1 sheet in A3 size)', 'style_red'],
        ['\u2022 objects cards (6 sheets in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 answer sheet A (1 sheet in A3 size)', 'style_red'],
        ['\u2022 answer sheet B (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Advent calendar game', 'style_text_bigger_bold'],
        ['Describing everyday objects', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar2to1,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'almost_christmas_game_2_A3',
    },
 ],
}

const download_so_much_stuff_games = {
  name: 'download_so_much_stuff_games',
  link: 'SoMuchStuffGame',
  header: {
    activity: 'Board Games',
    main: 'So much stuff!',
    text: 'Board game & card game',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 4,
  photos_set: [
    {
      name: 'So much stuff',
      img: require('static/illustrations/8_maison_exterieur_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['In game 1, students play in teams. They progress on the game board by answering a variety of questions. '+
        'For example, they have to remember the place of objects on a picture. They also have to find out objects by reading their description. Before answering, students may discuss their ideas with their team. ',
        'style_text_bigger'],
        ['', 'style_space'],
        ['In game 2, students play alone. The aim is to discover the place of hidden objects on various pictures of rooms. '+
        'While a student describes a room full of objects, the other students complete their own picture of the same room by drawing the missing items in the correct place. ',
        'style_text_bigger'],
        ['', 'style_space'],
        ['Free teacher\'s notes are included in each pack.', 'style_text_bigger']
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    download_so_much_stuff.photos_set[5],
    download_so_much_stuff.photos_set[6],
    download_so_much_stuff.photos_set[7],
  ],
}

const download_who_killed_the_cat_games = {
  name: 'download_who_killed_the_cat_games',
  link: 'WhoKilledTheCatGame',
  header: {
    activity: 'Board Games',
    main: 'Who Killed The Cat?',
    text: 'Board game',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 4,
  photos_set: [
    {
      name: 'Who Killed The Cat',
      img: require('static/illustrations/7_dead_cat_photo_avec_ombre_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['In this series of board games, students progress on the game board by stating facts or asking questions about a murder. '+
        'In game 1, they speak about the clues they have or not in order to find all the necessary information to arrest the killer. ',
        'style_text_bigger'],
        ['', 'style_space'],
        ['Game 2 is an alternative to game 1. The final goal remains the same, but the task is more complex. '+
        'Students have to figure out the motives of each suspect in addition to finding the rest of the information. ',
        'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in each pack.', 'style_text_bigger']
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 'game1a4',
      samples3: ['3_g1_1', require('static/swipe_PDFs/3_board_games_who_killed_the_cat/GAME_1_A4_PDF.png')],
      samples4: [
        ['swipe_g1_4', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_1_A4_4.jpg')],
        ['swipe_g1_5', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_1_A4_5.jpg')],
        ['swipe_g1_1', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_1_A4_1.jpg')],
        ['swipe_g1_2', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_1_A4_2.jpg')],
        ['swipe_g1_3', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_1_A4_3.jpg')],
      ],
      title: 'GAME 1 (A4)',
      textf: [
        ['13 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 list of clues (1 sheet in A4 size)', 'style_red'],
        ['\u2022 28 game cards (7 sheets in A4 size)', 'style_red'],
        ['\u2022 3 answer cards and a label (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Investigating past events', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar1to2,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'who_killed_the_cat_game_1_A4',
    },
    {
      name: 'game1a3',
      samples3: ['3_g2_1', require('static/swipe_PDFs/3_board_games_who_killed_the_cat/GAME_1_A3_PDF.png')],
      samples4: [
        ['swipe_g2_4', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_1_A3_4.jpg')],
        ['swipe_g2_1', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_1_A3_1.jpg')],
        ['swipe_g2_2', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_1_A3_2.jpg')],
        ['swipe_g2_3', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_1_A3_3.jpg')],
      ],
      title: 'GAME 1 (A3)',
      textf: [
        ['12 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 list of clues (1 sheet in A4 size)', 'style_red'],
        ['\u2022 28 game cards (7 sheets in A4 size)', 'style_red'],
        ['\u2022 3 answer cards and a label (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Investigating past events', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar1to2,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'who_killed_the_cat_game_1_A3',
    },
    { name: 'blk1' },
    { name: 'blk2' },
    {
      name: 'game2a4',
      samples3: ['3_g3_1', require('static/swipe_PDFs/3_board_games_who_killed_the_cat/GAME_2_A4_PDF.png')],
      samples4: [
        ['swipe_g3_4', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_2_A4_4.jpg')],
        ['swipe_g3_5', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_2_A4_5.jpg')],
        ['swipe_g3_1', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_2_A4_1.jpg')],
        ['swipe_g3_2', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_2_A4_2.jpg')],
        ['swipe_g3_3', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_2_A4_3.jpg')],
      ],
      title: 'GAME 2 (A4)',
      textf: [
        ['14 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (3 sheets in A4 size)', 'style_red'],
        ['\u2022 list of clues (1 sheet in A4 size)', 'style_red'],
        ['\u2022 28 game cards (7 sheets in A4 size)', 'style_red'],
        ['\u2022 3 answer cards and a label (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Investigating past events', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar2to1,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'who_killed_the_cat_game_2_A4',
    },
    {
      name: 'game2a3',
      samples3: ['3_g4_1', require('static/swipe_PDFs/3_board_games_who_killed_the_cat/GAME_2_A3_PDF.png')],
      samples4: [
        ['swipe_g4_4', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_2_A3_4.jpg')],
        ['swipe_g4_1', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_2_A3_1.jpg')],
        ['swipe_g4_2', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_2_A3_2.jpg')],
        ['swipe_g4_3', require('static/swipe_PDFs/4_board_games_who_killed_the_cat/GAME_2_A3_3.jpg')],
      ],
      title: 'GAME 2 (A3)',
      textf: [
        ['13 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (3 sheets in A4 size)', 'style_red'],
        ['\u2022 list of clues (1 sheet in A4 size)', 'style_red'],
        ['\u2022 28 game cards (7 sheets in A4 size)', 'style_red'],
        ['\u2022 3 answer cards and a label (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheets in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Investigating past events', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningSimilar2to1,
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'who_killed_the_cat_game_2_A3',
    },
  ],
}

export {
  download_crazy_animals_games, download_iamanalien_games, download_absurd_inventions_games, download_secretpizza_games,
  download_treasureisland_games, download_almost_christmas_games, download_so_much_stuff_games, download_who_killed_the_cat_games,
};
