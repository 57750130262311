// -*- coding: utf-8 -*-

import React from 'react';
import { websiteUrl } from '../const.js';

const Website = () => {
  return (
    <a href={websiteUrl} rel="noopener noreferrer" target="_blank">{websiteUrl}</a>
  );
};

export default Website;
