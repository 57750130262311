// -*- coding: utf-8 -*-

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import {LinkContainer} from 'react-router-bootstrap';

import Breadcrumbs from './Breadcrumb';
import AuthNavbar from '../auth/AuthNavbar';

import Button from 'react-bootstrap/Button';
import {debug, websiteUrl } from '../const.js';

//https://www.flaticon.com/free-icon/instagram_174855
import {
  //EmailShareButton,
  //FacebookShareButton,
  //PinterestShareButton,
  //EmailIcon,
  FacebookIcon,
  PinterestIcon,
} from "react-share";

import { useAuth0 } from "../auth/react-auth0-wrapper";


/******************************************************************************/
/* header */

const Header = ({header, type_wave, pageLevel4, searchMode}) => {

  const type_wave_bool = typeof type_wave !== "undefined"
  const header_main =  (type_wave_bool) ? header.main :
      ( (pageLevel4) ? (searchMode ? "SEARCH RESULT..." : "") : header.main.toUpperCase() );
  const header_text = (pageLevel4) ? "" : header.text;

  //console.log("header.main");
  //console.log(header);
  //console.log(type_wave);

  //console.log(header.main);
  //console.log(header_main);

  return (
    <Row className="wave_header">
      <Col xs={0} sm={0} lg={1} className="phone_hide"/>
      <Col xs={12} sm={12} lg={10} className="header">
          {type_wave_bool && header.img && (<img className="header_img" alt="header" src={header.img}/>)}
          {header_main && (<div className="header_main">{header_main}</div>)}
          {header_text && (<div className="header_text">{header_text}</div>)}
          {type_wave_bool && header.text2 && (<div className="header_text2">{header.text2}</div>)}
      </Col>
      <Col xs={0} sm={0} lg={1} className="phone_hide"/>
    </Row>
  )
}

const AppHeader = ({toggleSearch, cart, children}) => {
    const { isAuthenticated, user, userProfile } = useAuth0();

    //console.log(`AppHeader cart length=${cart.length}`);
              /*
              <img className="logo" src={websiteUrl+"/logo_en_rotation.gif"} alt="logo" />
              <div className="app-logo phone_unhide">
                <video className="logo phone_unhide" autoPlay={true} loop={true} muted={true} playsInline={true}>
                  <source src={websiteUrl+"/logo_en_rotation2.webm"} type="video/webm" />
                </video>
              </div>
              <div onClick={toggleSearch} className="app-logo phone_hide">
                <video className="logo phone_hide" autoPlay={true} loop={true} muted={true} playsInline={true}>
                  <source src={websiteUrl+"/logo_en_rotation2.webm"} type="video/webm" />
                </video>
              </div>
              */
    return (
        <Row className="app-header">
          <Col xs={0} sm={0} lg={2} className="header_search phone_hide">
            <div className="app-logo phone_unhide">
              <img className="logo phone_unhide" src={websiteUrl+"/logo_en_rotation.gif"} alt="logo" />
            </div>
            <div onClick={toggleSearch} className="app-logo phone_hide">
              <img className="logo phone_hide" src={websiteUrl+"/logo_en_rotation.gif"} alt="logo" />
            </div>
            {children}
          </Col>
          <Col xs={12} sm={12} lg={10}>
            <Navbar expand="lg" variant="light" className="">
              <Navbar.Toggle aria-controls="basic-navbar-nav" className="header_nav_button"/>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="me-auto">
                  <Breadcrumbs/>
                </Nav>
                <Nav className="me-auto">
                  {/*
                    <LinkContainer className="LinkContainer header_button" to="/Test1"><Button variant="light">TEST1</Button></LinkContainer>
                  */}
                  <LinkContainer className="LinkContainer header_button" to="/Pictures"><Button variant="light">PICTURES</Button></LinkContainer>
                  <LinkContainer className="LinkContainer header_button" to="/QuickGames"><Button variant="light">QUICK GAMES</Button></LinkContainer>
                  <LinkContainer className="LinkContainer header_button" to="/BoardGames"><Button variant="light">BOARD GAMES</Button></LinkContainer>
                  <LinkContainer className="LinkContainer header_button" to="/Modules"><Button variant="light">MODULES</Button></LinkContainer>
                </Nav>
                <Nav className="">
                  { debug && (
                    <>
                      <span className="debug">{window.outerWidth + "x" +window.outerHeight} ow x oh</span>
                    </>)
                  }
                  <span className="socialLink">
                    {/*
                    <EmailShareButton url={websiteUrl} subject={websiteDescription} body={`Hi,\nTake a look at that website: \n`}>
                      <EmailIcon size={32} round={true} />
                    </EmailShareButton>

                    #Learning #Teaching #EFL #ESL
                    https://www.facebook.com/search/top?q=gameleon.ch

                    <PinterestShareButton url={websiteUrl} media={websiteUrl+'/logo.png'} description={websiteDescription}>
                      <PinterestIcon size={32} round={true} />
                    </PinterestShareButton>
                    <FacebookShareButton url={websiteUrl} quote={websiteDescription} hashtag="#EFL">
                      <FacebookIcon size={32} round={false} />
                    </FacebookShareButton>
                    */}

                    {/*
                    <a href="https://www.instagram.com/gameleon.ch" target="_blank" rel="noreferrer">
                      <img className="socialIcon" src={websiteUrl+"/Instagram_Glyph_Gradient_RGB2.png"} alt="instagram" />
                    </a>
                    */}
                    <a href="https://www.pinterest.ch/Gameleon_ch" target="_blank" rel="noreferrer">
                      <PinterestIcon className="socialIcon" size={32} round={true} />
                    </a>
                    <a href="https://www.facebook.com/pages/category/Education-Website/Gameleonch-102586761834099/" target="_blank" rel="noreferrer">
                      <FacebookIcon className="socialIcon" size={32} round={false} />
                    </a>
                  </span>
                  <LinkContainer className="LinkContainer header_button shoppingcart_button" to="/ShoppingCart"><Button variant="light"><img id="shoppingCart" src={websiteUrl+"/shoppiong_cart.png"} alt="Shopping cart" /></Button></LinkContainer>
                  <div id="shoppingCartNbItems">{cart.length}</div>
                  { (isAuthenticated && user && userProfile) && (
                    <LinkContainer className="LinkContainer header_button" to="/AuthMyStuff"><Button variant="light">My stuff</Button></LinkContainer>
                  )}
                </Nav>
                <Nav className="header_button login_group">
                  <AuthNavbar />
                </Nav>
              </Navbar.Collapse>

            </Navbar>
          </Col>
        </Row>
    );
}
export default AppHeader;

export { Header };
