// -*- coding: utf-8 -*-

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal'
import axios from	'axios'
import {api} from './const.js';

import ExpandableWave from './comp/ExpandableWave';
import OneDocCard from './comp/OneDocCard';

/******************************************************************************/
/* wave and level management */

//Used: level 1-2
//content on each wave or just waves
function subjectColumns(waves, get_obj) {

  const nb_col = waves.length;
  const row_out = [];
  const nb_img_per_line = 10;

  let ridx = 0;
  cols:
  while(true)
  {

    const col_out = [];
    for(var c=0; c < nb_img_per_line; c++){
      const wave_idx = ridx * nb_img_per_line + c;
      if (wave_idx >= nb_col){
        row_out.push(
          <Row key={`row${ridx}`} className="wave_container_subject">
            {col_out}
          </Row>
        );
        break cols;
      }
      //console.log(waves[wave_idx].name+",wave_idx="+wave_idx);

      const wave_conf = waves[wave_idx];
      const wave_conf_key = "wave"+(wave_idx);

      const content = (get_obj) ? get_obj(wave_idx, wave_conf) : undefined
      col_out.push(
         <Col key={wave_conf_key} className={wave_conf.className} style={wave_conf.blocStyle}>
           {content}
         </Col>
       );
    }

    row_out.push(
      <Row key={`row${ridx}`} className="wave_container_subject">
        {col_out}
      </Row>
    );

    ridx += 1;
  }

  return row_out;
}


//Used : DownloadPdf, DownloadHistory, PurchaseHistory, SearchResult
//content on the 1th wave. Only 3 waves displayed.
function waveDownload(pageLevel, nb_waves_wanted, waves, get_obj, wave3_height) {
  let waves_out = [];

  //console.log('waveDownload');
  //console.log('nb_waves_wanted='+nb_waves_wanted);
  //console.log(waves);

  for (var i = 0; i < nb_waves_wanted;){
    const obj_col = get_obj ? get_obj(i) : null

    waves_out.push(obj_col);

    i += 3;
  }
  //console.log(waves_out);

  const wave_container = waves.wave_container;
  const wave_footer = waves.footer;

  return (<ExpandableWave content={waves_out} wave_container_style={wave_container.style} footer_style={wave_footer.style} gap={""}/>);
}

/******************************************************************************/
/* client utility */

function getPhotoArray(download_obj){
  return new Map(download_obj.photos_set.filter(photo=>photo && typeof photo.documentId !== 'undefined').map((photo)=>[photo.documentId, photo.samples3[1]]));
}

function getDocCard(nb_img_per_line, data, photos_map, searchMode){
  const col = [];

  //doc : {docKey, activities, text, title, downloadDate}
  const cards = data.map((doc)=> (<OneDocCard key={"oneDoc_"+doc.docKey} doc={doc} searchMode={searchMode} photos_map={photos_map}/>));

  const nb_card = data.length;
  //console.log("nb_img_per_line=", nb_img_per_line, "nb_card=", nb_card);

  var card_idx = 0;
  for(var j=0; j < nb_card;){

    const card_set = [];

    var i=0;
    while( i < nb_img_per_line && card_idx < nb_card ){
      //console.log("card_idx=", card_idx, cards[card_idx]);
      card_set.push(cards[card_idx]);

      i = i + 1;
      card_idx = i + j;
    }
    const nb_card_set = card_set.length;
    //console.log("nb_card_set=", nb_card_set, ", j=", j);

    col.push(
      <React.Fragment key={"fr_"+j}>
        <Col key={"coll"+j} xs={0} lg={2} className="search search_space">
        </Col>
        <Col key={"colr"+j} xs={12} lg={10} className="search search_col">
          <div key={"cardcol"+j} style={{columnCount: nb_card_set}}>
            {card_set}
          </div>
        </Col>
      </React.Fragment>
    );

    j +=  nb_img_per_line;
  }

  return col;
}

function getProductName(doc){
  //console.log(doc);
  return doc.docKey ? `${doc.activities.join(', ').toUpperCase()} - ${doc.title} ${doc.text}.zip` : doc.name;
  //return doc.docKey ? `${doc.activities.join(', ').toUpperCase()} - ${doc.name}` : doc.name;
}

/******************************************************************************/
/* server utility */
async function updateUserProfile(token, data, login, setReloadUserProfile) {
  //console.log("updateUserProfile")
  //console.log(user)
  if (!login){
    return;
  }

  const url = api.base + api.user + login;

  //console.log(data);
  //const data = await
  await axios.post(url, data, {
    headers: {
      "Authorization": `Bearer ${token}`,
      "withCredentials": true,
    }})
    .then(function (response) {
      //console.log("updateUserProfile ok");
      //console.log(response);
      setReloadUserProfile(true);
    })
    .catch(function (error) {
      //console.error(error);
      throw new Error("Profile update error");
    });
  return;
}

async function getDocumentMetadata(token, documentId) {
  const url = api.base + api.documentMetadata + documentId;

  return await axios.get(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'withCredentials': true,
      'Accept': 'application/json',
    }})
    .then(function (response) {
      //console.log("getDocumentMetadata ok");
      //console.log(response.data)
      return response.data;
    })
    .catch(function (error) {
      //console.error(error)
      throw new Error("download document "+documentId+" error: "+ error.message);
    });
}

async function getAllDocumentMetadata() {
  const url = api.base + api.allDocumentMetadata;

  return await axios.get(url, {
    }).then(function (response) {
      //console.log("get_all_document_metadata ok");
      //console.log(response.data)

      return response.data;
    })
    .catch(function (error) {
      //console.error(error)
      //throw new Error("get_all_document_metadata error");
    });
}

async function getUserBoughtDocument(token, user) {
  const url = api.base + api.userBoughtDocument + user.login;

  return await axios.get(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'withCredentials': true,
      'Accept': 'application/json',
    }})
    .then(function (response) {
      //console.log("getUserBoughtDocument");
      //console.log(response.data)
      return response.data;
    })
    .catch(function (error) {
      //console.error(error)
      throw new Error(error.message);
    });
}

async function getUserDownloadedDocument(token, user) {
  const url = api.base + api.userDownloadedDocument + user.login;

  return await axios.get(url, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'withCredentials': true,
      'Accept': 'application/json',
    }})
    .then(function (response) {
      //console.log("getUserDownloadedDocument");
      //console.log(response.data)
      return response.data;
    })
    .catch(function (error) {
      //console.error(error)
      throw new Error(error.message);
    });
}


async function downloadDocument(token, documentId, user) {
  //console.log("downloadDocument")
  //console.log(user)
  if (!user){
    throw new Error("No user!");
  }

  const url = api.base + api.document + documentId;

  await getDocumentMetadata(token, documentId)
    .then(async function (documentMetadata) {
      //console.log("getDocumentMetadata");
      if (!documentMetadata){
        throw new Error("Download document "+documentId+" error: document not found!");
      }

      const documentName = documentMetadata.name;

      await axios.post(url, {downloadDate: Date.now()}, {
        responseType: 'arraybuffer',
        headers: {
          'Authorization': `Bearer ${token}`,
          'withCredentials': true,
          'Accept': 'application/zip',
        }})
        .then(function (response) {
          //console.log("downloadDocument ok");
          //console.log(response)

          const do_save  = true
          if (do_save) {
            const blob = new Blob([response.data], {type : 'application/zip'})

            //const documentName = response.headers['x-document-name'];
            //console.log(response.headers)
            //console.log("documentName="+documentName)

          	let url = window.URL.createObjectURL(blob);
          	let a = document.createElement('a');
          	a.href = url;
          	a.download = documentName;
          	document.body.appendChild(a);
          	a.click();
          	document.body.removeChild(a);
            //window.location.href = response.url;
          }

          return;
        })
        .catch(function (error) {
          //console.error(error)
          //console.error(error.response.status)

          if (error.response){
            if (error.response.status === 404){
              throw new Error("The document "+documentName+" was not found on the server, use the contact page to warn the admin!");
            }
            if (error.response.status === 500){
              throw new Error("Download impossible.");
            }
          }
          throw new Error("Download document "+documentId+" error: "+ error.message);
        });
    });

  return;
}

/******************************************************************************/
/* payment */


//"buyDocumentList": "/api/user/:id/buydocumentlist",
async function buyDocumentList(token, userProfile, acceptedTerms, selectedPaymentModus, docList, totalPrice) {
  const url = `${api.base}${api.user}${userProfile.login}/buydocumentlist`;
  const data = {userProfile: userProfile, selectedPaymentModus: selectedPaymentModus, docList: docList, totalPrice: totalPrice, acceptedTerms: acceptedTerms};

  //console.log(data);
  return await axios.post(url, data, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'withCredentials': true,
    }})
    .then(function (response) {
      //console.log("buyCreditPack");
      //console.log(response.data);
      return response.data;
    })
    .catch(function (error) {
      //console.error(error.response)
      throw new Error(error.response.data);
    });
}
/*
//check status of payment for the current user
async function checkPaymentAssert(token, userProfile) {
  const url = `${api.base}${api.user}${userProfile.login}/paymentSixAssert`;
  const data = {userProfile: userProfile};
  return await axios.post(url, data, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'withCredentials': true,
    }})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      //console.error(error.response)
      throw new Error(error.response.data);
    });
}
*/
//"getPayments": "/api/user/:id/payment",
async function getPayments(token, userProfile, returnStatus) {
  const url = `${api.base}${api.user}${userProfile.login}/payment`;
  const data = {userProfile: userProfile, returnStatus: returnStatus};
  return await axios.post(url, data, {
    headers: {
      'Authorization': `Bearer ${token}`,
      'withCredentials': true,
    }})
    .then(function (response) {
      return response.data;
    })
    .catch(function (error) {
      //console.error(error.response)
      //throw new Error(error.response.data);
      throw new Error("Error getting payments");
    });
}

/******************************************************************************/
/* download documents */
function warningBox(user, getTokenSilently, show, setShow, setReloadUserProfile) {
  return (
  <Modal
    size="lg"
    show={show}
    onHide={() => setShow(false)}
    aria-labelledby="download_popup"
  >
    <div className="download_popup download_text">
      <Modal.Body>
        <div className="download_text style_text_bigger_bold">Be careful...</div>
        <p>Before you download the PDF pack or file, check the size of all the pages and make sure it is compatible with your printer:</p>
        <ul>
          <li>A4 size: 210 x 297 mm</li>
          <li>A3 size: 297 x 420 mm</li>
        </ul>
        <div className="download_text style_text_bigger_bold">And also...</div>
        <p>Before you print the first version of your document, set your printer on colour and high quality to get the best result!</p>
        <Button className="download_button" variant="light" onClick={()=>closeDownLoadAlert(user, getTokenSilently, setShow, setReloadUserProfile)}>Ok, thanks! Don't remind me any more!</Button>
      </Modal.Body>
    </div>
  </Modal>
)};

async function closeDownLoadAlert(user, getTokenSilently, setShow, setReloadUserProfile){
  //console.log(user);

  const token = await getTokenSilently();

  const data = {
    login: user.sub,
    documentWarning: false,
  }

  updateUserProfile(token, data, user.sub, setReloadUserProfile);

  setShow(false);
}

async function updateUserShowExamples(user, getTokenSilently, setReloadUserProfile, showExamples){
  //console.log(user);

  const token = await getTokenSilently();

  const data = {
    login: user.sub,
    showExamples: showExamples,
  }

  updateUserProfile(token, data, user.sub, setReloadUserProfile);
}

async function downloadDocumentEvt(event, doDisplayWarning, documentId, user,
  getTokenSilently, setErrorMsg, setDataBoughtDocReload, setShow) {
  event.preventDefault();

  setErrorMsg();

  if (doDisplayWarning){
    setShow(true);
  } else {
    try {
      const token = await getTokenSilently();

      await downloadDocument(token, documentId, user);

      setDataBoughtDocReload(true);
    } catch (error) {
      //console.error(error);
      setErrorMsg(error.message)
    }
  }
}

/******************************************************************************/
/* history */

function addDate(Moment, d, downloadDate){
  d['downloadDate'] = Moment(downloadDate)//.format('YYYY-MM-DDTHH:mm:ss');
  //new Date(downloadDate).toLocaleString();
  return d;
}

function sleep(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}

//https://developer.mozilla.org/fr/docs/Web/JavaScript/Reference/Objets_globaux/Date/toLocaleDateString
var dateOptions = {year: "numeric", month: "short", day: "numeric", hour: "2-digit", minute: "2-digit"};


function formatDateToString(date){
  return new Date(date).toLocaleDateString('en-GB', dateOptions);
}

function sortOrderCompare(a, b) {
  if (a.date < b.date) {
    return 1;
  }
  if (a.date > b.date) {
    return -1;
  }
  //
  if (a.text < b.text) {
    return 1;
  }
  if (a.text > b.text) {
    return -1;
  }
  // a must be equal to b
  return 0;
}

export { subjectColumns, waveDownload };
export { getPhotoArray, getDocCard, getProductName };
export { getAllDocumentMetadata, getDocumentMetadata, getUserDownloadedDocument, getUserBoughtDocument, updateUserProfile };
export { getPayments, buyDocumentList };
export { addDate, formatDateToString, sortOrderCompare, sleep };
export { warningBox, downloadDocumentEvt, updateUserShowExamples };
