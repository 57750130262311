// -*- coding: utf-8 -*-

import React from 'react';
import Website from '../comp/Website';

import Support from './Support';

const Licensing = ({waves, hideSearch, footer_support}) => {

  const text = (
      <React.Fragment>
            <div className="information_text_container information_text_container_support information_text_scroll">
                <div className="information_title">
                  Licensing notice
                  <p/>
                </div>
                <div className="information_text">
                  This licensing notice applies for the entire contents of the
                  website <Website/> (called “Gameleon” in the following).
                  The entire contents of this website are copyright protected by the Swiss and law and belongs solely to Gameleon.
                  This licensing notice includes the following rights:
                  <ul>
                    <li>
                      <div className="information_title">
                      Adaptation right
                      </div>
                      <div className="information_text">
                      Any modification or adaptation of the resources is strictly forbidden, unless stated otherwise.
                      </div>
                    </li>
                    <li>
                      <div className="information_title">
                      Dissemination right
                      </div>
                      <div className="information_text">
                      The resources may not be disseminated or shared on social media or through any other IT infrastructures.
                      They may be mentioned with a link to the website <Website/>.
                      </div>
                    </li>
                    <li>
                      <div className="information_title">
                      Reproduction right
                      </div>
                      <div className="information_text">
                      Any reproduction for commercial purposes is strictly forbidden.
                      Printing and photocopying are allowed for class use or private use exclusively.
                      </div>
                    </li>
                    <li>
                      <div className="information_title">
                      Use right
                      </div>
                      <div className="information_text">
                      The contents of Gameleon are to be used in a restricted learning environment, such as the sphere of a classroom or the private sphere.
                      </div>
                    </li>
                  </ul>
                </div>
                <div className="information_text">
                The contents of Gameleon are subjected to two types of copyright licences. The licence of each PDF file is visible on the final overview page before the downloading process.
                </div>
                <div id="LicenceNoAdapt" className="information_title">
                  Copyright licence – No adaptation
                </div>
                <div className="information_text">
                  <ul>
                    <li>
                    No adaptation
                    </li>
                    <li>
                    No public dissemination
                    </li>
                    <li>
                    No commercial use
                    </li>
                    <li>
                    For printing and photocopying
                    </li>
                    <li>
                    For class use or private use only
                    </li>
                  </ul>
                </div>
                <div id="LicenceForAdapt" className="information_title">
                  Copyright licence – For adaptation
                </div>
                <div className="information_text">
                  <ul>
                    <li>
                    For adaptation
                    </li>
                    <li>
                    No public dissemination
                    </li>
                    <li>
                    No commercial use
                    </li>
                    <li>
                    For printing and photocopying
                    </li>
                    <li>
                    For class use or private use only
                    </li>
                  </ul>
                </div>
                <div className="information_text">
                When purchasing a resource from Gameleon, the customer downloads a copy of a file under copyright. Under no circumstances does he obtain the copyright of the purchased file.
                </div>
                <p/>
                <div className="information_text">
                Gameleon reserves the right to change or remove its contents from the site at any time without sending a notification to the customer.
                </div>
            </div>

      </React.Fragment>
    );

  return (
    <Support footer_support={footer_support} policies={true} waves={waves} hideSearch={hideSearch} children={text}/>
  );
}

export default Licensing;
