// -*- coding: utf-8 -*-

import React from 'react';

const buildLines = (className, txt) => {
  return (
    <div className={className}>
      {txt}
    </div>
    )
}
const buildLinesDownloadText = (txt) => {
  return buildLines("download_text", txt);
}
const buildLinesTitle = (txt) => {
  return buildLines("information_title", txt);
}
const buildLinesText = (txt) => {
  return buildLines("information_text", txt);
}

const Text = (fct, props) => {
  const do_separate_lines = typeof props.sep !== "undefined";

  //console.log(props.children);
  if (do_separate_lines && Array.isArray(props.children)){
    const lines = props.children.map(fct);
    return (
      <div>
        {lines}
      </div>
    );
  }
  return fct(props.children);
};

const Title = (props) => {
  return Text(buildLinesTitle, props);
};

const Information = (props) => {
  return Text(buildLinesText, props);
};

const DownloadText = (props) => {
  return Text(buildLinesDownloadText, props);
};


export { Information, Title, DownloadText };
