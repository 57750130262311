// -*- coding: utf-8 -*-

import React from 'react';
import Mailto from 'react-protected-mailto';
import Website from '../comp/Website';
import { Information, Title } from '../comp/Information';
import { DPS, MyStuff, Licensing, Address, IDE, CH_ID } from '../const.js';

import Support from './Support';

const GeneralTermsAndConditions = ({waves, hideSearch, footer_support}) => {

  const text = (
      <React.Fragment>

            <div className="information_text_container information_text_container_support information_text_scroll">
                <Title>
                  General terms and conditions (GTC)
                </Title>
<div className="information_text">
These general terms and conditions apply for all services provided
by <Website/> (called “Gameleon” in the following).
Gameleon can change the number of services provided at any time or stop providing them.
By using the services of Gameleon, you accept the following conditions in full.
</div>
<p/>
<Title>
  Legal notice
</Title>
<Information>
  Gameleon, registered sole proprietorship
</Information>
<Information>
  IDE\UID: <IDE/>
</Information>
<Information>
  CH-ID: <CH_ID/>
</Information>
<Address/>
<Title>
Overview
  <ol>
    <li><a href="#Object">Object</a></li>
    <li><a href="#Registration">Registration</a></li>
    <li><a href="#OnlineAccount">Online account</a></li>
    <li><a href="#downloading">Downloading process</a></li>
    <li><a href="#printing">Printing process</a></li>
    <li><a href="#Guarantee">Guarantee</a></li>
    <li><a href="#Liability">Liability</a></li>
    <li><a href="#issues">Potential issues</a></li>
    <li><a href="#LiabilityCustomer">Liability of the customer</a></li>
    <li><a href="#IP">Intellectual property</a></li>
    <li><a href="#Final">Final provisions</a></li>
  </ol>
</Title>
<ol className="information_title">
  <li>
    <div id="Object" className="information_title">
    Object
    </div>
    <div className="information_text">
    Gameleon sells print and play resources online in the form of downloadable PDF files of various sizes.
    Each file is worth a certain amount, depending on the number of pages.
    In order to download a file, the customer must register first.
    Then he may start adding products to his shopping cart.
    The downloads are possible after the payment is made.
    <p/>
    Gameleon is intended for education professionals whose purpose is to teach
    English as a foreign language and by extension, any other individual with similar skills and purpose.
    Consequently, the contents of Gameleon are to be used in a restricted learning environment,
    such as the sphere of a classroom or the private sphere.
    </div>
  </li>
  <li>
    <div id="Registration" className="information_title">
    Registration
    </div>
    <div className="information_text">
    To download a PDF file, the customer must identify himself through the login portal with his e-mail address and his password.
    If the customer is not registered yet, he is asked to register and provide a valid e-mail address and username.
    The customer’s access data to his online account is to be used by the customer only and kept confidential.
    All downloads via the online account are binding for the account holder.
    The use of personal data is regulated in the <DPS/> which is an integral part of these GTC.
    </div>
  </li>
  <li>
    <div id="OnlineAccount" className="information_title">
    Online account
    </div>
    <div className="information_text">
    Through his online account the customer has access to:
      <ul>
        <li>
        his personal data (name, username, e-mail address),
        </li>
        <li>
        his purchase history,
        </li>
        <li>
        his downloadable documents (<MyStuff/>).
        </li>
      </ul>
    The access to the account is not limited in time or number of times, as long as the customer keeps it active. In the event of a period of two consecutive years of non-use, the account will be closed down automatically. In such case, the customer will receive an e-mail notification one month before the expiration date.
    </div>
  </li>
  <li>
    <div id="downloading" className="information_title">
    Downloading process
    </div>
    <div className="information_text">
    Each PDF file is worth a certain amount.
    The customer can have access to all the necessary information of a file, including its value in Swiss francs (CHF),
      on the final overview page before adding it to his shopping cart.
    </div>
  </li>
  <li>
    <div id="printing" className="information_title">
    Printing process
    </div>
    <div className="information_text">
    A PDF file can be printed  with a functional and suitable printer.
    For a better result, the file should be printed in high quality,
    on white paper, in A4 size or A3 size depending on the original size of the file as well as the paper size options available on the printer.
    The customer recognises that it is within his own sphere of responsibility and risk to have all the necessary technical facilities for printing the file.
    </div>
  </li>
  <li>
    <div id="Guarantee" className="information_title">
    Guarantee
    </div>
    <div className="information_text">
    Gameleon provides its services within the expectable requirements unless it is prevented in doing so by circumstances for which it is not responsible.
    The customer is aware that Gameleon provides its services via the internet.
    Consequently there may be disruptions or interruptions in the said services due to technical or operating problems related to a temporary breakdown of IT infrastructures.
    Thus Gameleon gives no guarantee that its services will be available at all times without interruption or error.
    </div>
  </li>
  <li>
    <div id="Liability" className="information_title">
    Liability
    </div>
    <div className="information_text">
    In the event of failure to comply with its own contractual obligations under these GTC,
    Gameleon is liable to the customer without restriction for direct and proven damages caused through illegal intent or gross negligence.
    Liability for minor negligence or for indirect or consequential damages is expressly excluded.
    </div>
  </li>
  <li>
    <div id="issues" className="information_title">
    Potential issues
    </div>
    <div className="information_title">
    Downloading process
    </div>
    <div className="information_text">
    In the event that the downloading of a PDF file fails due to a breakdown of IT infrastructures
    and that the payment has already been processed, the customer will not be charged twice the required amount to download the desired file successfully.
    In such case, he should check his <MyStuff/> page on his online account and download
    the said file with no extra charge. If the problem subsides, the customer should contact Gameleon
    at <Mailto className="information_important" email='account@gameleon.ch'/> and report the problem.
    </div>
    <div className="information_title">
    Deleted files
    </div>
    <div className="information_text">
    If the customer deletes a downloaded PDF file from his local drive, he may download it again,
    with no extra charge, by checking the <MyStuff/> page on his online account.
    </div>
    <div className="information_title">
    Flawed files
    </div>
    <div className="information_text">
    If the customer were to find any errors related to the content of a PDF file (typos, lay-out, etc…)
    after downloading it, he should contact Gameleon
    at <Mailto className="information_important" email='pdf@gameleon.ch'/> and report them.
    Gameleon commits to correct the said file with no extra charge.
    Please note that any flaws related to the printing process will not be taken into account.
    </div>
    <Title>
    Website
    </Title>
    <div className="information_text">
    If the customer should encounter any problems related to the contents of the
    website <Website/> (malfunctioning, classification, search tool, typos, etc…),
    he should contact Gameleon
    at <Mailto className="information_important" email='webmaster@gameleon.ch'/> and report them.
    Gameleon commits to solve the problem.
    </div>
    <Title>
    Payment
    </Title>
    <div className="information_text">
    In the event of doubt, the customer may at all times check his purchase history available on his online account. Please note that Gameleon is not in a position to solve any issues related to the failure of payment transactions. In such cases, the customer should address directly his credit card company or the financial service in charge.
    </div>
  </li>
  <li>
    <div id="LiabilityCustomer" className="information_title">
    Liability of the customer
    </div>
    <div className="information_text">
    The customer is liable to Gameleon without restriction for direct and proven damage caused by illegal intent or gross negligence. The customer’s liability to Gameleon for minor negligence is excluded.
    <p/>
    The customer is obliged to reimburse Gameleon for any expenditure due to the unauthorised use or misuse of his access data.
    </div>
  </li>
  <li>
    <div id="IP" className="information_title">
    Intellectual property
    </div>
    <div className="information_text">
    The entire contents of the
    website <Website/> are copyright protected by the Swiss law and belongs solely to Gameleon.

    Any trading, dissemination and forwarding of the contents for commercial purposes is strictly forbidden. All contents are to be used within the private sphere of the customer.

    In the event that the customer is an education professional, he may use the contents within the sphere of his own classroom or with his own students. Any trading, dissemination and forwarding of the contents to other professionals, including those working for the same institution, is strictly forbidden.

    Any modification or adaptation of the contents unless stated otherwise is strictly forbidden.
    Each PDF file is bound to a specific licence which the customer can access from the final overview page before the downloading process.
    Further information on licensing is available <Licensing text="here"/>.

    Consequences of non-compliance may include claims for compensation and for return of potential profit against the original customer.
  </div>
  </li>
  <li>
    <div id="Final" className="information_title">
    Final provisions
    </div>
    <div className="information_text">
    Gameleon reserves the right to change these GTC at any time.
    Changes will be published on the
    website <Website/> and
    enforceable as soon as they are published.
    <p/>
    If one or several provisions of these GTC are or become invalid or unachievable in whole or in part, this shall not affect the validity of the other provisions of these GTC. In such case, Gameleon will replace the invalid or unachievable provision by a provision that is valid and achievable and that comes as close as possible to the purpose of the provision to be replaced. The same applies in the event that there is an omission in these GTC.
    <p/>
    Swiss law solely governs these GTC and the possible disputes arising out of or in connection with the relationship between Gameleon and the customer.
    <p/>
    The sole legal venue for all disputes between Gameleon and the customer is the registered office of Gameleon. Gameleon is entitled however to take action against the customer at his domicile.
    </div>
  </li>
</ol>

            </div>

      </React.Fragment>
    );

  return (
    <Support footer_support={footer_support} policies={true} waves={waves} hideSearch={hideSearch} children={text}/>
  );
}

export default GeneralTermsAndConditions;
