// -*- coding: utf-8 -*-
import {
  //LicenceNoAdapt,
  LicenceForAdapt,
  FurtherInfoLicence,
  } from './const.js';

/************

Pictures

*/

const download_characters = {
  name: 'download_characters',
  link: 'Characters',
  header: {
    activity: 'Pictures',
    main: 'CHARACTERS',
    text: 'People or animals with no background',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/3_handsome_guy_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['Print them, laminate them, cut them.', 'style_text_bigger'],
        ['Do as you please.', 'style_text_bigger'],
        ['', 'style_space'],
        ['For class use or private use only, commercial use not allowed.', 'style_text_bigger'],
        FurtherInfoLicence,
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's1',
      samples3: ['swipe_s1_1', require('static/swipe_PDFs/3_pictures_characters/1_Neighbours_pdf.png')],
      samples4: [
        ['swipe_s1_2', require('static/swipe_PDFs/4_pictures_characters/1_Neighbours_2.jpg')],
        ['swipe_s1_1', require('static/swipe_PDFs/4_pictures_characters/1_Neighbours_1.jpg')],
        ['swipe_s1_3', require('static/swipe_PDFs/4_pictures_characters/1_Neighbours_3.jpg')],
        ['swipe_s1_4', require('static/swipe_PDFs/4_pictures_characters/1_Neighbours_4.jpg')],
      ],
      title: '1. NEIGHBOURS',
      textf: [
        ['4 PDF pack', 'style_red_bold'],
        ['\u2022 8 character portraits, 2 per sheet (4 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'characters_1_neighbours',
    },
    {
      name: 's2',
      samples3: ['swipe_s2_1', require('static/swipe_PDFs/3_pictures_characters/2_Neighbours_pdf.png')],
      samples4: [
        ['swipe_s2_2', require('static/swipe_PDFs/4_pictures_characters/2_Neighbours_2.jpg')],
        ['swipe_s2_1', require('static/swipe_PDFs/4_pictures_characters/2_Neighbours_1.jpg')],
        ['swipe_s2_3', require('static/swipe_PDFs/4_pictures_characters/2_Neighbours_3.jpg')],
        ['swipe_s2_4', require('static/swipe_PDFs/4_pictures_characters/2_Neighbours_4.jpg')],
      ],
      title: '2. NEIGHBOURS',
      textf: [
        ['4 PDF pack', 'style_red_bold'],
        ['\u2022 6 full body characters, 2 per sheet (3 sheets in A4 size)', 'style_red'],
        ['\u2022 1 full body character, on a sheet (1 sheet in A4 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'characters_2_neighbours',
    },
    {
      name: 's3',
      samples3: ['swipe_s3_1', require('static/swipe_PDFs/3_pictures_characters/Family_Members_pdf.png')],
      samples4: [
        ['swipe_s3_2', require('static/swipe_PDFs/4_pictures_characters/Family_Members_2.jpg')],
        ['swipe_s3_1', require('static/swipe_PDFs/4_pictures_characters/Family_Members_1.jpg')],
        ['swipe_s3_3', require('static/swipe_PDFs/4_pictures_characters/Family_Members_3.jpg')],
      ],
      title: 'FAMILY MEMBERS',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 6 character portraits, 2 per sheet (3 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'characters_family_members',
    },
    { name: 'blk1'},
    {
      name: 's4',
      samples3: ['swipe_s4_1', require('static/swipe_PDFs/3_pictures_characters/1_Crazy_Animals_pdf.png')],
      samples4: [
        ['swipe_s4_3', require('static/swipe_PDFs/4_pictures_characters/1_Crazy_Animals_3.jpg')],
        ['swipe_s4_1', require('static/swipe_PDFs/4_pictures_characters/1_Crazy_Animals_1.jpg')],
        ['swipe_s4_2', require('static/swipe_PDFs/4_pictures_characters/1_Crazy_Animals_2.jpg')],
        ['swipe_s4_4', require('static/swipe_PDFs/4_pictures_characters/1_Crazy_Animals_4.jpg')],
        ['swipe_s4_5', require('static/swipe_PDFs/4_pictures_characters/1_Crazy_Animals_5.jpg')],
        ['swipe_s4_6', require('static/swipe_PDFs/4_pictures_characters/1_Crazy_Animals_6.jpg')],
      ],
      title: '1. CRAZY ANIMALS',
      textf: [
        ['6 PDF pack', 'style_red_bold'],
        ['\u2022 12 animal portraits, 2 per sheet (6 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'characters_1_crazy_animals',
    },
    {
      name: 's5',
      samples3: ['swipe_s5_1', require('static/swipe_PDFs/3_pictures_characters/2_Crazy_Animals_pdf.png')],
      samples4: [
        ['swipe_s5_3', require('static/swipe_PDFs/4_pictures_characters/2_Crazy_Animals_3.jpg')],
        ['swipe_s5_1', require('static/swipe_PDFs/4_pictures_characters/2_Crazy_Animals_1.jpg')],
        ['swipe_s5_2', require('static/swipe_PDFs/4_pictures_characters/2_Crazy_Animals_2.jpg')],
        ['swipe_s5_4', require('static/swipe_PDFs/4_pictures_characters/2_Crazy_Animals_4.jpg')],
        ['swipe_s5_5', require('static/swipe_PDFs/4_pictures_characters/2_Crazy_Animals_5.jpg')],
        ['swipe_s5_6', require('static/swipe_PDFs/4_pictures_characters/2_Crazy_Animals_6.jpg')],
      ],
      title: '2. CRAZY ANIMALS',
      textf: [
        ['6 PDF pack', 'style_red_bold'],
        ['\u2022 12 full body animals, 2 per sheet (6 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'characters_2_crazy_animals',
    },
    { name: 'blk2'},
  ],
}


const download_scenes = {
  name: 'download_scenes',
  link: 'Scenes',
  header: {
    activity: 'Pictures',
    main: 'SCENES',
    text: 'Backgrounds with characters or not',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/7_nouveau_Betty_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['Print them, laminate them, cut them.', 'style_text_bigger'],
        ['Do as you please.', 'style_text_bigger'],
        ['', 'style_space'],
        ['For class use or private use only, commercial use not allowed.', 'style_text_bigger'],
        FurtherInfoLicence,
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's1',
      samples3: ['swipe_s1_1', require('static/swipe_PDFs/3_pictures_scenes/Dress_me_up_pdf.png')],
      samples4: [
        ['swipe_s1_2', require('static/swipe_PDFs/4_pictures_scenes/Dress_me_up_2.jpg')],
        ['swipe_s1_1', require('static/swipe_PDFs/4_pictures_scenes/Dress_me_up_1.jpg')],
      ],
      title: 'HURRY UP, DRESS ME UP!',
      textf: [
        ['2 PDF pack', 'style_red_bold'],
        ['\u2022 2 scenes (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'scenes_dress_me_up',
    },
    {
      name: 's2',
      samples3: ['swipe_s2_1', require('static/swipe_PDFs/3_pictures_scenes/Neighbours_A4_pdf.png')],
      samples4: [
        ['swipe_s2_1', require('static/swipe_PDFs/4_pictures_scenes/Neighbours_A4_1.jpg')],
        ['swipe_s2_2', require('static/swipe_PDFs/4_pictures_scenes/Neighbours_A4_2.jpg')],
      ],
      title: 'NEIGHBOURS (A4)',
      textf: [
        ['2 PDF pack', 'style_red_bold'],
        ['\u2022 1 scene in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'scenes_neighbours_A4',
    },
    {
      name: 's3',
      samples3: ['swipe_s3_1', require('static/swipe_PDFs/3_pictures_scenes/Neighbours_A3_pdf.png')],
      samples4: [
        ['swipe_s3_1', require('static/swipe_PDFs/4_pictures_scenes/Neighbours_A3_1.jpg')],
      ],
      title: 'NEIGHBOURS (A3)',
      textf: [
        ['1 PDF', 'style_red_bold'],
        ['\u2022 1 scene (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'scenes_neighbours_A3',
    },
    { name: 'blk1'},
    {
      name: 's4',
      samples3: ['swipe_s4_1', require('static/swipe_PDFs/3_pictures_scenes/Christmas_A4_pdf.png')],
      samples4: [
        ['swipe_s4_1', require('static/swipe_PDFs/4_pictures_scenes/Christmas_A4_1.jpg')],
        ['swipe_s4_2', require('static/swipe_PDFs/4_pictures_scenes/Christmas_A4_2.jpg')],
      ],
      title: 'CHRISTMAS (A4)',
      textf: [
        ['2 PDF pack', 'style_red_bold'],
        ['\u2022 1 scene in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'scenes_christmas_A4',
    },
    {
      name: 's5',
      samples3: ['swipe_s5_1', require('static/swipe_PDFs/3_pictures_scenes/Christmas_A3_pdf.png')],
      samples4: [
        ['swipe_s5_1', require('static/swipe_PDFs/4_pictures_scenes/Christmas_A3_1.jpg')],
      ],
      title: 'CHRISTMAS (A3)',
      textf: [
        ['1 PDF', 'style_red_bold'],
        ['\u2022 1 scene (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'scenes_christmas_A3',
    },
    { name: 'blk2'},
  ],
}


const download_any_others = {
  name: 'download_any_others',
  link: 'AnyOthers',
  header: {
    activity: 'Pictures',
    main: 'ANY OTHERS...',
    text: 'Objects or any pictures not included in previous categories',
  },
  wave_container:{
      style: { height: '850px' }
  },
  nb_waves : 4,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/4_doll_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['Print them, laminate them, cut them.', 'style_text_bigger'],
        ['Do as you please.', 'style_text_bigger'],
        ['', 'style_space'],
        ['For class use or private use only, commercial use not allowed.', 'style_text_bigger'],
        FurtherInfoLicence,
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's1',
      samples3: ['swipe_s1_1', require('static/swipe_PDFs/3_pictures_any_others/Dress_me_up_pdf.png')],
      samples4: [
        ['swipe_s1_1', require('static/swipe_PDFs/4_pictures_any_others/Dress_me_up_1.jpg')],
        ['swipe_s1_2', require('static/swipe_PDFs/4_pictures_any_others/Dress_me_up_2.jpg')],
        ['swipe_s1_3', require('static/swipe_PDFs/4_pictures_any_others/Dress_me_up_3.jpg')],
        ['swipe_s1_4', require('static/swipe_PDFs/4_pictures_any_others/Dress_me_up_4.jpg')],
      ],
      title: 'HURRY UP, DRESS ME UP!',
      textf: [
        ['4 PDF pack', 'style_red_bold'],
        ['\u2022 4 full body characters with corresponding clothes (4 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        LicenceForAdapt,
      ],
      documentId: 'any_others_dress_me_up',
    },
    { name: 'blk1'},
    { name: 'blk2'},
  ],
}

export {

  download_characters, download_scenes, download_any_others,

};
