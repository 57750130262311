// -*- coding: utf-8 -*-

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';

const HeaderUserInfo = ({children}) => {
  return (
    <React.Fragment>
      <Row className="information_header_container">
        <Col xs={2}/>
        <Col xs={10}>
          <LinkContainer className="inf_LinkContainer" to="/AuthAccount"><Button size="lg" variant="light">Account</Button></LinkContainer>
          {/*
            <LinkContainer className="inf_LinkContainer" to="/AuthPurchaseHistory"><Button size="lg" variant="light">Purchase history</Button></LinkContainer>
          */}
          <LinkContainer className="inf_LinkContainer" to={{pathname:"/AuthDownloadHistory", search:"?NW=1", }}><Button size="lg" variant="light">Download history</Button></LinkContainer>
        </Col>
      </Row>
    </React.Fragment>
    );
};

export default HeaderUserInfo;
