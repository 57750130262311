// -*- coding: utf-8 -*-

import React from 'react';
import { Link } from 'react-router-dom';
import ProtectedText from 'react-protected-text';
import Mailto from 'react-protected-mailto';

const debug = false;
const serviceWorkerActivatedInProd = false;

const payment_auto_enabled = false;

const websiteUrl = "https://gameleon.ch";
const websiteDescription = "Print and play, English, Learning, Teaching, Games, EFL, ESL";

const api = {
  "base": websiteUrl+":3001/v1",
  "user": "/api/users/",
  "userDownloadedDocument": "/api/userDownloadedDocument/",
  "userBoughtDocument": "/api/userBoughtDocument/",
  "document": "/api/document/",
  "documentMetadata": "/api/documentMetadata/",
  "allDocumentMetadata": "/api/documentMetadata",
}

const cookie = {
  buttonText: "OK, sure!",
  cookieName: "gameleon",
  text: "We sell printable resources for ESL and EFL teachers. Each PDF file is carefully conceived from hand-made sketches to the final graphic design. This website uses cookies to enhance the user's experience. We use them only not to mix your activity with some of the other users."
}

const warningCardText = 'The cards must be cut before the first use of the game. If possible, all game material should be printed in colour and laminated for a better preservation.';
const warningCard = warningCardText;

const warningCardTextStyle = [warningCardText, 'style_red'];
const warningCardStyle = [warningCard, 'style_red'];
const warningStep1 = ['Warning: material from step 1 is necessary.', 'style_red']

const licenceNoAdapt = ['Copyright licence – No adaptation', 'style_text_bigger_bold'];
const licenceForAdapt = ['Copyright licence – For adaptation', 'style_text_bigger_bold'];

const IDE = (props) => { return (<ProtectedText text="Closed"/>) };
const CH_ID = (props) => { return (<ProtectedText text="Closed"/>) };

const ContactMail = (props) => {
  return (<Mailto className="information_important" email='contact@gameleon.ch'/>)
}

const AddressOneLine = (props) => {
  const address = ``;
  return (
    <div className="information_text">
      Gameleon - Fernanda Monnier <ProtectedText className="phone_hide_il" text={address}/> <ContactMail/>
    </div>
  );
}

const Address = (props) => {
  return (
    <div className="information_text">
      <ProtectedText text="Gameleon, Fernanda Monnier"/>
      <br/>
    </div>
  );
}

const LicenceNoAdapt = (props) => {
  return (<Link key="LicenceNoAdapt" to="/Policies/Licensing#LicenceNoAdapt">Copyright licence - No adaptation</Link>);
};

const LicenceForAdapt = (props) => {
  return (<Link key="LicenceForAdapt" to="/Policies/Licensing#LicenceForAdapt">Copyright licence - For adaptation</Link>);
};

const GTC = (props) => {
  return (<Link className="information_important" key="GTC" to="/Support/GeneralInformation">General terms and conditions</Link>);
};
const DPS = (props) => {
  const replacement_text = props.text ? props.text : 'Data protection statement';
  return (<Link className="information_important" key="DPS" to="/Policies/DataProtectionStatement">{replacement_text}</Link>);
};
const Licensing = (props) => {
  const replacement_text = props.text ? props.text : 'Licensing';
  return (<Link className="information_important" key="Licensing" to="/Policies/Licensing">{replacement_text}</Link>);
};
const FurtherInfoLicence = (props) => {
  return (<span key={props.key} className="download_text style_text_bigger">Further information on licensing is available <Licensing text="here"/></span>);
};

const MyStuff = (props) => {
  const replacement_text = props.text ? props.text : 'My stuff';
  return (<Link className="information_important" to="/AuthMyStuff">{replacement_text}</Link>)
};

export { debug };
export { api, cookie, serviceWorkerActivatedInProd };
export { warningCardText, warningCard, warningCardTextStyle, warningCardStyle, warningStep1 };
export { licenceNoAdapt, licenceForAdapt, LicenceNoAdapt, LicenceForAdapt, GTC, DPS, Licensing, FurtherInfoLicence, MyStuff };
export { websiteUrl, websiteDescription };
export { payment_auto_enabled };
export { Address, AddressOneLine, IDE, CH_ID, ContactMail };
