// -*- coding: utf-8 -*-

import React, { useState, useEffect } from 'react';
import Table from 'react-bootstrap/Table'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from 'yup';

import { useAuth0 } from "../auth/react-auth0-wrapper";
import { GTC } from '../const.js';
import FooterSupport from '../comp/FooterSupport';
import Support from './Support';
import { Title } from '../comp/Information';
//import PaymentInformation from '../comp/PaymentInformation';
import OneDocCard from '../comp/OneDocCard';

import { buyDocumentList, getProductName } from '../lib-util.js';


import '../styles.scss';

const ShoppingCart = ({waves, hideSearch, documentMetadata, dataBoughtDoc, photos_map, cart, footer_support, removeFromCart, removeAllDocFromCart, purgeCart, checkoutUrl, setCheckoutUrl, children}) => {
  const { isAuthenticated, user, getTokenSilently, userProfile, setReloadUserProfile, loginWithRedirect } = useAuth0();
  const [getErrorMsg, setErrorMsg] = useState();
  const [cartTable, setCartTable] = useState([]);

  useEffect(() => {
    hideSearch();
  });

  //get shopping cart
  useEffect(() => {

    async function loadCart(){
      function getDocProperties(docKey){
        //console.log(documentMetadata);
        //console.log(documentMetadata.constructor.name);
        return documentMetadata && documentMetadata.constructor.name === 'Array' ? documentMetadata.filter(doc => doc.docKey === docKey)[0] : [];
      }

      try{
        //console.log(documentMetadata);
        var seq=1;
        const data = cart.map(docKey => {
          const doc = getDocProperties(docKey);
          //console.log(doc);
          return ({seq: seq++, ...doc })
        });

        //console.log(dataBoughtDoc);
        const docAlreadyBought = dataBoughtDoc.map(d=>d.docKey);
        //console.log(docAlreadyBought);
        const docAlreadyBoughtInCart = cart.filter(d=>docAlreadyBought.includes(d));
        //console.log(docAlreadyBoughtInCart);

        if (docAlreadyBoughtInCart && docAlreadyBoughtInCart.length > 0){
          setErrorMsg("Some documents have already been purchased, we have removed them from your cart");

          removeAllDocFromCart(docAlreadyBoughtInCart);
        }

        //console.log("loadCart");
        //console.log(data);
        if (typeof data !== 'undefined'){
          setCartTable(data);
          //return data;
        }
      }catch (err){
        setErrorMsg("loadCart failed "+err.message.substring(0,100));
      }
    };

    loadCart();
  }, [ cart, documentMetadata, dataBoughtDoc, removeAllDocFromCart ]);


  function transformCartData(data){
    //console.log("transformCartData");
    //console.log(data);

    const ret = {
      seq : `No. ${data.seq}`,
      productName: getProductName(data),
      price: data.docKey ? data.costInCredit.toFixed(2) : undefined,
      totalPrice: data.costInCredit,

      ...data,
    };
    //console.log(ret);
    return ret;
  }
  //console.log(cart);

  async function removeDocumentEvt(event, documentId, setErrorMsg) {
    event.preventDefault();

    setErrorMsg();
    //console.log("removeDocumentEvt "+documentId);

    try {
      removeFromCart(documentId);
    } catch (error) {
      //console.error(error);
      setErrorMsg(error.message)
    }
  }

  function getDataFormatted(data){
    const docKey = data.docKey;
    //const docKeyLinkId = `${data.seq}_lk_${docKey}`;
    const docKeyLink = data.link+"?download="+docKey;
    //<LinkContainer key={docKeyLinkId} className="LinkContainer" to={docKeyLink}>{data.link}</LinkContainer>
    //<Link key={docKeyLinkId} className="LinkContainer" to={docKeyLink}>{data.link}</Link>
    const isADoc = typeof docKey !== 'undefined';

    return (
      <tr key={data.seq} className="text">
        <td className="information_text cart_text">
          {isADoc &&
            data.seq
          }
        </td>
        <td className="phone_hide_tc">
          {isADoc &&
            <OneDocCard doc={data} photos_map={photos_map}/>
          }
        </td>
        {/*
        <td className="information_text cart_text">
          {isADoc &&
            <Link to={docKeyLink}>{data.link}</Link>
          }
        </td>
        */}
        <td className="text cart_text">
          {isADoc && (
            <React.Fragment>
              <Row>
                <Col className="cart_text_desc">
                  <Link to={docKeyLink}>
                    {data.productName}
                  </Link>
                </Col>
              </Row>
              <Row>
                <Col className="">
                  <Button className="remove_button" onClick={(evt) => removeDocumentEvt(evt, docKey, setErrorMsg)} variant="light">
                    Remove
                  </Button>
                </Col>
              </Row>
            </React.Fragment>
          )}
          {!isADoc &&
            data.productName
          }

          {/*isADoc && (
            <React.Fragment>
              <span className="cart_button_remove">
                <Button className="remove_button" onClick={(evt) => removeDocumentEvt(evt, docKey, setErrorMsg)} variant="light">
                  Remove
                </Button>
              </span>
            </React.Fragment>
            )
          */}
        </td>
        <td className="information_text cart_price">{data.price ? `${data.price} CHF` : ""}</td>
        {/*
          <td className="information_text cart_total_price">
            {data.totalPrice+" CHF"}
          </td>
        */}
      </tr>
    );
  }

  function cumulativsum(allObjects){
    const allObjectsPartialSum = (allObjects) => {
     const output = [];

     allObjects.forEach((o, index) => {
        const totalPrice = o.totalPrice ? o.totalPrice : 0;
        if(index === 0){
           output[index] = {...allObjects[0], totalPrice: totalPrice };
        }else{
           output[index] = {...allObjects[index], totalPrice: totalPrice + output[index - 1].totalPrice };
        }
     });
     return output;
    };
    let allObjectsSum = allObjects && allObjectsPartialSum(allObjects);
    return allObjectsSum;
  }

  //mixe credit and download to have the complete history of the user activity
  let allObjects = [];
  if (cartTable && cartTable.length > 0){
    allObjects.push(cartTable.map(transformCartData));
    allObjects = allObjects.flat();
  }
  //console.log(allObjects);

  const allObjectsSum = cumulativsum(allObjects);
  //console.log(allObjectsSum);
  const totalPrice = allObjectsSum && allObjectsSum.length > 0 ? allObjectsSum[allObjectsSum.length-1].totalPrice.toFixed(2) : undefined;

  allObjectsSum.push({ seq: -1, productName:"Total", price:totalPrice, totalPrice:totalPrice });

  //const wave_container = waves.wave_container;
  //const footer = waves.footer;


  async function handleSubmitCart(values, { setSubmitting }){
    // generate error message if too fast
    /* TODO?? Can't perform a React state update on an unmounted component. This is a no-op, but it indicates a memory leak in your application. To fix, cancel all subscriptions and asynchronous tasks in a useEffect cleanup function.
    Formik@https://gameleon.ch/static/js/vendors~main.chunk.js:23848:28
    setTimeout(() => {
      setSubmitting(false);
    }, 400);
    */

    //et si pas d'auth? message!
    if (isAuthenticated && user) {
      if (!userProfile){
        setErrorMsg("User's account incomplete.");
        return;
      }

      if (values.totalPrice <= 0){
        setErrorMsg("Add stuff to your cart.");
        return;
      }

      //console.log(user);
      //console.log(userProfile);
      const acceptedTerms = values.acceptedTerms;

      try{
        const token = await getTokenSilently();
        //console.log(`userProfile = ${userProfile.name}, totalPrice = ${values.totalPrice}, acceptedTerms=${acceptedTerms}`);

        //buyDocumentList
        //console.log(cart);
        const selectedPaymentModusValueLocal = Number(values.selectedPaymentModus);
        const data = await buyDocumentList(token, userProfile, acceptedTerms, selectedPaymentModusValueLocal, cart, values.totalPrice);
        //const data = { message: 'payment pending', checkoutUrl: "https://gameleon.ch:3001/v1/api/test" };
        //console.log("buyDocumentList");
        //console.log(data);

        //only change page if not in banking mode
        if (typeof data !== 'undefined' && typeof data.checkoutUrl !== 'undefined' && typeof checkoutUrl === 'undefined'){
          const checkoutUrlLocal= data.checkoutUrl;
          //const checkoutUrlLocal= "https://gameleon.ch:3001/v1/api/test";
          //console.log(`checkoutUrl handleSubmitCart : ${data.checkoutUrl}`);
          //console.log(`checkoutUrl fake : ${checkoutUrlLocal}`);
          setCheckoutUrl(checkoutUrlLocal);
        }

        purgeCart();
        setReloadUserProfile(true);
      }catch (err){
        setErrorMsg("Payment failed "+err.message);
      }
    }else{
      setErrorMsg("You have to login first.");
    }
  };

  //https://github.com/jquense/yup
  const validateDataSchema = Yup.object({
    acceptedTerms: Yup.boolean()
      .required('Required')
      .oneOf([true], 'You must accept the terms and conditions.'),
  });

  const AcceptGTC = () => (
    <Row>
      <Col xs={1} sm={2}/>
      <Col xs={11} sm={8}>
         <div className="user_account_group">
           <div className="user_account_item">
             <label className="user_account_label" htmlFor="acceptedTerms">To continue, accept the <GTC/> :</label>
             <Field type="checkbox" name="acceptedTerms" />
             <ErrorMessage className="user_account_error" name="acceptedTerms" component="div" />
           </div>
         </div>
      </Col>
      <Col xs={2}/>
    </Row>
  );

  const MessageInFrame = ({children}) => (
    <Row>
      <Col xs={1}/>
      <Col xs={10}>
        <div className="user_account_group">
          <div className="user_account_item">
            {children}
          </div>
        </div>
      </Col>
      <Col xs={1}/>
    </Row>
  );

  const BuyNowButton = ({isSubmitting, is_user_logged, is_user_email_verified, is_user_ok, is_price_above_0, is_acceptedTerms}) => {
      /*
    {is_user_email_verified ?
      ( is_price_above_0 && is_user_ok ? <BuyNowButton isSubmitting={isSubmitting}/> : (is_price_above_0 ? <CompletUserInfo/> : undefined) )
      : <VerifyEmail is_user_email_verified={is_user_email_verified}/>}
      */
    if (!is_price_above_0)
      return null;

    if (!is_user_logged)
      return (
        <Button className="download_button" variant="light"
          onClick={() =>
            loginWithRedirect({ })
          }>
          Sign up or log in to continue!
        </Button>
    );

    if (!is_user_email_verified)
      return (
        <MessageInFrame>
          <div className="information_important">Verify your e-mail and follow the instruction received</div>
        </MessageInFrame>
    );

    if (!is_user_ok)
      return (
        <MessageInFrame>
          <Link className="information_important" to="/AuthAccount">Click here to complete your user account before buying. Your contact information will appear on the invoice.</Link>
        </MessageInFrame>
    );

    if (!is_acceptedTerms)
      return (
        <AcceptGTC/>
    );

    return (
      <Row>
        <Col xs={5} sm={5}/>
        <Col xs={1} sm={7}>
          <Button disabled={isSubmitting} variant="light" className="download_button" type="submit">
          GET IT NOW!
          </Button>
        </Col>
      </Row>
    );
  }


  function testStringEmpty(s){
    return typeof s === 'undefined' || s.trim() === '';
  }


  const SelectPayment = () => {
    //console.log(creditPack);

      return (
      <Formik
         //important: all value must be defined here and have a default
         initialValues={{
           selectedPaymentModus: "1", //PSP
           totalPrice: totalPrice && totalPrice.toString(),
           acceptedTerms: true,
          }}
         onSubmit={handleSubmitCart}
         validationSchema={validateDataSchema}
      >
        {({ isSubmitting, values, touched }) => {

          const is_user_logged = typeof user !== 'undefined';
          const is_user_email_verified = typeof user !== 'undefined' && user.email_verified;
          const is_acceptedTerms = values.acceptedTerms;
          const is_price_above_0 = values.totalPrice > 0;
          const is_user_ok = isAuthenticated && user && userProfile
            && is_user_email_verified
            && userProfile.eMail !== userProfile.name
            && !testStringEmpty(userProfile.eMail)
            && !testStringEmpty(userProfile.firstName)
            && !testStringEmpty(userProfile.lastName)
            && !testStringEmpty(userProfile.address)
            && !testStringEmpty(userProfile.city)
            && !testStringEmpty(userProfile.codePostal)
            && !testStringEmpty(userProfile.country)
          //const is_payment_modus_banking = values.selectedPaymentModus === "0"
          //console.log("SelectPayment");
          //console.log(user);
          //console.log(userProfile);
          //console.log(`is_acceptedTerms=${is_acceptedTerms}, is_user_email_verified=${is_user_email_verified}, is_price_above_0=${is_price_above_0}, is_user_ok=${is_user_ok}`);

          return (
          <Form className="selectForm">
            {/*
            <Row>
              <Col xs={12}>
                <div role="group" aria-labelledby="my-radio-group" className="user_account_group">
                  {is_payment_modus_banking && (
                  <Row>
                    <Col xs={0} sm={4} className="phone_hide"/>
                    <Col xs={12} sm={8} className="selectedPaymentModus">
                      <label htmlFor="selectedPaymentModus">
                        <Field value="0" type="radio" name="selectedPaymentModus"/>
                      </label>
                      <span className="information_text">Bank account payment</span>
                    </Col>
                  </Row>
                  )}
                  {!is_payment_modus_banking && (
                  <Row>
                    <Col xs={0} sm={4} className="phone_hide"/>
                    <Col xs={12} sm={8} className="selectedPaymentModus">
                      <label htmlFor="selectedPaymentModus">
                        <Field value="1" type="radio" name="selectedPaymentModus"/>
                      </label>
                      <span className="information_text">Credit cards</span>
                    </Col>
                  </Row>
                  )}
                </div>

                { is_user_ok && is_acceptedTerms && is_price_above_0 && is_payment_modus_banking ? (
                  <PaymentInformation price={Number(values.totalPrice)} email={user.email}/>
                ) : null}
              </Col>
            </Row>
            */}

            <BuyNowButton isSubmitting={isSubmitting}
              is_user_logged={is_user_logged}
              is_user_email_verified={is_user_email_verified}
              is_user_ok={is_user_ok}
              is_price_above_0={is_price_above_0}
              is_acceptedTerms={is_acceptedTerms}/>
          </Form>
        );}
      }
      </Formik>
    )
  };

  const title = (
    <React.Fragment>
      <Row className="information_header_container">
        <Row className="information_text_header">
          <Col xs={2}/>
          <Col xs={10}>
              SELECTED STUFF
          </Col>
        </Row>
        <Row className="wave_container_gap_header"/>
      </Row>
    </React.Fragment>
  );

  //https://react-bootstrap.github.io/components/table/
  const text = (
    <React.Fragment>
      <Row className="information_text_container_cart">
        <Col xs={1} sm={1} lg={2}/>
        <Col xs={10} sm={10} lg={8} className="information_text_container_base">
            <Title>Cart current items</Title>
            <div className="user_account_error">{getErrorMsg}</div>
            <div className="information_text_container_base">
              <div className="text">
                <Table striped bordered hover responsive="sm" className="text cart">
                  <thead>
                    <tr>
                      <th className="cart_seq"># Nb</th>
                      <th className="phone_hide_tc cart_img">Selected product</th>
                      {/*<th className="cart_link">Location</th>*/}
                      <th className="cart_name">Product name</th>
                      <th className="cart_price">Price</th>
                      {/*
                        <th className="cart_total_price">Total Price</th>
                      */}
                    </tr>
                  </thead>
                  <tbody>
                  {allObjectsSum && allObjectsSum.map(d => getDataFormatted(d))}
                  </tbody>
                </Table>
              </div>
            </div>

            <SelectPayment/>
        </Col>
        <Col xs={1} sm={1} lg={2}/>
      </Row>

      {footer_support ?
        (<FooterSupport/>):null
      }
    </React.Fragment>
  );

  return (<Support waveNoLink waves={waves} hideSearch={hideSearch} children={text} title={title}/>);
}

export default ShoppingCart;
