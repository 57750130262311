// -*- coding: utf-8 -*-

import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form'

import checkboxesUnCheckedImg from 'static/bouton_vide_20px.png';
import checkboxesCheckedImg from 'static/bouton_vu_20px.png';

import { useNavigate } from 'react-router-dom';

const searchLabelRegex = new RegExp(/([^\w]+)/, 'g');

const initSearchFacetVisible = {
  activities: false,
  vocabulary: false,
  grammar: false,
  skills: false,
  languageLevel: false,
//  taskDifficulty: false,
  timing: false,
};

const initSearchFacetUse = {
  activities: false,
  vocabulary: false,
  grammar: false,
  skills: false,
  languageLevel: false,
//  taskDifficulty: false,
  timing: false,
};

const useSearchFacetFilterContext = (setSearchActivated, searchFacets) => {

  const [searchFacetFilter, setSearchFacetFilter] = useState({});
  const [searchFacetUse, setSearchFacetUse] = useState(initSearchFacetUse);

  const initSearchFacetFilter = {
    //by default uncheck activities to limit the search
    activities: searchFacets.activities.labels,
    vocabulary: searchFacets.vocabulary.labels,
    grammar: searchFacets.grammar.labels,
    skills: searchFacets.skills.labels,
    languageLevel: searchFacets.languageLevel.labels,
//        taskDifficulty: searchFacets.taskDifficulty.labels,
    time: searchFacets.time.labels,
  };

  function initSearchFacets() {
      //console.log("initSearchFacets");

      setSearchFacetFilter(initSearchFacetFilter);
  }

  function facetChangeAll(searchFacetName, state, navigate) {
    //console.log('facetChangeAll');
    //console.log(searchFacetName);

    var facetNewValues = Object.assign({}, searchFacetFilter);

    //if state is true reset to all labels of the facet or empty
    facetNewValues[searchFacetName] =  state ? initSearchFacetFilter[searchFacetName] : [];
    //console.log(facetNewValues);

    setSearchFacetFilter(facetNewValues)
    facetChangeUse(searchFacetName, !state);

    navigate("/SearchResult");
  }

  function facetChangeUse(facetName, state){
    var searchFacetUseNew = Object.assign({}, searchFacetUse);
    searchFacetUseNew[facetName] = state;
    setSearchFacetUse(searchFacetUseNew);
  }

	function facetChangeCheck(e, navigate) {
		var targetName = e.target.name;
		var checked = e.target.checked;

    //console.log('facetChangeCheck');
    //console.log("targetName="+targetName);

    const facet = targetName.split('%', 2);
    const facetName = facet[0];
    const facetNewValue = facet[1];
    //console.log(facet);

		if	(Object.keys(searchFacetFilter).includes(facetName)){
      var facetNewValues = Object.assign({}, searchFacetFilter);
      //const facetOldValue = searchFacetFilter[facetName];
      //console.log(facetNewValues);
      //console.log("facetName="+facetName+", facetNewValue="+facetNewValue+", checked="+checked+", facetOldValue="+facetOldValue);

      const dimFirstUse = !searchFacetUse[facetName];
      if (checked || dimFirstUse){
        //if this is the first use of this facet, clear all first
        if (dimFirstUse){
          facetNewValues[facetName] = [];
          facetChangeUse(facetName, true);
        }

        //add the new value
        facetNewValues[facetName].push(facetNewValue);
      }else{
        //filter out the new value
        facetNewValues[facetName] = facetNewValues[facetName].filter(val => val !== facetNewValue);
      }

      //console.log(facetNewValues);
      setSearchFacetFilter(facetNewValues)
      navigate("/SearchResult");
		}

    //activation of the search result
    setSearchActivated(true);
	}

  return {searchFacetFilter, setSearchFacetFilter, facetChangeAll, facetChangeCheck, initSearchFacets}
}

const SearchFacet = ({facetKey, facetValueObj, navigate, searchActivated, searchFacetVisible, searchFacetFilter, toggleFacetVisible, facetChangeAll, facetChangeCheck}) => {
  const facetValue = facetValueObj.name;
  const facetValueLC = facetValue.toLowerCase();

  //console.log(searchFacetFilter);

  //each search facet contains a list of checks
  /*
  import checkboxesPlusImg from 'static/bouton_plus_30px.png';
  import checkboxesMinusImg from 'static/bouton_moins_30px.png';
  import checkboxesUnCheckedImg from 'static/bouton_vide_20px.png';
  import checkboxesCheckedImg from 'static/bouton_vu_20px.png';
  <span className="search_facet_change_on" onClick={(evt) => facetChangeAll(facetKey, true)}>{'\u2611'}</span>
  <span className="search_facet_change_off" onClick={(evt) => facetChangeAll(facetKey, false)}>{'\u2610'}</span>
  */
  return (
    <React.Fragment>
      <Row key={"r_"+facetKey} className={"search_facet_header" + (searchFacetVisible[facetValueLC] ? "" : "_hidden")}>
        <Col>
          <div>
            <div key={"d_"+facetKey} className="search_facet" onClick={(evt) => toggleFacetVisible(evt, facetValue)}>{facetValue.toUpperCase()}</div>
            <React.Fragment>
              {searchFacetVisible[facetValueLC] && (
                <React.Fragment>
                <img className="search_facet_change_on" alt="search_facet_change_on" src={checkboxesCheckedImg} onClick={(evt) => facetChangeAll(facetKey, true, navigate)}/>
                <img className="search_facet_change_off" alt="search_facet_change_off" src={checkboxesUnCheckedImg} onClick={(evt) => facetChangeAll(facetKey, false, navigate)}/>
                </React.Fragment>
              )}
            </React.Fragment>
          </div>
          <div id={facetValue} className={"search_facet_options" + (searchFacetVisible[facetValueLC] ? "" : "_hidden")}>
            {facetValueObj.labels.map(labels =>
              (<SearchFacetCheck key={"sfc_"+facetKey+"_"+labels} facetKey={facetKey}
                facetValueObj={facetValueObj}
                navigate={navigate}
                labels={labels}
                searchFacetFilter={searchFacetFilter}
                facetChangeCheck={facetChangeCheck}/>)
            )}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
}

//one check is an option text from a facet on or off
function SearchFacetCheck({facetKey, facetValueObj, navigate, labels, searchFacetFilter, facetChangeCheck}) {
  const check_id = facetKey+"_"+labels.replace(searchLabelRegex,'_')
  const check_name = facetKey+"%"+labels

  if (typeof searchFacetFilter === 'undefined' || typeof searchFacetFilter[facetKey] === 'undefined'){
    //console.log("SearchContext searchFacetFilter undefined??")
    return 'SearchFacetCheck Loading...';
  }

  const check_state = searchFacetFilter[facetKey].includes(labels);

//  <Redirect push to={"/SearchResult?"+check_name+"="+check_state} />
//<Form.Check.Label htmlFor={check_name} className={"form-check-label-" + (check_state ? "plus" : "minus")}><div className="search_option_label">{labels}</div></Form.Check.Label>
//.form-check-label-checked
//<img className="form-check-label" alt="form-check-label-on" src={checkboxesCheckedImg}/>
//className={"form-check-label-" + (check_state ? "checked" : "unchecked")}
  return (
    <React.Fragment>
      <Form.Check type='checkbox' className="search_option">
        <Form.Check.Input className="search_option_input" type='checkbox' id={check_id} key={check_id} name={check_name}
          value={check_state ? 'on' : 'off'} checked={check_state}
          onChange={(evt) => facetChangeCheck(evt, navigate)}/>
        <Form.Check.Label htmlFor={check_id}>
          {check_state && (
            <img className="search_facet_change_on" alt="search_facet_change_on" src={checkboxesCheckedImg}/>
          )}
          {!check_state && (
            <img className="search_facet_change_off" alt="search_facet_change_off" src={checkboxesUnCheckedImg}/>
          )}
          <span className="search_option_label">{labels}</span>
        </Form.Check.Label>
      </Form.Check>
    </React.Fragment>
  )
}

function SearchContext ({
    searchFacets
    , searchFacetFilterContext
    , searchActivated
    , setSearchActivated}) {

  //displays the SearchResult if some selection are made
  const navigate = useNavigate();

  const [searchFacetVisible, setSearchFacetVisible] = useState(initSearchFacetVisible);

  const {searchFacetFilter, facetChangeAll, facetChangeCheck} = searchFacetFilterContext;

  const searchFacetsObj = Object.entries(searchFacets)


  //example of setState hierarchical
  function toggleFacetVisible(evt, id) {
    //console.log("toggleFacetVisible");
    const lid = id.toLowerCase()
    //console.log("lid = "+lid);

    const newstate = Object.assign({}, searchFacetVisible);
    newstate[lid] = !searchFacetVisible[lid];
    //console.log(searchFacetVisible);
    //console.log(newstate);

    setSearchFacetVisible(newstate);
    //activation of the search result
    setSearchActivated(true);
  }

  return (
    <React.Fragment>
      <div className="search_panel_container phone_hide">
        <Row className="search_panel_gap">
        </Row>
        <Row className="search_panel_facet">
          <Col xs={1}>
            <Form className="search_panel">
              {searchFacets && searchFacetsObj.map(m => (
                <SearchFacet key={"sf"+m[0]} facetKey={m[0]} facetValueObj={m[1]}
                  navigate={navigate}
                  searchActivated={searchActivated}
                  searchFacetVisible={searchFacetVisible}
                  searchFacetFilter={searchFacetFilter}
                  toggleFacetVisible={toggleFacetVisible}
                  facetChangeAll={facetChangeAll}
                  facetChangeCheck={facetChangeCheck}
                />))}
            </Form>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default SearchContext;
export {useSearchFacetFilterContext};
