// -*- coding: utf-8 -*-
import {
  LicenceNoAdapt,
  //LicenceForAdapt,
  } from './const.js';

/************

QuickGames

*/


const download_build_it_up = {
  name: 'download_build_it_up',
  link: 'BuildItUp',
  header: {
    activity: 'Quick Games',
    main: 'BUILD IT UP!',
    text: 'Vocabulary games',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 4,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/6_build_it_up_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['First students memorise a list of words. Then they play the game. The object is to write words in groups of 3. In turns, each student writes a word or corrects the spelling of his opponent. ', 'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in each pack.', 'style_text_bigger'],
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's1',
      samples3: ['swipe_s1_1', require('static/swipe_PDFs/3_quick_games_build_it_up/CLOTHES_PDF.png')],
      samples4: [
        ['swipe_s1_1', require('static/swipe_PDFs/4_quick_games_build_it_up/CLOTHES_1.jpg')],
        ['swipe_s1_2', require('static/swipe_PDFs/4_quick_games_build_it_up/CLOTHES_2.jpg')],
      ],
      title: 'CLOTHES',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Writing memorised clothes', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'build_it_up_clothes',
    },
    {
      name: 's2',
      samples3: ['swipe_s2_1', require('static/swipe_PDFs/3_quick_games_build_it_up/SPORTS_PDF.png')],
      samples4: [
        ['swipe_s2_1', require('static/swipe_PDFs/4_quick_games_build_it_up/SPORTS_1.jpg')],
        ['swipe_s2_2', require('static/swipe_PDFs/4_quick_games_build_it_up/SPORTS_2.jpg')],
      ],
      title: 'SPORTS',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Writing memorised sports', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'build_it_up_sports',
    },
    {
      name: 's3',
      samples3: ['swipe_s3_1', require('static/swipe_PDFs/3_quick_games_build_it_up/PHRASAL_VERBS_PDF.png')],
      samples4: [
        ['swipe_s3_1', require('static/swipe_PDFs/4_quick_games_build_it_up/PHRASAL_VERBS_1.jpg')],
        ['swipe_s3_2', require('static/swipe_PDFs/4_quick_games_build_it_up/PHRASAL_VERBS_2.jpg')],
      ],
      title: 'PHRASAL VERBS',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Writing memorised phrasal verbs', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'build_it_up_phrasal_verbs',
    },
  ],
}


const download_remember_where = {
  name: 'download_remember_where',
  link: 'RememberWhere',
  header: {
    activity: 'Quick Games',
    main: 'REMEMBER WHERE?',
    text: 'Vocabulary games',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/6_remember_where_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['First students memorise a list of words and their place on a picture. Then they receive a blank picture. The object of the game is to remember a maximum of the previous elements (their name and their place) as fast as possible.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in each pack.', 'style_text_bigger'],
        ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's3',
      samples3: ['swipe_s3_1', require('static/swipe_PDFs/3_quick_games_remember_where/THE_ISLAND_A4_PDF.png')],
      samples4: [
        ['swipe_s3_2', require('static/swipe_PDFs/4_quick_games_remember_where/THE_ISLAND_A4_2.jpg')],
        ['swipe_s3_3', require('static/swipe_PDFs/4_quick_games_remember_where/THE_ISLAND_A4_3.jpg')],
        ['swipe_s3_4', require('static/swipe_PDFs/4_quick_games_remember_where/THE_ISLAND_A4_4.jpg')],
        ['swipe_s3_5', require('static/swipe_PDFs/4_quick_games_remember_where/THE_ISLAND_A4_5.jpg')],
        ['swipe_s3_1', require('static/swipe_PDFs/4_quick_games_remember_where/THE_ISLAND_A4_1.jpg')],
      ],
      title: 'THE ISLAND (A4)',
      textf: [['6 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 picture A in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['\u2022 picture B in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Memorising names of landscape elements', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'remember_where_the_island_A4',
    },
    {
      name: 's4',
      samples3: ['swipe_s4_1', require('static/swipe_PDFs/3_quick_games_remember_where/THE_ISLAND_A3_PDF.png')],
      samples4: [
        ['swipe_s4_2', require('static/swipe_PDFs/4_quick_games_remember_where/THE_ISLAND_A3_2.jpg')],
        ['swipe_s4_3', require('static/swipe_PDFs/4_quick_games_remember_where/THE_ISLAND_A3_3.jpg')],
        ['swipe_s4_1', require('static/swipe_PDFs/4_quick_games_remember_where/THE_ISLAND_A3_1.jpg')],
      ],
      title: 'THE ISLAND (A3)',
      textf: [['4 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 picture A (1 sheet in A3 size)', 'style_red'],
        ['\u2022 picture B (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Memorising names of landscape elements', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'remember_where_the_island_A3',
    },
    { name: 'blk1'},
    { name: 'blk2'},
    {
      name: 's1',
      samples3: ['swipe_s1_1', require('static/swipe_PDFs/3_quick_games_remember_where/CHRISTMAS_A4_PDF.png')],
      samples4: [
        ['swipe_s1_2', require('static/swipe_PDFs/4_quick_games_remember_where/CHRISTMAS_A4_2.jpg')],
        ['swipe_s1_3', require('static/swipe_PDFs/4_quick_games_remember_where/CHRISTMAS_A4_3.jpg')],
        ['swipe_s1_4', require('static/swipe_PDFs/4_quick_games_remember_where/CHRISTMAS_A4_4.jpg')],
        ['swipe_s1_5', require('static/swipe_PDFs/4_quick_games_remember_where/CHRISTMAS_A4_5.jpg')],
        ['swipe_s1_1', require('static/swipe_PDFs/4_quick_games_remember_where/CHRISTMAS_A4_1.jpg')],
      ],
      title: 'CHRISTMAS (A4)',
      textf: [
        ['6 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 picture A in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['\u2022 picture B in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Memorising names of everyday objects', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'remember_where_christmas_A4',
    },
    {
      name: 's2',
      samples3: ['swipe_s2_1', require('static/swipe_PDFs/3_quick_games_remember_where/CHRISTMAS_A3_PDF.png')],
      samples4: [
        ['swipe_s2_2', require('static/swipe_PDFs/4_quick_games_remember_where/CHRISTMAS_A3_2.jpg')],
        ['swipe_s2_3', require('static/swipe_PDFs/4_quick_games_remember_where/CHRISTMAS_A3_3.jpg')],
        ['swipe_s2_1', require('static/swipe_PDFs/4_quick_games_remember_where/CHRISTMAS_A4_1.jpg')],
      ],
      title: 'CHRISTMAS (A3)',
      textf: [['4 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 picture A (1 sheet in A3 size)', 'style_red'],
        ['\u2022 picture B (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Memorising names of everyday objects', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'remember_where_christmas_A3',
    },
  ],
}


const download_have_a_guess = {
  name: 'download_have_a_guess',
  link: 'HaveAGuess',
  header: {
    activity: 'Quick Games',
    main: 'HAVE A GUESS...',
    text: 'Speaking games',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 4,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/7_have_a_guess_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['Each student receives a sheet. In the first round, student A starts by filling the grid with words or ticks. Student B tries to guess student\'s A choices by asking questions and filling his own grid gradually. In the second round, students change roles.', 'style_text_bigger'],
        ['No extra rules included.', 'style_text_bigger'],
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's1',
      samples3: ['swipe_s1_1', require('static/swipe_PDFs/3_quick_games_have_a_guess/CLOTHES_PDF.png')],
      samples4: [
        ['swipe_s1_1', require('static/swipe_PDFs/4_quick_games_have_a_guess/CLOTHES_1.jpg')],
        ['swipe_s1_2', require('static/swipe_PDFs/4_quick_games_have_a_guess/CLOTHES_2.jpg')],
        ['swipe_s1_3', require('static/swipe_PDFs/4_quick_games_have_a_guess/CLOTHES_3.jpg')],
        ['swipe_s1_4', require('static/swipe_PDFs/4_quick_games_have_a_guess/CLOTHES_4.jpg')],
      ],
      title: 'CLOTHES',
      textf: [
        ['4 PDF pack', 'style_red_bold'],
        ['\u2022 4 self correcting sheets (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Asking and answering in turns', 'style_text_bigger_thin'],
        ['about clothes', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'have_a_guess_clothes',
    },
    {
      name: 's2',
      samples3: ['swipe_s2_1', require('static/swipe_PDFs/3_quick_games_have_a_guess/FAMILY_PDF.png')],
      samples4: [
        ['swipe_s2_1', require('static/swipe_PDFs/4_quick_games_have_a_guess/FAMILY_1.jpg')],
        ['swipe_s2_2', require('static/swipe_PDFs/4_quick_games_have_a_guess/FAMILY_2.jpg')],
        ['swipe_s2_3', require('static/swipe_PDFs/4_quick_games_have_a_guess/FAMILY_3.jpg')],
        ['swipe_s2_4', require('static/swipe_PDFs/4_quick_games_have_a_guess/FAMILY_4.jpg')],
      ],
      title: 'FAMILY',
      textf: [['4 PDF pack', 'style_red_bold'],
        ['\u2022 4 self correcting sheets (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Asking and answering in turns', 'style_text_bigger_thin'],
        ['about family members', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'have_a_guess_family',
    },
    {
      name: 's3',
      samples3: ['swipe_s3_1', require('static/swipe_PDFs/3_quick_games_have_a_guess/CHRISTMAS_PDF.png')],
      samples4: [
        ['swipe_s2_1', require('static/swipe_PDFs/4_quick_games_have_a_guess/CHRISTMAS_1.jpg')],
        ['swipe_s2_2', require('static/swipe_PDFs/4_quick_games_have_a_guess/CHRISTMAS_2.jpg')],
        ['swipe_s2_3', require('static/swipe_PDFs/4_quick_games_have_a_guess/CHRISTMAS_3.jpg')],
        ['swipe_s2_4', require('static/swipe_PDFs/4_quick_games_have_a_guess/CHRISTMAS_4.jpg')],
      ],
      title: 'CHRISTMAS',
      textf: [['6 PDF pack', 'style_red_bold'],
      ['\u2022 4 self correcting sheets (in A4 size)', 'style_red'],
      ['\u2022 2 vocabulary sheets (in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Asking and answering in turns', 'style_text_bigger_thin'],
        ['about likes and wishes', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'have_a_guess_christmas',
    },
  ],
}


const download_down_or_across = {
  name: 'download_down_or_across',
  link: 'DownOrAcross',
  header: {
    activity: 'Quick Games',
    main: 'DOWN OR ACROSS?',
    text: 'Vocabulary games',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 4,
  photos_set: [
    {
      name: 'intro',
      img: require('static/illustrations/6_down_across_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['First students memorise a list of words. Then they play the game. The object is to write words down or across the grid. In turns, each student writes a word or corrects the spelling of his opponent.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Detailed game rules included in each pack.', 'style_text_bigger'],
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's1',
      samples3: ['swipe_s1_1', require('static/swipe_PDFs/3_quick_games_down_or_across/CLOTHES_PDF.png')],
      samples4: [
        ['swipe_s1_1', require('static/swipe_PDFs/4_quick_games_down_or_across/CLOTHES_1.jpg')],
        ['swipe_s1_2', require('static/swipe_PDFs/4_quick_games_down_or_across/CLOTHES_2.jpg')],
      ],
      title: 'CLOTHES',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Writing memorised clothes', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'down_or_across_clothes',
    },
    {
      name: 's2',
      samples3: ['swipe_s2_1', require('static/swipe_PDFs/3_quick_games_down_or_across/SPORTS_PDF.png')],
      samples4: [
        ['swipe_s2_1', require('static/swipe_PDFs/4_quick_games_down_or_across/SPORTS_1.jpg')],
        ['swipe_s2_2', require('static/swipe_PDFs/4_quick_games_down_or_across/SPORTS_2.jpg')],
      ],
      title: 'SPORTS',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Writing memorised sports', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'down_or_across_sports',
    },
    {
      name: 's3',
      samples3: ['swipe_s3_1', require('static/swipe_PDFs/3_quick_games_down_or_across/PERSONALITY_PDF.png')],
      samples4: [
        ['swipe_s3_1', require('static/swipe_PDFs/4_quick_games_down_or_across/PERSONALITY_1.jpg')],
        ['swipe_s3_2', require('static/swipe_PDFs/4_quick_games_down_or_across/PERSONALITY_2.jpg')],
      ],
      title: 'PERSONALITY',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Writing memorised personality adjectives', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'down_or_across_personality',
    },
  ],
}

export {

  download_build_it_up, download_remember_where, download_have_a_guess, download_down_or_across

};
