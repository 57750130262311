// -*- coding: utf-8 -*-

import React, { useState } from 'react';
import PdfRespCarousel from '../comp/PdfRespCarousel';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { LinkContainer } from 'react-router-bootstrap';
import { Link } from 'react-router-dom';
import { Header } from '../comp/AppHeader';
import ScrollToTop from '../comp/ScrollToTop'
import { DownloadText } from '../comp/Information';

import plusImg from 'static/bouton_plus_30px.png';

import { useLocation } from "react-router-dom";

import { useAuth0 } from "../auth/react-auth0-wrapper";

import { waveDownload, warningBox, downloadDocumentEvt } from '../lib-util.js';

//text lines with those style will not be displayed on the level 3 pages
const page3StylesExcludes = ['style_red', 'style_normal', 'style_text_bigger_bold_warning', '']

function DownloadPdf({config, waves, hideSearch, documentMetadata, dataBoughtDoc, addToCart, removeFromCart, setDataBoughtDocReload, children}) {
  //console.log('DownloadPdf')

  const { isAuthenticated, user, getTokenSilently, setReloadUserProfile, userProfile } = useAuth0();
  const [getErrorMsg, setErrorMsg] = useState();
  const [show, setShow] = useState(false);

  //console.log("DownloadPdf");
  //console.log(documentContent);
  //console.log(documentMetadata);

  //get parameters
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();

  const header = config.header;
  const link = "";
  const docKey = query.get("download");
  const searchModeStr = query.get("searchMode");
  const searchMode = (searchModeStr !== null);
  const pageLevel = (docKey !== null) ? 4 : 3;
  const pageLevel4 = pageLevel === 4;
  const photo_id = (pageLevel4) ? config.photos_set.map(x=>x.documentId).indexOf(docKey) : undefined;
  const photos_set = (pageLevel4) ? config.photos_set.slice(photo_id, photo_id+1) : config.photos_set;
  const footer = config.footer;

  const isDocumentAlreadBought = dataBoughtDoc.map(d=>d.docKey).includes(docKey);
  //console.log(isDocumentAlreadBought);
  const doc = documentMetadata && documentMetadata.filter && documentMetadata.filter(d=>d.docKey === docKey)[0];
  //console.log(doc);

  //console.log(userProfile);
  const doDisplayWarning = (typeof userProfile !== 'undefined') && userProfile.documentWarning;
  //console.log(doDisplayWarning);
/*  onClick={(x)=>hideSearch2("isOverview")}
  const hideSearch2 = (t) => {
    console.log(`hideSearch2 ${t}`);
    hideSearch();
  }
*/
  const documentContentCredit = new Map(documentMetadata.length ? documentMetadata.map(d => [d.docKey, d.costInCredit] ) : [])

  const ButtonGoToCart = () => {
    return (
      <Row>
        <Col xs={5}/>
        <Col xs={2}>
          <Link className="LinkContainer" to="/ShoppingCart">
            <Button className="download_button add_button">
              Go to selection
            </Button>
          </Link>
        </Col>
      </Row>
    )
  }

  const ButtonAddToCart = ({isDocumentAlreadBought, doDisplayWarning, documentId, setErrorMsg}) => {
    if (isDocumentAlreadBought) return null;
    return (
      <Row>
        <Col xs={5}/>
        <Col xs={2}>
          <Button className="download_button add_button" onClick={(evt) =>
            addDocumentEvt(evt, doDisplayWarning, documentId, setErrorMsg)} variant="light">
            Add to selection
          </Button>
        </Col>
      </Row>
    )
  }

  const TeachersNote = ({teachers_note}) => {
    if (!teachers_note) return null
    return (
      <a href={teachers_note} download type="application/pdf" rel="alternate" media="print">
        Free Teacher's notes
      </a>
    )
  }
  const photos_set_entites = getDocs(pageLevel, photos_set, header, footer)

  function get_obj(i){
    return photos_set_entites.slice((i/3)*4,(i/3+1)*4)
  }

  //console.log(`photo_id= ${photo_id}, pageLevel=${pageLevel}, pageLevel4=${pageLevel4}`)
  //console.log({photos_set_entites})


  function downloadText(isOverview, text_array, pageLevel) {
    let text_out = [];

    for(var t=0; t < text_array.length; t++){
      const text_config = text_array[t];

      const is_array_text_config = Array.isArray(text_config);
      if (is_array_text_config){
        const text_pure = text_config[0];
        const text_style = (text_config[1] ? text_config[1] : "style_normal");
        const text_style_custom = text_array[t][2];
        //some style are only written on the last level 4 pages
        const doPrintText = pageLevel === 4 || (pageLevel === 3 && !page3StylesExcludes.includes(text_style))
        if (doPrintText){
          const class_name = "download_text " + text_style
          text_out.push(<div key={"dt"+t} className={class_name} style={text_style_custom}>{text_pure}</div>)
        }
      }
      //object like LicenceNoAdapt only one level 4
      if (!is_array_text_config && (pageLevel === 4 || isOverview)) {
        text_out.push(text_config({key: "dt"+t}));
      }
    }
    return text_out
  }

  function getDocs(pageLevel, photos_set, header, footer) {

    const nb_photos_set = photos_set.length;
    let photo_col = [];

    for(var photo_idx=0; photo_idx < nb_photos_set; photo_idx++){

      const photo = photos_set[photo_idx];
      if(typeof photo === 'undefined'){
        throw new Error("Error in "+photos_set[0].name)
      }
      const photoFooter = photo.footer;
      const teachers_note = photo.notes;
      const docKey = photo.documentId;
      const isPageLevel3Img = pageLevel === 3 && typeof photo['samples3'] !== 'undefined';
      const isOverview = photo['img'];
      //const isBlk = !isPageLevel3Img && !isOverview;
      const isBlk = typeof photo['samples3'] === 'undefined' && !isOverview;
      //const isUserAuth = (isAuthenticated && user);

      //console.log(`photo.name ${photo.name}`);

      //Overview photo with explanation text
      if (isOverview){
        photo_col.push(
          <React.Fragment key={"p"+photo_idx}>
            <Col xs={6} sm={3} className="download_block download_block_ovw" style={photo.blocStyle} onClick={hideSearch}>
              <div className="download_photo_container">
                <div className="download_photo_container_int">
                  <img className="download_img_ovw" alt={photo.name} src={photo.img}/>
                </div>
              </div>
              {photo['title_img'] && (<img className="download_title_img" alt={photo.name} src={photo.title_img}/>)}
              {photo['title'] && (<div className="download_title" alt={photo.name}>{photo.title}</div> )}
              <div className="download_text_container">
                {photo['textf'] && downloadText(isOverview, photo.textf, pageLevel)}
              </div>
              <TeachersNote teachers_note={teachers_note}/>
            </Col>
          </React.Fragment>
        );
      }else
      {
        const documentId = photo['documentId'];

        const costInCredit = Number(documentContentCredit.get(documentId));
        //console.log("costInCredit");
        //console.log(documentMetadata);
        //console.log(documentContentCredit);
        const price = `Price: ${costInCredit.toFixed(2)} CHF`

        //download photo with all detail about the pdf
        if (isPageLevel3Img){
          const download_photo = (
            <div className="download_photo_container3">
              <div className="download_photo_container_int3">
                {/*<PdfRespCarousel photos={photo.samples} pageLevel={pageLevel}/>*/}
                <img className="download_img" src={photo.samples3[1]} alt={photo.samples3[0]}/>
              </div>
            </div>
          );
          const download_photo_title = (photo['title']) ? (<div className="download_title" alt={photo.name}>{photo.title}</div> ) : null;

          const download_text = (
            <>
              <div className="download_text_container">
                {photo['textf'] && downloadText(isOverview, photo.textf, pageLevel)}
              </div>
              {documentId && downloadButton(link, documentId, photo_idx, isDocumentAlreadBought)}
              {footer && photo_idx === footer['index']-1 && footer['text'] &&
              (<div className="download_footer" style={footer.blocStyle} alt="download_footer">{footer.text}</div>)
              }
            </>
          );

          const downloadBlock = (
            <React.Fragment key={"p"+photo_idx}>
              <Col xs={6} sm={3} className="download_block download_block_level3" style={photo.blocStyle} onClick={hideSearch}>
                <Link className="LinkContainer" to={link+"?download="+docKey}>
                  {(download_photo)}
                  {(download_photo_title)}
                </Link>
                {(download_text)}
              </Col>
            </React.Fragment>
          );

          photo_col.push(downloadBlock);
        }
//titre activity
//        <div className="download_title" alt={header.main}>{header.activity.toUpperCase()} - {header.main.toUpperCase()}</div>
        //1 download pdf in big
        if (pageLevel4){
          //console.log(doc);
          photo_col.push(
            <React.Fragment key={"fp"+photo_idx} >
              <Row key={"p"+photo_idx} className="information_download" onClick={hideSearch}>
                <Col xs={0} sm={1}/>
                <Col xs={12} sm={5} className="download_block download_block_level4" style={photo.blocStyle}>
                  <div className="download_photo_container4">
                    <div className="download_photo_container_int4">
                      <PdfRespCarousel photos={photo.samples4} pageLevel={pageLevel}/>
                    </div>
                  </div>
                </Col>
                <Col xs={12} sm={5} className="download_block download_block_level4">
                  {photo['title'] && (<div className="download_title" alt={photo.name}>{photo.title}</div> )}
                  <div className="download_text_container">
                    {photo['textf'] && downloadText(isOverview, photo.textf, pageLevel)}
                    <br/><br/>
                    {(doc && doc.languageLevel && doc.languageLevel.length > 0 && doc.languageLevel[0] !== "") ? (
                      <DownloadText>
                        {"Language level : "+doc.languageLevel.join(', ')}
                      </DownloadText>
                    ):undefined}
                    {(doc && doc.taskDifficulty && doc.taskDifficulty.length > 0 && doc.taskDifficulty[0] !== "") ? (
                      <DownloadText>
                        {"Task difficulty : "+doc.taskDifficulty.join(', ')}
                      </DownloadText>
                    ):undefined}
                    <DownloadText>
                      {doc && "Time needed : "+doc.time.join(', ')}
                    </DownloadText>
                    <DownloadText>
                      {doc && "Vocabulary : "+doc.vocabulary.join(', ').toLowerCase()}
                    </DownloadText>
                    <DownloadText>
                      {doc &&  "Grammar : "+doc.grammar.join(', ').toLowerCase()}
                    </DownloadText>
                  </div>
                  {/*
                  <div className="download_footer" alt="download_footer">{footer.text}</div>
                  <div className="download_footer_warning" alt="download_footer_warning">{footer.warning}</div>
                  */}
                  <div className="download_footer">
                    {photoFooter && photoFooter['textf'] && downloadText(isOverview, photoFooter.textf, pageLevel)}
                  </div>
                  <div className="download_footer_credit" alt="download_footer_credit">{price}</div>
                  {documentId && downloadButton(link, documentId, photo_idx, isDocumentAlreadBought)}
                </Col>
              </Row>
            </React.Fragment>
          );
        }
      }

      if (isBlk){
        photo_col.push(
                  <Col xs={6} sm={3} key={photo.name} className="download_block download_block_blk phone_hide" style={photo.blocStyle}>
                  </Col>
                  );
      }
    }

    return photo_col;
  }


  async function addDocumentEvt(event, doDisplayWarning, documentId, setErrorMsg) {
    event.preventDefault();

    setErrorMsg();
    //console.log(documentId);

    try {
      //const token = await getTokenSilently();
      addToCart(documentId);

      setReloadUserProfile(true);

      if (doDisplayWarning){
        setShow(true);
      }
    } catch (error) {
      //console.error(error);
      setErrorMsg(error.message)
    }
  }

  function downloadButton(link, documentId, photo_idx, isDocumentAlreadBought) {

    if (pageLevel === 3){
      //pageLevel 3
      return (
        <LinkContainer className="LinkContainer" to={{ pathname: link, search:"?download="+documentId }}>
          <Button variant="light">
            <span className="download_button_more">
            More
            <span className="phone_hide_il">
            &nbsp;
            details
            </span>
            &nbsp;
            </span>
            <img alt={photo_idx+"_plus"} src={plusImg} className="download_img_plus"/>
          </Button>
        </LinkContainer>
      );
    } else {
      //pageLevel 4
      if (isAuthenticated && user) {
        return (
          <React.Fragment>
            { isDocumentAlreadBought && (
              <React.Fragment>
                <Button className="download_button" onClick={(evt) =>
                  downloadDocumentEvt(evt, doDisplayWarning, documentId, user, getTokenSilently, setErrorMsg, setDataBoughtDocReload, setShow)} variant="light">
                  Download
                </Button>
                <div className="button_space"/>
              </React.Fragment>
            )}
            <ButtonAddToCart isDocumentAlreadBought={isDocumentAlreadBought} doDisplayWarning={doDisplayWarning} documentId={documentId} setErrorMsg={setErrorMsg}/>
            <div className="button_space"/>
            <ButtonGoToCart isDocumentAlreadBought={isDocumentAlreadBought}/>
          </React.Fragment>
        )
      } else {
        return (
          <React.Fragment>
            <ButtonAddToCart isDocumentAlreadBought={isDocumentAlreadBought} doDisplayWarning={doDisplayWarning} documentId={documentId} setErrorMsg={setErrorMsg}/>
            <div className="button_space"/>
            <ButtonGoToCart isDocumentAlreadBought={isDocumentAlreadBought}/>
          </React.Fragment>
        );
      }
    }
  }

  //const wave_container = waves.wave_container;
  //const wave_footer = waves.footer;

  return (
    <React.Fragment>
      <ScrollToTop/>
      <div>
        {(!pageLevel4 || searchMode) && (
          <Header header={header} pageLevel4={pageLevel4} searchMode={searchMode}/>
        )}
        {getErrorMsg && (
        <Row className="download_error">
          <Col xs={2}/>
          <Col xs={10}>
            <div>{getErrorMsg}</div>
          </Col>
        </Row>
        )}
        {show && warningBox(user, getTokenSilently, show, setShow, setReloadUserProfile)}
        {waveDownload(pageLevel, pageLevel4 ? 4 : config.nb_waves, waves, get_obj, 50)}
      </div>
    </React.Fragment>
  );
}

export default DownloadPdf;
