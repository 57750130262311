// -*- coding: utf-8 -*-

/*******************

 vague colorée seul
        height: '900px',
*/


const wave_dark_green =  {
  wave_container:{
      style: {
        backgroundRepeat: 'repeat-x',
        backgroundImage: 'url('+require('static/vagues/vague_vert_fonce.png')+')',
      },
  },
  footer:{
    style: {
      //backgroundColor: '#bac056',
      backgroundColor: '#b8c040',
    },
  },
}
;

const wave_light_green =  {
  wave_container:{
      style: {
        backgroundRepeat: 'repeat-x',
        backgroundImage: 'url('+require('static/vagues/vague_vert_clair.png')+')',
      },
    },
    footer:{
      style: {
        //backgroundColor: '#e9ea62',
        backgroundColor: '#e9ea41',
      },
    },
}
;

const wave_yellow =  {
  wave_container:{
      style: {
        backgroundRepeat: 'repeat-x',
        backgroundImage: 'url('+require('static/vagues/vague_jaune.png')+')',
      },
    },
    footer:{
      style: {
        //backgroundColor: '#ffed5a',
        backgroundColor: '#ffec2c',
      },
    },
}
;

const wave_orange =  {
  wave_container:{
      style: {
        backgroundRepeat: 'repeat-x',
        backgroundImage: 'url('+require('static/vagues/vague_orange.png')+')',
      },
    },
    footer:{
      style: {
        //backgroundColor: '#f8c84d',
        //backgroundColor: '#ffc524',
        backgroundColor: '#ffc624', //ok vague_orange
        //backgroundColor: '#ffc303',
      },
    },
}
;

const wave_dark_violet = {
  wave_container:{
      style: {
        backgroundRepeat: 'repeat-x',
        backgroundImage: 'url('+require('static/vagues/vague_violette.png')+')',
      },
  },
  footer:{
    style: {
      //backgroundColor: '#e4d2e9',
      backgroundColor: '#e8d1eb',
    },
  },
}
;

export { wave_dark_green, wave_light_green, wave_yellow, wave_orange, wave_dark_violet};
