// -*- coding: utf-8 -*-

import React, {useState} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';

import { Formik, Field, Form, ErrorMessage} from "formik";
import * as Yup from 'yup';

import { useAuth0 } from "../auth/react-auth0-wrapper";
import { updateUserProfile } from '../lib-util.js';
import { GTC } from '../const.js';

import Support from './Support';

//https://fr.reactjs.org/docs/forms.html#controlled-components
//https://react-bootstrap.netlify.app/components/forms/#forms-custom
//https://formik.org/docs/tutorial
const UserAccount = ({children, waves, hideSearch, footer_support}) => {
  const { isAuthenticated, user, userProfile, setReloadUserProfile, getTokenSilently } = useAuth0();
  const [getErrorMsg, setErrorMsg] = useState();

  //console.log("UserAccount", user);
  async function handleSubmit (values, { setSubmitting }) {
    setTimeout(() => {
      setSubmitting(false);
    }, 400);

    const data = { ...values,
      login: user.sub,
    }
    //console.log(data);

    const token = await getTokenSilently();

    try{
      setErrorMsg();
      updateUserProfile(token, data, user.sub, setReloadUserProfile);
      setErrorMsg("Profile saved");
    } catch (error) {
      //console.error(error);
      setErrorMsg(error.message)
    }
  }

  //https://github.com/jquense/yup
  const validateDataSchema = Yup.object({
    name: Yup.string()
        /*eslint-disable no-template-curly-in-string*/
       .min(3, 'Username must be ${min} characters or more.')
       .max(50, 'Username must be ${max} characters or less.')
       .notOneOf([user && user.email ? user.email : ''], "Don't use you email as username")
       .required('Username required'),
    firstName: Yup.string()
      .min(3, 'First name must be ${min} characters or more.')
      .max(50, 'First name must be ${max} characters or less.')
      .required('First name required'),
    lastName: Yup.string()
      .min(3, 'Last name must be ${min} characters or more.')
      .max(50, 'Last name must be ${max} characters or less.')
      .required('Last name required'),

    address: Yup.string()
      .min(1, 'Address must be ${min} characters or more.')
      .max(100, 'Address must be ${max} characters or less.')
      .required('Address required'),
    codePostal: Yup.string()
      .min(3, 'Zip code must be ${min} characters or more.')
      .max(10, 'Zip code must be ${max} characters or less.')
      .required('Zip code required'),
    city: Yup.string()
      .min(2, 'City must be ${min} characters or more.')
      .max(50, 'City must be ${max} characters or less.')
      .required('City required'),
    country: Yup.string()
      .min(2, 'Country must be ${min} characters or more.')
      .max(50, 'Country must be ${max} characters or less.')
      .required('Country required'),

    eMail: Yup.string()
      .email('Invalid email address.')
      .max(100, 'Email address must be ${max} characters or less.')
      .required('Email required'),
    /*
    documentWarning: Yup.boolean()
      .required('required')
      .oneOf([true, false], 'Reactivate document warning.'),
      <Field className="user_account_item" type="text" name="nickname" />
      */
    acceptedTerms: Yup.boolean()
      .required('required')
      .oneOf([true], 'You must accept the terms and conditions.'),
  });


    //Persist form state to the browser's sessionStorage so that form progress is kept inbetween page refreshes
  const text = () => (
      <React.Fragment>
            <div className="information_text_container information_text_container_account">
              <div className="information_title">
                User account
              </div>
              <div className="user_account_error">{getErrorMsg}</div>
              <div className="information_text">

                <Formik
                   //important: all value must be defined here and have a default
                   initialValues={{
                     name: (user && userProfile && userProfile.name) ? userProfile.name : (user.name ? user.name : ''),
                     firstName: (user && userProfile && userProfile.firstName) ? userProfile.firstName : (user.given_name ? user.given_name : ''),
                     lastName: (user && userProfile && userProfile.lastName) ? userProfile.lastName : (user.family_name ? user.family_name : ''),
                     eMail: (user && user.email ? user.email : ''),//userProfile.eMail
                     address: (user && userProfile && userProfile.address),
                     codePostal: (user && userProfile && userProfile.codePostal),
                     city: (user && userProfile && userProfile.city),
                     country: (user && userProfile && userProfile.country),
                     // phone: '079 123 45 67', , country:'CH',
                     documentWarning: user && userProfile && typeof userProfile.documentWarning !== 'undefined' ? userProfile.documentWarning : true,
                     showExamples: user && userProfile && typeof userProfile.showExamples !== 'undefined' ? userProfile.showExamples : true,
                     acceptedTerms: user && userProfile && typeof userProfile.acceptedTerms !== 'undefined' ? userProfile.acceptedTerms : false,
                    }}
                   onSubmit={handleSubmit}
                   validationSchema={validateDataSchema}
                >
                  {({ isSubmitting }) => (
                    <Form>
                      <Row>
                        <Col xs={1}/>
                        <Col xs={11}>
                          <div className="user_account_group">
                            <div className="user_account_item_group">
                              <label className="user_account_label" htmlFor="name">Username :</label>
                              <Field className="user_account_item" type="text" name="name" />
                            </div>

                            <div className="user_account_item_group">
                              <label className="user_account_label" htmlFor="firstName">First name :</label>
                              <Field className="user_account_item" type="text" name="firstName" />
                            </div>

                            <div className="user_account_item_group">
                              <label className="user_account_label" htmlFor="lastName">Last name :</label>
                              <Field className="user_account_item" type="text" name="lastName" />
                            </div>

                            <ErrorMessage className="user_account_error" name="name" component="div" />
                            <ErrorMessage className="user_account_error" name="firstName" component="div" />
                            <ErrorMessage className="user_account_error" name="lastName" component="div" />
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <Col xs={1}/>
                        <Col xs={11}>
                          <div className="user_account_group">
                            <div className="user_account_item_group">
                              <div className="user_account_item_group">
                                <label className="user_account_label" htmlFor="eMail">Email :</label>
                                <Field className="user_account_item_email" type="email" name="eMail" />
                                {user.email_verified ? '(verified)' : ' (unverified)'}
                              </div>
                            </div>

                            <div className="user_account_item_group">
                              <div className="user_account_item_group">
                                <label className="user_account_label" htmlFor="address">Address :</label>
                                <Field type="text" name="address" />
                              </div>
                              <div className="user_account_item_group">
                                <label className="user_account_label" htmlFor="codePostal">Zip code:</label>
                                <Field type="text" name="codePostal" />
                              </div>
                              <div className="user_account_item_group">
                                <label className="user_account_label" htmlFor="city">City :</label>
                                <Field type="text" name="city" />
                              </div>
                              <div className="user_account_item_group">
                                <label className="user_account_label" htmlFor="city">Country :</label>
                                <Field className="user_account_country" as="select" name="country">
                                  {/* https://fr.wikipedia.org/wiki/ISO_3166-1_alpha-2, https://www.iso.org/obp/ui/fr/#search
                                    <option value="">Unspecified</option>
                                    */}
                                  <option></option>
                                  <option value="CH">Switzerland</option>

                                  <option value="AT">Austria</option>
                                  <option value="FR">France</option>
                                  <option value="DE">Germany</option>
                                  <option value="LI">Liechtenstein</option>
                                  <option value="LU">Luxembourg</option>
                                  <option value="PT">Portugal</option>
                                  <option value="ES">Spain</option>
                                  <option value="IT">Italy</option>

                                  <option value="CA">Canada</option>
                                  <option value="GB">United Kingdom</option>
                                  <option value="US">United States of America</option>

                                  <option value="LU">Luxembourg</option>
                                  <option value="AF">Afghanistan</option>
                                  <option value="AX">Åland Islands</option>
                                  <option value="AL">Albania</option>
                                  <option value="DZ">Algeria</option>
                                  <option value="AS">American Samoa</option>
                                  <option value="AD">Andorra</option>
                                  <option value="AO">Angola</option>
                                  <option value="AI">Anguilla</option>
                                  <option value="AQ">Antarctica</option>
                                  <option value="AG">Antigua and Barbuda</option>
                                  <option value="AR">Argentina</option>
                                  <option value="AM">Armenia</option>
                                  <option value="AW">Aruba</option>
                                  <option value="AU">Australia</option>
                                  <option value="AZ">Azerbaijan</option>
                                  <option value="BS">Bahamas</option>
                                  <option value="BH">Bahrain</option>
                                  <option value="BD">Bangladesh</option>
                                  <option value="BB">Barbados</option>
                                  <option value="BY">Belarus</option>
                                  <option value="BE">Belgium</option>
                                  <option value="BZ">Belize</option>
                                  <option value="BJ">Benin</option>
                                  <option value="BM">Bermuda</option>
                                  <option value="BT">Bhutan</option>
                                  <option value="BO">Bolivia</option>
                                  <option value="BQ">Bonaire, Sint Eustatius and Saba</option>
                                  <option value="BA">Bosnia and Herzegovina</option>
                                  <option value="BW">Botswana</option>
                                  <option value="BV">Bouvet Island</option>
                                  <option value="BR">Brazil</option>
                                  <option value="IO">British Indian Ocean Territory</option>
                                  <option value="BN">Brunei</option>
                                  <option value="BG">Bulgaria</option>
                                  <option value="BF">Burkina Faso</option>
                                  <option value="BI">Burundi</option>
                                  <option value="KH">Cambodia</option>
                                  <option value="CM">Cameroon</option>
                                  <option value="CV">Cape Verde</option>
                                  <option value="KY">Cayman Islands</option>
                                  <option value="CF">Central African Republic</option>
                                  <option value="TD">Chad</option>
                                  <option value="CL">Chile</option>
                                  <option value="CN">China</option>
                                  <option value="CX">Christmas Island</option>
                                  <option value="CC">Cocos (Keeling) Islands</option>
                                  <option value="CO">Colombia</option>
                                  <option value="KM">Comoros</option>
                                  <option value="CG">Congo</option>
                                  <option value="CD">Congo, Republic of the</option>
                                  <option value="CK">Cook Islands</option>
                                  <option value="CR">Costa Rica</option>
                                  <option value="HR">Croatia</option>
                                  <option value="CU">Cuba</option>
                                  <option value="CW">Curaçao</option>
                                  <option value="CY">Cyprus</option>
                                  <option value="CZ">Czech Republic</option>
                                  <option value="DK">Denmark</option>
                                  <option value="DJ">Djibouti</option>
                                  <option value="DM">Dominica</option>
                                  <option value="DO">Dominican Republic</option>
                                  <option value="EC">Ecuador</option>
                                  <option value="EG">Egypt</option>
                                  <option value="SV">El Salvador</option>
                                  <option value="GQ">Equatorial Guinea</option>
                                  <option value="ER">Eritrea</option>
                                  <option value="EE">Estonia</option>
                                  <option value="ET">Ethiopia</option>
                                  <option value="FO">Faeroe Islands</option>
                                  <option value="FK">Falkland Islands</option>
                                  <option value="FJ">Fiji</option>
                                  <option value="FI">Finland</option>
                                  <option value="GF">French Guiana</option>
                                  <option value="PF">French Polynesia</option>
                                  <option value="TF">French Southern Territories</option>
                                  <option value="GA">Gabon</option>
                                  <option value="GM">Gambia</option>
                                  <option value="GE">Georgia</option>
                                  <option value="GH">Ghana</option>
                                  <option value="GI">Gibraltar</option>
                                  <option value="GR">Greece</option>
                                  <option value="GL">Greenland</option>
                                  <option value="GD">Grenada</option>
                                  <option value="GP">Guadeloupe</option>
                                  <option value="GU">Guam</option>
                                  <option value="GT">Guatemala</option>
                                  <option value="GG">Guernsey</option>
                                  <option value="GN">Guinea</option>
                                  <option value="GW">Guinea-Bissau</option>
                                  <option value="GY">Guyana</option>
                                  <option value="HT">Haiti</option>
                                  <option value="HM">Heard Island and McDonald Islands</option>
                                  <option value="HN">Honduras</option>
                                  <option value="HK">Hong Kong</option>
                                  <option value="HU">Hungary</option>
                                  <option value="IS">Iceland</option>
                                  <option value="IN">India</option>
                                  <option value="ID">Indonesia</option>
                                  <option value="IR">Iran</option>
                                  <option value="IQ">Iraq</option>
                                  <option value="IE">Ireland</option>
                                  <option value="IM">Isle of Man</option>
                                  <option value="IL">Israel</option>
                                  <option value="CI">Ivory Coast</option>
                                  <option value="JM">Jamaica</option>
                                  <option value="JP">Japan</option>
                                  <option value="JE">Jersey</option>
                                  <option value="JO">Jordan</option>
                                  <option value="KZ">Kazakhstan</option>
                                  <option value="KE">Kenya</option>
                                  <option value="KI">Kiribati</option>
                                  <option value="KP">Korea, Democratic People's Republic of</option>
                                  <option value="XK">Kosovo</option>
                                  <option value="KW">Kuwait</option>
                                  <option value="KG">Kyrgyzstan</option>
                                  <option value="LA">Laos</option>
                                  <option value="LV">Latvia</option>
                                  <option value="LB">Lebanon</option>
                                  <option value="LS">Lesotho</option>
                                  <option value="LR">Liberia</option>
                                  <option value="LY">Libya</option>
                                  <option value="LT">Lithuania</option>
                                  <option value="MO">Macau</option>
                                  <option value="MK">Macedonia </option>
                                  <option value="MG">Madagascar</option>
                                  <option value="MW">Malawi</option>
                                  <option value="MY">Malaysia</option>
                                  <option value="MV">Maldives</option>
                                  <option value="ML">Mali</option>
                                  <option value="MT">Malta</option>
                                  <option value="MH">Marshall Islands</option>
                                  <option value="MQ">Martinique</option>
                                  <option value="MR">Mauritania</option>
                                  <option value="MU">Mauritius</option>
                                  <option value="YT">Mayotte</option>
                                  <option value="MX">Mexico</option>
                                  <option value="FM">Micronesia</option>
                                  <option value="MD">Moldova</option>
                                  <option value="MC">Monaco</option>
                                  <option value="MN">Mongolia</option>
                                  <option value="ME">Montenegro</option>
                                  <option value="MS">Montserrat</option>
                                  <option value="MA">Morocco</option>
                                  <option value="MZ">Mozambique</option>
                                  <option value="MM">Myanmar</option>
                                  <option value="NA">Namibia</option>
                                  <option value="NR">Nauru</option>
                                  <option value="NP">Nepal</option>
                                  <option value="NL">Netherlands</option>
                                  <option value="AN">Netherlands Antilles</option>
                                  <option value="NC">New Caledonia</option>
                                  <option value="NZ">New Zealand</option>
                                  <option value="NI">Nicaragua</option>
                                  <option value="NE">Niger</option>
                                  <option value="NG">Nigeria</option>
                                  <option value="NU">Niue</option>
                                  <option value="NF">Norfolk Island</option>
                                  <option value="MP">Northern Marianas</option>
                                  <option value="NO">Norway</option>
                                  <option value="OM">Oman</option>
                                  <option value="PK">Pakistan</option>
                                  <option value="PW">Palau</option>
                                  <option value="PS">Palestine</option>
                                  <option value="PA">Panama</option>
                                  <option value="PG">Papua New Guinea</option>
                                  <option value="PY">Paraguay</option>
                                  <option value="PE">Peru</option>
                                  <option value="PH">Philippines</option>
                                  <option value="PN">Pitcairn Islands</option>
                                  <option value="PL">Poland</option>
                                  <option value="PR">Puerto Rico</option>
                                  <option value="QA">Qatar</option>
                                  <option value="RE">Réunion</option>
                                  <option value="RO">Romania</option>
                                  <option value="RU">Russia</option>
                                  <option value="RW">Rwanda</option>
                                  <option value="BL">Saint Barthélemy</option>
                                  <option value="SH">Saint Helena</option>
                                  <option value="KN">Saint Kitts and Nevis</option>
                                  <option value="LC">Saint Lucia</option>
                                  <option value="MF">Saint Martin</option>
                                  <option value="PM">Saint Pierre and Miquelon</option>
                                  <option value="VC">Saint Vincent and the Grenadines</option>
                                  <option value="WS">Samoa</option>
                                  <option value="SM">San Marino</option>
                                  <option value="ST">São Tomé and Príncipe</option>
                                  <option value="SA">Saudi Arabia</option>
                                  <option value="SN">Senegal</option>
                                  <option value="RS">Serbia</option>
                                  <option value="SC">Seychelles</option>
                                  <option value="SL">Sierra Leone</option>
                                  <option value="SG">Singapore</option>
                                  <option value="SX">Sint Maarten (Dutch sectorl)</option>
                                  <option value="SK">Slovakia</option>
                                  <option value="SI">Slovenia</option>
                                  <option value="SB">Solomon Islands</option>
                                  <option value="SO">Somalia</option>
                                  <option value="ZA">South Africa</option>
                                  <option value="GS">South Georgia and the South Sandwich Islands</option>
                                  <option value="KR">South Korea</option>
                                  <option value="SS">South Sudan</option>
                                  <option value="LK">Sri Lanka</option>
                                  <option value="SD">Sudan</option>
                                  <option value="SR">Suriname</option>
                                  <option value="SJ">Svalbard and Jan Mayen</option>
                                  <option value="SZ">Swaziland</option>
                                  <option value="SE">Sweden</option>
                                  <option value="SY">Syria</option>
                                  <option value="TW">Taiwan</option>
                                  <option value="TJ">Tajikistan</option>
                                  <option value="TZ">Tanzania</option>
                                  <option value="TH">Thailand</option>
                                  <option value="TL">Timor-Leste</option>
                                  <option value="TG">Togo</option>
                                  <option value="TK">Tokelau</option>
                                  <option value="TO">Tonga</option>
                                  <option value="TT">Trinidad and Tobago</option>
                                  <option value="TN">Tunisia</option>
                                  <option value="TR">Turkey</option>
                                  <option value="TM">Turkmenistan</option>
                                  <option value="TC">Turks and Caicos Islands</option>
                                  <option value="TV">Tuvalu</option>
                                  <option value="UG">Uganda</option>
                                  <option value="UA">Ukraine</option>
                                  <option value="AE">United Arab Emirates</option>
                                  <option value="UM">United States Minor Outlying Islands</option>
                                  <option value="UY">Uruguay</option>
                                  <option value="UZ">Uzbekistan</option>
                                  <option value="VU">Vanuatu</option>
                                  <option value="VA">Vatican City State</option>
                                  <option value="VE">Venezuela, Bolivarian Republic of</option>
                                  <option value="VN">Vietnam</option>
                                  <option value="VG">Virgin Islands, British</option>
                                  <option value="VI">Virgin Islands, U.S.</option>
                                  <option value="WF">Wallis and Futuna</option>
                                  <option value="EH">Western Sahara</option>
                                  <option value="YE">Yemen</option>
                                  <option value="ZM">Zambia</option>
                                  <option value="ZW">Zimbabwe</option>
                                </Field>
                              </div>
                            </div>
                            <ErrorMessage className="user_account_error" name="eMail" component="div" />
                            <ErrorMessage className="user_account_error" name="address" component="div" />
                            <ErrorMessage className="user_account_error" name="codePostal" component="div" />
                            <ErrorMessage className="user_account_error" name="city" component="div" />
                            <ErrorMessage className="user_account_error" name="country" component="div" />
                            {/*
                            <Field type="text" name="phone" />
                            <ErrorMessage name="phone" component="div" />
                            <Field type="text" name="country" />
                            <ErrorMessage name="country" component="div" />
                            */}
                          </div>
                        </Col>
                     </Row>

                     <Row>
                       <Col xs={1}/>
                       <Col xs={11}>
                           {false && (//hide the documentWarning reset button
                           <div className="user_account_group">
                             <div className="user_account_item">
                               <label className="user_account_label" htmlFor="documentWarning">Document warning message status :</label>
                               <Field type="checkbox" name="documentWarning" />
                               <ErrorMessage name="documentWarning" component="div" />
                             </div>
                           </div>
                           )}
                           <div className="user_account_group">
                             {(!userProfile || !userProfile.acceptedTerms) && (
                               <div className="user_account_item">
                                 <label className="user_account_label" htmlFor="acceptedTerms">To continue, accept the <GTC/> :</label>
                                 <Field type="checkbox" name="acceptedTerms" />
                                 <ErrorMessage className="user_account_error" name="acceptedTerms" component="div" />
                               </div>
                              )}
                              <div className="user_account_item">
                                <label className="user_account_label" htmlFor="showExamples">Show homepage examples :</label>
                                <Field type="checkbox" name="showExamples" />
                                <ErrorMessage name="showExamples" component="div" />
                              </div>
                           </div>
                       </Col>
                     </Row>
                     <Row>
                       <Col xs={7}/>
                       <Col xs={2}>
                         <Button disabled={isSubmitting} variant="light" className="download_button" type="submit">
                            Save profile
                         </Button>
                       </Col>
                     </Row>
                    </Form>
                  )}
                </Formik>

              </div>
            </div>

        {children}
      </React.Fragment>
    );

  const text_no_auth = (
      <React.Fragment>
        {children}
      </React.Fragment>
    );

  if (isAuthenticated && user) {
    //console.log(user);
    //console.log(userProfile);
    return (
      <Support footer_support={footer_support} userInfo waves={waves} hideSearch={hideSearch} children={text()}/>
    );
  }

  return (
    <Support footer_support={footer_support} userInfo waves={waves} hideSearch={hideSearch} children={text_no_auth}/>
  );
}

export default UserAccount;
