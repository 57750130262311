//import React, { } from 'react';
//import ReactDOM from 'react-dom';
import { createRoot } from 'react-dom/client';
import * as serviceWorker from './serviceWorker';

//don't change anything, this order is difficult to have correct!
//https://github.com/webpack/webpack/issues/215
//https://github.com/facebook/create-react-app/issues/7107
//https://github.com/facebook/create-react-app/issues/7190
//import 'bootstrap/dist/css/bootstrap.min.css';
//import './static/bootstrap.min.css';
import './App.scss';
import './App.css';

import config from "./auth/config.json";

import { GameleonAuth0Provider } from "./auth/react-auth0-wrapper";
import { AppProvider } from "./AppContext";
import AppRouter from './App';
import { serviceWorkerActivatedInProd } from './const.js';

/*eslint no-extend-native: ["error", { "exceptions": ["String"] }]*/
String.prototype.trunc = String.prototype.trunc ||
      function(n){
          return (this.length > n) ? this.substr(0, n-1) + '...' : this;
      };

const container = document.getElementById("root")
const root = createRoot(container); // createRoot(container!) if you use TypeScript

root.render(
  <GameleonAuth0Provider
    config={config}
  >
    <AppProvider>
      <AppRouter />
    </AppProvider>
  </GameleonAuth0Provider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://github.com/facebook/create-react-app/blob/master/packages/cra-template/template/README.md
if (serviceWorkerActivatedInProd){
  serviceWorker.register();
}
else {
  serviceWorker.unregister();
}
