// -*- coding: utf-8 -*-

import React from 'react';
import Row from 'react-bootstrap/Row';

const ExpandableWave = ({content, no_extension_color, wave_container_style, footer_style, gap}) => {
  return (
    <React.Fragment key="ew">
      <div className="screen_background" style={footer_style}/>
      <div className="screen_background_white"/>
      <Row className="wave_background">
        <Row className="wave_container" style={wave_container_style}>
        </Row>
        {typeof gap !== 'undefined' && (
          <Row key="g1" className={"wave_container_gap"+gap}/>
        )}
        <Row className="wave_content" style={typeof no_extension_color === 'undefined' && footer_style ? footer_style : undefined}>
          {content}
        </Row>
      </Row>
    </React.Fragment>
    );
};

export default ExpandableWave;
