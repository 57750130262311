// -*- coding: utf-8 -*-

import React from 'react';
import Mailto from 'react-protected-mailto'
import { Information, Title } from '../comp/Information';
import Support from './Support';
import { Address, IDE, CH_ID } from '../const.js';

const DataProtectionStatement = ({waves, hideSearch, footer_support}) => {

  const text = (
      <React.Fragment>
            <div className="information_text_container information_text_container_support information_text_scroll">
              <Title>
                Data protection statement
              </Title>
              <Title>
                Legal notice
              </Title>
              <Information>
                Gameleon, sole proprietorship
              </Information>
              <Information>
                IDE\UID: <IDE/>
              </Information>
              <Information>
                CH-ID: <CH_ID/>
              </Information>
              <Title>
                Registered office
              </Title>
              <Address/>
              <Title>
                Gameleon customer service contact: <Mailto className="information_important" email='data@gameleon.ch'/>
              </Title>
              <br/>
              <br/>
              <Information>
              In this data protection statement you will learn about what personal data we collect, why we do it and how we process it. You will also learn how you can have access to your personal information if you wish so.
              </Information>
              <Title>
              Who is responsible?
              </Title>
              <Information>
                <Address/> with Company ID n°: <IDE/> - is responsible for the collecting and processing of your personal data.
              </Information>
              <Title>
              What is personal data?
              </Title>
              <Information>
              Personal data is any information that can be directly or indirectly linked to a person, making that person identifiable to others; for example: a name, address, telephone number or IP address.
              </Information>
              <Title>
              What personal data do we collect?
              </Title>
              <Information>
              When you visit our website, we collect personal information about you for a variety of purposes.
              Apart from your username and e-mail address necessary for the registration,
              we collect any information connected with your browsing on our website,
              such as the files you view or download.
              However, we do not collect any information related to the enabling of payment transactions.
              We have delegated that power to an external financial service.
              We only keep track of the amounts you spend on PDF files.
              You can also have access to that information by checking your purchase history.
              </Information>
              <Title>
              Why do we use personal data?
              </Title>
              <Information>
              We use your personal data to provide you with the best possible service. We may use your e-mail address to contact you in case of problem. Moreover, we may collect the information connected with your browsing in order to improve the quality and quantity of our products and services. This information remains confidential. We do not share any of your personal data to external parties.
              </Information>
              <Title>
              How do we protect your data?
              </Title>
              <Information>
              We have put in place technical measures to protect the confidentiality and integrity of your personal data. In order to cover up all your communication with our website we use client to server HTTPS encryption. Moreover, we use a firewall to protect our backend server. However, you should bear in mind that the transmission of information via the internet and other IT infrastructures involves certain security risks. Consequently, we can give no full guarantee of the safety of information transmitted to us by these means.
              </Information>
              <Title>
              How long do we retain your personal data?
              </Title>
              <Information>
              We keep your personal data for as long as it is necessary according to the purpose for which it was collected in the first place. Retention time depends on the type of the data. We delete your personal data when we no longer need it.
              </Information>
              <Title>
              How can you have access to your information?
              </Title>
              <Information>
              If you would like a copy of the information that we have about you, please contact us
              at <Mailto className="information_important" email='data@gameleon.ch'/>.
              If any piece of information is incorrect, we will correct it at your request. You can also ask us to delete your data and no longer use it. Please bear in mind that deleting your data may limit our ability to provide you with our services.
              </Information>
              <Title>
              How can you contact us?
              </Title>
              <Information>
              Please contact us
              at <Mailto className="information_important" email='data@gameleon.ch'/> if
              you have any questions about our data protection statement or about the information we hold about you.
              </Information>
            </div>

      </React.Fragment>
    );

  return (
    <Support footer_support={footer_support} policies={true} waves={waves} hideSearch={hideSearch} children={text}/>
  );
}

export default DataProtectionStatement;
