// -*- coding: utf-8 -*-

const examples = [
  {
    name: "Set1"
    ,img: [
      [1, new URL("examples/1_secret_pizza_export_web_920x1200px_HQ.jpg", window.location.href), "/BoardGames/SecretPizzaGame?download=secret_pizza_game_1_A3"]
      , [2, new URL("examples/2_im_an_alien_export_web_920x1200px_HQ.jpg", window.location.href), "/BoardGames/IMAnAlienGame?download=im_an_alien_game_1_A3"]
      , [3, new URL("examples/3_treasure_island_export_web_920x1200px_HQ.jpg", window.location.href), "/BoardGames/TreasureIslandGame?download=treasure_island_game_1_A3"]
      , [4, new URL("examples/4_crazy_animals_export_web_920x1200px_HQ.jpg", window.location.href), "/BoardGames/CrazyAnimalsGame?download=crazy_animals_game_2"]
      , [5, new URL("examples/5_dress_me_up_export_web_920x1200px_HQ.jpg", window.location.href), "/Modules/DressMeUp?download=dress_me_up_5_writing"]
      , [6, new URL("examples/6_absurd_inventions_export_web_920x1200px_HQ.jpg", window.location.href), "/Modules/AbsurdInventions?download=absurd_inventions_2_reading"]
      //[1, new URL("examples/modules/absurd_inventions_step3_72dpi_L1500xH2000_2.jpg", window.location.href), "/Modules/AbsurdInventions?download=absurd_inventions_3_reading"]
      //,[2, new URL("examples/modules/crazy_animals_72dpi_L1500xH2000px_1.jpg", window.location.href), "/Modules/CrazyAnimals"]
      //,[3, new URL("examples/modules/so_much_stuff_72dpi_L1500xH2000px_1.jpg", window.location.href), "/Modules/SoMuchStuff"]
    ]
  }
  /*
  ,{
    name: "Quick Games"
    ,img: [
      [4, new URL("examples/quick_games/build_it_up_clothes_72dpi_L1500xH2000px.jpg", window.location.href), "/Modules/BuildItUp"]
      ,[5, new URL("examples/quick_games/family_72dpi_L1500xH2000.jpg", window.location.href), "/QuickGames/HaveAGuess?download=have_a_guess_family"]
      ,[6, new URL("examples/quick_games/the_island_72dpi_L1500xH2000_avec_logo_1.png", window.location.href), "/QuickGames/RememberWhere?download=remember_where_the_island_A3"]
    ]
  }
  */
  ];

export default examples;
