import React, { useState, useEffect, useContext, useCallback } from "react";
import {createAuth0Client} from "@auth0/auth0-spa-js";

import axios from	"axios"
import {api} from "../const.js";

export const GameleonAuth0Context = React.createContext();
export const useAuth0 = () => useContext(GameleonAuth0Context);

export const GameleonAuth0Provider = ({
  children,
  config,
}) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(undefined);
  const [auth0Client, setAuth0] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);
  const [userProfile, setUserProfile] = useState(undefined);
  const [reloadUserProfile, setReloadUserProfile] = useState(true);

  //console.log(`window.location.pathname=${window.location.pathname}`);
  //console.log(`window.location.search=${window.location.search}`);

  useEffect(() => {
    const initAuth0 = async () => {

      const initOptions = {
        domain: config.Gameleon.domain,
        clientId: config.Gameleon.clientId,
        authorizationParams: {
          audience: config.Gameleon.audience,
          redirect_uri: window.location.origin,
        },
        //use Refresh Token Rotation https://community.auth0.com/t/why-is-authentication-lost-after-refreshing-my-single-page-application/56276
        useRefreshTokens: true,
        cacheLocation: "localstorage",
        useRefreshTokensFallback: true,
      }

      //console.log(initOptions, "initAuth0 initOptions");
      const auth0FromHook = await createAuth0Client(initOptions);
      setAuth0(auth0FromHook);

      // A function that routes the user to the right place after login
      const onRedirectCallback = appState => {
        window.history.replaceState(
          {},
          document.title,
          appState && appState.targetUrl
            ? appState.targetUrl
            : window.location.pathname
        );
        //console.log("onRedirectCallback: "+appState.targetUrl);
      };

      if (window.location.search.includes("code=") &&
          window.location.search.includes("state=")) {
        try{
          const { appState } = await auth0FromHook.handleRedirectCallback();
          //console.log("handleRedirectCallback ok");
          onRedirectCallback(appState);
        }catch(error){
          //console.log("error handleRedirectCallback");
        }
      }

      const isAuthenticated = await auth0FromHook.isAuthenticated();
      //console.log("initAuth0 isAuthenticated = "+isAuthenticated);

      setIsAuthenticated(isAuthenticated);

      let do_get_auth0_user = isAuthenticated;
      if (do_get_auth0_user) {
        const user = await auth0FromHook.getUser();
        setUser(user);
      }
      //console.log(`initAuth0 do_get_auth0_user = ${do_get_auth0_user}, Auth0 user: `, user);

      setLoading(false);
    };

    initAuth0();
  }, [config, reloadUserProfile]);


  useEffect(() => {
    //call server API
    const getUserInfo = async (user) => {
      //get user data
      const url = `${api.base}${api.user}${user.sub}`;
      const token = await auth0Client.getTokenSilently();

      // Auth0: sub: "auth0|xx", email, email_verified, "nickname": "b", "name": "b@gameleon.ch",
      // Google: sub: "google-oauth2|xxx", family_name: "M", given_name: "Al"​, name: "Al M", nickname: "al.m"
      // Facebook: sub: "facebook|xxx", email, email_verified, family_name, given_name, name, nickname
      const is_auth0 = user.sub && user.sub.startsWith("auth0");
      const is_google = user.sub && user.sub.startsWith("google");
      const is_facebook = user.sub && user.sub.startsWith("facebook");

      // const userAuth0 = getAuth0UserInfo(user, token);
      const email = user.email;
      const username = (is_auth0 && user.nickname && user.nickname.length > 3) ? user.nickname : (is_google ? user.name : (is_facebook ? user.name : user.email) );
      const firstName = is_auth0 ? '' : (is_google ? user.given_name : (is_facebook ? user.given_name : '') );
      const lastName = is_auth0 ? '' : (is_google ? user.family_name : (is_facebook ? user.family_name : '') );
      //console.log("getUserInfo getUserInfo");
      //console.log(user);
      //console.log("getUserInfo is_auth0="+is_auth0+", is_google="+is_google+", is_facebook="+is_facebook);
      //console.log("getUserInfo email="+email+", username="+username+", firstName="+firstName+", lastName="+lastName);

      await axios.get(url, {
          headers: {
            "Authorization": `Bearer ${token}`,
            "email": email,
            "username": username,
            "firstName": firstName,
            "lastName": lastName,
          }
        })
        .then(response => {
          //console.log("getUserInfo response");
          //console.log(response);
          //console.log(response.data.representation);

          const userProfileI = response.data;

          const userInitialized = userProfileI && response.status === 200;
          //console.log("userInitialized ", userInitialized);
          if (userInitialized){
            //console.log(userProfileI);

            const data = {
              login: user.sub,

              name: userProfileI.name,
              firstName: userProfileI.firstName,
              lastName: userProfileI.lastName,

              eMail: userProfileI.eMail,

              address: userProfileI.address,
              codePostal: userProfileI.codePostal,
              city: userProfileI.city,
              country: userProfileI.country,

              documentWarning: userProfileI.documentWarning,
              showExamples: userProfileI.showExamples,
              acceptedTerms: userProfileI.acceptedTerms,

              numberOfCredit: userProfileI.numberOfCredit,
              paymentCustomerId: userProfileI.paymentCustomerId,
            }

            //console.log("Auth0 setUserProfile");
            //console.log(data);
            setUserProfile(data);
            setReloadUserProfile(false);

            //sessionStorage.setItem("userProfile", data);
            //sessionStorage.setItem("firstName", userProfile.firstName);
            //sessionStorage.setItem("lastName", userProfile.lastName);
            return data;
          }
        })
        .catch (error => {
            //console.error("getUserInfo user");
            //console.error(error);
            return;
        });
    }

    let do_get_gameleon_user = user && reloadUserProfile;
    //console.log(`getUserInfo loading=${loading}, user=${user}, reloadUserProfile=${reloadUserProfile}: do_get_gameleon_user=${do_get_gameleon_user}`);
    if (do_get_gameleon_user) {
      getUserInfo(user);
      //setReloadUserProfile(false);
    }
    /* cleaning before closing object
    return () => {
      setUserProfile(undefined);
    };
    */
  }, [auth0Client, isAuthenticated, loading, user, reloadUserProfile]);//don't compare others states

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error); //ok
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    setLoading(false);
    setIsAuthenticated(true);
    setUser(user);
  };

  //https://github.com/auth0/auth0-react/blob/master/src/auth0-provider.tsx
  const loginWithRedirect = useCallback( (opts) => auth0Client && auth0Client.loginWithRedirect(opts), [auth0Client] );
  // eslint-disable-next-line
  /*
  const loginWithRedirectCallback = useCallback( (opts) => {

    const loginWithRedirectCallbackFct = async (opts) => {
      //console.log(`loginWithRedirectCallback loading=${loading}, user=${user}, reloadUserProfile=${reloadUserProfile}: auth0Client=${auth0Client}`);
      setLoading(true);
      await auth0Client.loginWithRedirect(opts);
      const user_local = await auth0Client.getUser();
      setLoading(false);
      setIsAuthenticated(true);
      setUser(user_local);
    };

    auth0Client && loginWithRedirectCallbackFct(opts)
    }, [auth0Client]
  );
  */
  const getTokenSilently = useCallback( (opts) => auth0Client && auth0Client.getTokenSilently(opts), [auth0Client] );
  //const getIdTokenClaims = useCallback( (opts) => auth0Client && auth0Client.getIdTokenClaims(opts), [auth0Client] );
  //const getTokenWithPopup = useCallback( (opts) => auth0Client && auth0Client.getTokenWithPopup(opts), [auth0Client] );
  const logout = useCallback( (opts) => auth0Client && auth0Client.logout(opts), [auth0Client] );
  const getUser = useCallback( (opts) => auth0Client && auth0Client.getUser(opts), [auth0Client] );

  return (
    <GameleonAuth0Context.Provider
      value={{
        isAuthenticated,
        user,
        getUser,
        userProfile,
        loading,
        setReloadUserProfile,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        loginWithRedirect,
        getTokenSilently,
        //getIdTokenClaims: getIdTokenClaims,
        //getTokenWithPopup: getTokenWithPopup,
        logout: logout
      }}
    >
      {children}
    </GameleonAuth0Context.Provider>
  );
};
