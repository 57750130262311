// -*- coding: utf-8 -*-

import React from 'react';
import Card from 'react-bootstrap/Card';
import {LinkContainer} from 'react-router-bootstrap';

import { formatDateToString } from '../lib-util.js';

//doc : {docKey, activities, text, title, downloadDate}
const OneDocCard = (props) => {
  const docKey = props.doc.docKey;
  const link = props.doc.link;
  const activities = props.doc.activities;
  const text = props.doc.text;
  const title = props.doc.title;
  const downloadDate = props.doc.downloadDate;

  const searchMode = props.searchMode;
  const photos_map = props.photos_map;

  function getPhoto(id){
    const photo = photos_map.get(id);
    if (typeof photo === "undefined")
      return (`Photo ${id} not found`)
    else
      return (<Card.Img src={photo} width="350px" height="260px" className="card_img"/>)
  }
  const linkKey = "lnk_"+docKey;
  const cardKey = "card_"+docKey;

  return (
    <LinkContainer key={linkKey} className="LinkContainer" to={{ pathname:link, search: "?download="+docKey+(searchMode ? "&searchMode=1" : ""), }}>
      <Card key={cardKey} variant="top">
        <div className="card_photo_container3">
          <div className="card_photo_container_int3">
            {getPhoto(docKey)}
          </div>
        </div>
        <div className="card_text_zone">
          <Card.Body>
            <Card.Text className="card_text">{activities.join(', ').toUpperCase()} - {title}</Card.Text>
            <Card.Text className="card_text">{text}</Card.Text>
            {downloadDate && (<Card.Text className="card_text">Downloaded: {formatDateToString(downloadDate)}</Card.Text>)}
          </Card.Body>
        </div>
      </Card>
    </LinkContainer>
  );
}

export default OneDocCard;
