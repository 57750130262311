// -*- coding: utf-8 -*-

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';

const HeaderSupport = ({children}) => {
  return (
    <React.Fragment>
      <Row className="information_header_container">
        <Col xs={2}/>
        <Col xs={10}>
          <LinkContainer className="inf_LinkContainer" to="/Support/GeneralInformation"><Button size="lg" variant="light">General information</Button></LinkContainer>
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default HeaderSupport;
