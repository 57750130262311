// -*- coding: utf-8 -*-

import React from 'react';
import { Information, Title } from '../comp/Information';

import { Licensing } from '../const.js';

import Support from './Support';

const GeneralInformation = ({waves, hideSearch, footer_support}) => {

  const text = (
      <React.Fragment>
            <div className="information_text_container information_text_container_support information_text_scroll">
                <Title>
                  Our products
                </Title>
                <Information>
                  All our products are PDF files. They come in packs or single sheets.
                  <br/>
                  Before downloading any PDF files you can find the following information:
                  <ul>
                  <li>the number of pages of the file and their size</li>
                  <li>the type of activities (eg: sheets or keys),</li>
                  <li>the price of the product.</li>
                  </ul>
                </Information>
                <Information>
                  Most PDF files will also include other information, such as:
                  <ul>
                  <li>the practised skills (speaking, reading, writing, listening or vocabulary learning),</li>
                  <li>the approximate language level,</li>
                  <li>the task difficulty,</li>
                  <li>the time needed for the preparation or the execution of the task</li>
                  </ul>
                </Information>
                <Information>
                  <ul>
                  <li>All practised skills are available. However the listening skill is rare. The activities including a listening skill are NOT based on a recoding.
                  They involve a person reading a text to others.</li>
                  <li>The language level varies little since most activities and games are suited for beginners or false beginners in English.
                  However activities or games with the same language level can be more or less difficult, depending on the task. </li>
                  <li>The time needed is merely estimated. It can vary depending on the type of class or language level of the students.</li>
                  </ul>
                </Information>
                <Title>
                  Size and colour
                </Title>
                <Information>
                  Most PDF files are the size of a regular paper sheet (A4 size, 210 x 297 mm).
                  Some pictures and game boards are twice as big (A3 size, 297 x 420 mm).
                  However any A3 sheets are also available in A4 size.
                  In that case the sheet is simply split in 2 parts, each in A4 size.
                  Make sure to select the size corresponding to your printer before downloading the file.
                  Be careful, some sheets and games need to be printed in colour.
                  Pay attention to the warnings in the description of the products.
                </Information>
                <Title>
                  Resolution and quality
                </Title>
                <Information>
                  All PDF files have a resolution of 300 dpi required for printing.
                  However the quality of the PDF files when printed may vary depending on the printer you use.
                  If possible print in colour and set your printer on higher quality.
                  Besides products to be used frequently, such as board games and card games, are best preserved when laminated.
                </Information>
                <Title>
                  Categories of activities
                </Title>
                <div>
                  <Title>Pictures</Title>
                  <Information>
                  They are PDF packs of images, with no text.
                  All images are fit for class use or private use, but no commercial use is allowed. Further information on the licences of our products is available <Licensing text="here"/>.
                  </Information>
                  <Title>Quick games</Title>
                  <Information>
                  These PDF packs usually include the game rules, a vocabulary sheet and a game board.
                  As the game boards of the vocabulary games are meant for written use, they can simply be printed or photocopied.
                  </Information>
                  <Title>Board games</Title>
                  <Information>
                  This section contains both board games and card games.
                  But in each case the PDF packs include the necessary game rules. These games need more preparation.
                  If possible, they should be printed in colour and laminated for a better preservation. As for the card games, the cards should be cut out and laminated in advance.
                  </Information>
                  <Title>Modules</Title>
                  <Information>
                  They represent a sequence of PDF packs that follow a logical progression, but each pack can also be used individually.
                  Each module includes free teacher's notes that guide you through the various steps.
                  </Information>
                  <Title>Search tool</Title>
                  <Information>
                  If you are looking for specific activities, you can use the search tool which uses a detailed list of search criteria, such as a vocabulary and a grammar sections.
                  Simply tick the topics you are interested in to have access to the corresponding PDF files.
                  </Information>
                </div>
            </div>

      </React.Fragment>
  );

  return (
    <Support footer_support={footer_support} support={true} waves={waves} hideSearch={hideSearch} children={text}/>
  );
}

export default GeneralInformation;
