// -*- coding: utf-8 -*-

import { websiteUrl } from './const.js';

const wave_home = {
  name: 'HOME', link: '/',
  header: {
    img: `${websiteUrl}/GAMELEON_titre.png`,
//    text: 'English the fun way. Just print and play.',
//      text: 'English the fun way, Print and play teaching resources',
      text: 'Printable resources for English teachers',
//    text2: 'Print and play teaching resources',
  },
  wave_container:{
      style: {
        backgroundImage: 'url('+require('static/vagues/vague_home.png')+')',
        height: '950px',
      },
  },
  wave_content:{
    //style: { backgroundColor: '#737d30', }
  },
  footer:{
//    style: { border: '2px solid rgb(120, 63, 129)', }
  },
  waves:
  [
  {
    name: 'activity_pictures', link: '/Pictures',
    textMain: 'PICTURES',
    textSub1: 'Plain pictures',
    textSub2: 'to be used as you please',
    img: require('static/illustrations/3_handsome_guy_TC.png'),
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
//    className: 'col-sm-3',
    textStyle: {top: '00px'},
    imgStyle: {},//maxWidth: 'calc(120px - 0.01vmin)'
//    waveStyle: {
//      backgroundImage: 'url('+require('static/vagues/home/accueil_vague_1_5_fond_plat.png')+')',
//      backgroundPosition: '0px 0px',
//      top: '0px', height: '400px',
//    }
  },
  {
    name: 'activity_quick_games', link: '/QuickGames',
    textMain: 'QUICK GAMES',
    textSub1: 'Games for 2 to 3 players',
    textSub2: 'with simple rules',
    img: require('static/illustrations/6_reveil_matin_TC.png'),
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
//    className: 'col-sm-3',
    textStyle: {},
    imgStyle: {},
  },
  {
    name: 'activity_boardgames', link: '/BoardGames',
    textMain: 'BOARD GAMES',
    textSub1: 'Games to be played in groups',
    textSub2: 'with more complex rules',
    img: require('static/illustrations/6_des_pions_TC.png'),
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
//    className: 'col-sm-3',
    textStyle: {},
    imgStyle: {},
  },
  {
    name: 'activity_modules', link: '/Modules',
    textMain: 'MODULES',
    textSub1: 'Step by step sheets',
    textSub2: 'including various skills and games',
    img: require('static/illustrations/6_crayon_feuilles_TC.png'),
    blocStyle: { zIndex: '4', top: '0px', left: '0px' },
//    className: 'col-sm-3',
    textStyle: {},
    imgStyle: {},
  },
//  { className: "wave_gap", },
]}
;

const wave_picture = {
  name: 'PICTURES', link: '/Pictures',
  header: {
    main: 'PICTURES',
    text: 'Plain pictures to be used as you please',
  },
  wave_container:{
    style: {
      backgroundImage: 'url('+require('static/vagues/vague_orange.png')+')',
    }
  },
  wave_content:{
    //style: { backgroundColor: '#f8c84d', }
    style: { backgroundColor: '#ffc524', }
  },
  footer:{
//    style: { border: '2px solid rgb(120, 63, 129)', }
//    style: { backgroundColor: 'rgb(249, 199, 25)', height: 'calc(100vh - (900px + 170px))', }
  },
  waves:
  [
  {
    name: 'activity_characters',
    textMain: 'CHARACTERS',
    textSub1: 'People or animals',
    textSub2: 'with no background',
    img: require('static/illustrations/3_handsome_guy_TC.png'),
    link: '/Pictures/Characters',
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
    waveStyle: {
      //backgroundImage: 'url('+require('static/vagues/vague_orange_1_5.png')+')',
      //backgroundPosition: '00px 0px',
      //top: '0px', height: '400px',
    }
  },
  {
    name: 'activity_scenes',
    textMain: 'SCENES',
    textSub1: 'Backgrounds',
    textSub2: 'with characters or not',
    img: require('static/illustrations/7_nouveau_Betty_TC.png'),
    link: '/Pictures/Scenes',
    blocStyle: { zIndex: '4', top: '0px',left: '00px',},
  },
  {
    name: 'activity_any_others',
    textMain: 'ANY OTHERS...',
    textSub1: 'Objects or any pictures',
    textSub2: 'not included in previous categories',
    img: require('static/illustrations/4_doll_TC.png'),
    link: '/Pictures/AnyOthers',
    blocStyle: { zIndex: '4', top: '0px',left: '00px',},
  },
//  { className: "wave_gap", },
//  { className: "wave_gap", },
]}
;


const wave_quick_games = {
  name: 'QUICKGAMES', link: '/QuickGames',
  header: {
    main: 'QUICK GAMES',
    text: 'Games for 2 to 3 players with simple rules',
  },
  wave_container:{
      style: {
        backgroundImage: 'url('+require('static/vagues/vague_jaune.png')+')',
      }
  },
  wave_content:{
//    style: { backgroundColor: '#ffed5a', }
    style: { backgroundColor: '#ffc524', }
  },
  footer:{
    //style: { backgroundColor: 'rgb(249, 211, 28)', height: 'calc(100vh - (900px + 170px))', }
  },
  waves:
  [
  {
    name: 'downoracross',
    textMain: 'DOWN OR ACROSS?',
    textSub1: 'Vocabulary games',
    textSub2: 'Writing memorised words on a grid',
    img: require('static/illustrations/6_down_across_TC.png'),
    link: '/QuickGames/DownOrAcross',
    blocStyle: { zIndex: '4', top: '00px', left: '00px',},
//    waveStyle: {
//      backgroundImage: 'url('+require('static/vagues/vague_jaune_1_5.png')+')',
//      backgroundPosition: '00px 0px',
//      top: '0px', height: '400px',
//    }
  },
  {
    name: 'builditup',
    textMain: 'BUILD IT UP!',
    textSub1: 'Vocabulary games',
    textSub2: 'Writing memorised words in groups of three',
    img: require('static/illustrations/6_build_it_up_TC.png'),
    link: '/QuickGames/BuildItUp',
    blocStyle: { zIndex: '4', top: '0px', left: '00px', },
  },
  {
    name: 'rememberwhere',
    textMain: 'REMEMBER WHERE?',
    textSub1: 'Vocabulary games',
    textSub2: 'Remembering elements from a picture',
    img: require('static/illustrations/6_remember_where_TC.png'),
    link: '/QuickGames/RememberWhere',
    blocStyle: { zIndex: '4', top: '0px', left: '00px',},
  },
  {
    name: 'haveaguess',
    textMain: 'HAVE A GUESS...',
    textSub1: 'Speaking games',
    textSub2: 'Asking and answering questions',
    img: require('static/illustrations/7_have_a_guess_TC.png'),
    link: '/QuickGames/HaveAGuess',
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
  },
//  { className: "wave_gap", },
]}
;


const wave_boardgames = {
  name: 'BOARDGAMES', link: '/BoardGames',
  header: {
    main: 'BOARD GAMES',
    text: 'Games to be played in groups with more complex rules',
  },
  wave_container:{
    style: {
      backgroundImage: 'url('+require('static/vagues/vague_vert_clair.png')+')',
//      border: '1px solid rgb(110, 50, 120)',
//      backgroundColor: '#e9ea41',
    }
  },
  wave_content:{
    //style: { backgroundColor: '#e9ea62', }
    style: { backgroundColor: '#e9ea41', }
  },
  footer:{
    //style: { backgroundColor: '#e9ea62', }
  },
  waves:
  [
  {
    name: 'carzyanimalsgames',
    textMain: 'CRAZY ANIMALS',
    textSub1: 'Card games',
    textSub2: 'Describing animals',
    img: require('static/illustrations/4_dragon_TC.png'),
    link: '/BoardGames/CrazyAnimalsGame',
    blocStyle: { zIndex: '4', top: '0px', left: '00px',},
  },
  {
    name: 'iamanaliengames',
    textMain: 'I\'M AN ALIEN',
    textSub1: 'Board games',
    textSub2: 'Making physical descriptions',
    img: require('static/illustrations/5_alien_couleur_TC.png'),
    link: '/BoardGames/IMAnAlienGame',
    blocStyle: { zIndex: '4', top: '0px', left: '00px',},
  },
  {
    name: 'absurdinventionsgames',
    textMain: 'ABSURD INVENTIONS',
    textSub1: 'Card games',
    textSub2: 'Describing everyday objects',
    img: require('static/illustrations/3_chat_TC.png'),
    link: '/BoardGames/AbsurdInventionsGame',
    blocStyle: { zIndex: '4', top: '0px', left: '00px',},
  },
  {
    name: 'secretpizzagames',
    textMain: 'SECRET PIZZA',
    textSub1: 'Board game & card game',
    textSub2: 'Speaking about food',
    img: require('static/illustrations/5_pizzaiolo_TC.png'),
    link: '/BoardGames/SecretPizzaGame',
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
  },
  {
    name: 'treasureisland',
    textMain: 'TREASURE ISLAND',
    textSub1: 'Board games',
    textSub2: 'Following instructions & making decisions',
    img: require('static/illustrations/3_treasure_island_TC.png'),
    link: '/BoardGames/TreasureIslandGame',
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
  },
//  { className: "wave_gap", },
  {
    name: 'almostchristmas',
    textMain: 'ALMOST CHRISTMAS!',
    textSub1: 'Advent calendar games',
    textSub2: 'Describing everyday objects',
    img: require('static/illustrations/4_deer_TC.png'),
    link: '/BoardGames/AlmostChristmasGame',
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
  },
  {
    name: 'somuchstuffgames',
    textMain: 'SO MUCH STUFF!',
    textSub1: 'Board game & card game',
    textSub2: 'Speaking about house objects',
    img: require('static/illustrations/8_maison_exterieur_TC.png'),
    link: '/BoardGames/SoMuchStuffGame',
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
  },
  {
    name: 'whokillerthecatgames',
    textMain: 'WHO KILLED THE CAT?',
    textSub1: 'Board game',
    textSub2: 'Investigating past events',
    img: require('static/illustrations/7_dead_cat_photo_avec_ombre_TC.png'),
    link: '/BoardGames/WhoKilledTheCatGame',
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
  },
]}
;


const wave_module = {
  name: 'Module', link: '/Modules',
  header: {
    main: 'MODULES',
    text: 'Step by step sheets including various skills and games',
  },
  wave_container:{
    style: {
      backgroundImage: 'url('+require('static/vagues/vague_vert_fonce.png')+')',
    }
  },
  wave_content:{
    //style: { backgroundColor: '#bac056', }
    style: { backgroundColor: '#b8c040', }
  },
  footer:{
    //style: { backgroundColor: 'rgb(199, 204, 24)', height: 'calc(100vh - (900px + 170px))', }
  },
  waves:
  [
  {
    name: 'crazyanimals',
    textMain: 'CRAZY ANIMALS',
    textSub1: 'Learning about animals',
    textSub2: 'through various skills and games',
    img: require('static/illustrations/4_dragon_TC.png'),
    link: '/Modules/CrazyAnimals',
    blocStyle: { zIndex: '4', top: '0px', left: '00px',},
  },
  {
    name: 'hurryup',
    textMain: 'HURRY UP, DRESS ME UP!',
    textSub1: 'Learning about clothes',
    textSub2: 'through various skills',
    link: '/Modules/DressMeUp',
    img: require('static/illustrations/3_Robert_TC.png'),
    blocStyle: { zIndex: '4', top: '0px', left: '00px',},
  },
  {
    name: 'absurdinventions',
    textMain: 'ABSURD INVENTIONS',
    textSub1: 'Learning about everyday objects',
    textSub2: 'through various skills and games',
    img: require('static/illustrations/3_chat_TC.png'),
    link: '/Modules/AbsurdInventions',
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
  },
  {
    name: 'somuchstuff',
    textMain: 'SO MUCH STUFF!',
    textSub1: 'Learning about house objects',
    textSub2: 'through various skills and games',
    img: require('static/illustrations/8_maison_exterieur_TC.png'),
    link: '/Modules/SoMuchStuff',
    blocStyle: { zIndex: '4', top: '0px', left: '0px',},
  },
//  { className: "wave_gap", },
//  { className: "wave_gap", },
  ]
}
;


export { wave_picture, wave_quick_games, wave_boardgames, wave_module };
export default wave_home;
