import React, {  } from "react";
import { Outlet } from 'react-router-dom';

import "@auth0/auth0-spa-js";

import CookieConsent from "react-cookie-consent";
import Container from "react-bootstrap/Container";

import { useApp } from "./AppContext";

import wave_home from './const-wave.js';
import Wave from './pages/Wave';
import AppHeader from "./comp/AppHeader";
import SearchContext from './comp/SearchContext';

import { cookie, DPS, debug } from './const.js';

const Layout = ({home=false}) => {
    const { 
      searchVisible,
      searchActivated, setSearchActivated,
  
      cart, searchFacets,
      searchFacetFilterContext,
  
      toggleSearch, hideSearch,
    } = useApp();
  
    //console.log(`home : ${home}`)
  
    return (
      <div className={debug ? "AppDebug" : "App"}>
        <Container fluid={true}>
  
          <AppHeader toggleSearch={toggleSearch} cart={cart}>
            {searchVisible && (
                <React.Fragment>
                  <SearchContext
                    searchFacets={searchFacets}
                    searchActivated={searchActivated}
                    setSearchActivated={setSearchActivated}
                    searchFacetFilterContext={searchFacetFilterContext}
                    />
                </React.Fragment>
              )
            }
          </AppHeader>
  
          <Outlet />
  
          {home && (
              <Wave footer_support config={wave_home} hideSearch={hideSearch} home={home}/>
              )
          }
  
        </Container>
  
        <CookieConsent
          location="bottom"
          buttonText={cookie.buttonText}
          cookieName={cookie.cookieName}
          style={{ background: "#2B373B" }}
          buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
          expires={150}
        >{cookie.text} Click <DPS text="here"/> for more information.
        </CookieConsent>
      </div>
    );
}

export default Layout;