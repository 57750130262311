// -*- coding: utf-8 -*-

import React, {useEffect} from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import { waveDownload, getDocCard } from '../lib-util.js';
import ScrollToTop from '../comp/ScrollToTop'

const SearchResult = ({waves, documentMetadata, photos_map, searchFacetFilterContext, setSearchVisible, searchActivated, children}) => {

  useEffect(() => {
    setSearchVisible(true);
  }, [setSearchVisible]);

  const { searchFacetFilter } = searchFacetFilterContext;

  if (typeof searchFacetFilterContext === 'undefined' ||
  typeof searchFacetFilter === 'undefined' ||
  typeof searchFacetFilter.activities === 'undefined' ||
  typeof documentMetadata.filter === 'undefined'){
    //console.log("SearchResult searchFacetFilterContext undefined??")
    return 'SearchResult Loading...';
  }

  //search mode by filtering. From all to less
  const data = documentMetadata.filter(res =>
    (res.activities.some(r=> searchFacetFilter.activities.includes(r))) &&
    (res.vocabulary.some(r=> searchFacetFilter.vocabulary.includes(r))) &&
    (res.grammar.some(r=> searchFacetFilter.grammar.includes(r))) &&
    (res.skills.some(r=> searchFacetFilter.skills.includes(r))) &&
    (res.time.some(r=> searchFacetFilter.time.includes(r))) &&
    (res.languageLevel.some(r=> searchFacetFilter.languageLevel.includes(r))) &&
    //(res.taskDifficulty.some(r=> searchFacetFilter.taskDifficulty.includes(r))) &&
    true
    );

  const nb_img_per_line = 4;//(window.screen.width >= 1300) ? 4 : ( (window.screen.width >= 900) ? 2 : 1);
  const min_nb_wave_on_page = 3;
  const nb_wave_to_display = Math.max(Math.ceil(data.length/nb_img_per_line)*3, min_nb_wave_on_page)

  //console.log("nb_result = "+data.length+", nb_wave_to_display="+nb_wave_to_display)

  const obj = getDocCard(nb_img_per_line, data, photos_map, 1);

  function get_obj(i){
    const idx = i / 3

    return obj[idx]
  }

  return (
    <React.Fragment>
      <ScrollToTop/>
      <Row className="wave_header">
        <Col xs={4} sm={1}/>
        <Col xs={8} sm={11} className="header">
        {searchActivated && (
          <>
            <div className="header_main">{data.length} SEARCH RESULT{data.length>1 ? 'S':''}...</div>
            {data.length>0 && (
              <div className="header_text">Pick one and have a closer look.</div>
            )}
          </>
        )}
        {!searchActivated && (
          <>
            <div className="header_main">SEARCH</div>
            <div className="header_text">What would you like?</div>
          </>
        )}
        </Col>
      </Row>
      {searchActivated && (
      <React.Fragment>
          {waveDownload(3, nb_wave_to_display, waves, get_obj, 30)}
      </React.Fragment>
      )}
    </React.Fragment>
  );
}


export default SearchResult;
