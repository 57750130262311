// -*- coding: utf-8 -*-

import React, { useState } from 'react';
import { Link } from 'react-router-dom'

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Reveal from 'react-reveal/Reveal';

import { Header } from '../comp/AppHeader';
import Button from 'react-bootstrap/Button';
import FooterSupport from '../comp/FooterSupport';
import { subjectColumns, updateUserShowExamples } from '../lib-util.js';

import { useAuth0 } from "../auth/react-auth0-wrapper";

import examples from '../const-examples.js';
/*
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import Popover from 'react-bootstrap/Popover'

const enable_presentation_examples = true;
//const pub1 = require('static/photo_secret_pizza_300dpi.png');
//const pub1 = "https://gameleon.ch/GAMELEON_titre.png";
*/

function Wave({config, waves, footer_support, hideSearch, home}) {

  const { isAuthenticated, userProfile, user, getTokenSilently, setReloadUserProfile } = useAuth0();
  const userInitOk = typeof isAuthenticated !== 'undefined' && typeof userProfile !== 'undefined';
  const [displayExamples, setDisplayExamples] = useState(true); //used only if the user is not logged
  const displayExamplesGlobal = home && (userInitOk ? userProfile.showExamples : displayExamples);

  /* console.log("isAuthenticated = " + isAuthenticated + ", userProfile = " + userProfile + ", userInitOk = " + userInitOk +
    ", userProfile.showExamples = "+ userProfile?.showExamples + ", displayExamples = " + displayExamples + ", displayExamplesGlobal = " + displayExamplesGlobal);
  */

  /* onClick={(x)=>hideSearch2("textMain")}
  const hideSearch2 = (t) => {
    //console.log(`hideSearch2 ${t} ${hideSearch}`);
    if (hideSearch) hideSearch();
  }
  */
/*
  function toolTip(wave_conf, key, children){
    return (
      <OverlayTrigger key={key+"_ot"} className="tooltip" placement="bottom" overlay={
        <Tooltip id={key+"_tt"}>
          {wave_conf['textSub1'] && (
            <div alt={key}>{wave_conf.textSub1}</div>)}
          {wave_conf['textSub2'] && (
            <div alt={key}>{wave_conf.textSub2}</div>)}
        </Tooltip>
      }>
        {children}
      </OverlayTrigger>
    )
  }
*/

//d-none d-lg-inline-block
  function get_obj(i, wave_conf) {
    const key = wave_conf.name
    const wave_text_main = <div className={key+" wave_text_main"} style={wave_conf.textStyle} alt={key}>{wave_conf.textMain}</div>
    const wave_img = <img className={key+" wave_img"} style={wave_conf.imgStyle} alt={key} src={wave_conf.img}/>

    //console.log(wave_conf)

    return (wave_conf['textMain']) ? (
        <React.Fragment>
          <Link to={wave_conf.link} className="wave_link">
            <div className="wave_block" onClick={hideSearch}>
              {wave_conf['img'] && wave_img /*toolTip(wave_conf, key+"1", wave_img)*/}
              <div className="wave_text_delete">
                {wave_conf['textMain'] && wave_text_main /*toolTip(wave_conf, key+"2", wave_text_main)*/}
                {wave_conf['textSub1'] && (
                  <div className={key+" wave_text_sub1"} style={wave_conf.textStyle} alt={key}>{wave_conf.textSub1}</div>)}
                {wave_conf['textSub2'] && (
                  <div className={key+" wave_text_sub2"} style={wave_conf.textStyle} alt={key}>{wave_conf.textSub2}</div>)}
              </div>
            </div>
          </Link>
        </React.Fragment>
    ) : undefined;
  }

  function toggleExample(){
    setDisplayExamples(!displayExamples)

    if (userInitOk){
      updateUserShowExamples(user, getTokenSilently, setReloadUserProfile, displayExamples)
    }
  }

  const wave_home_presentation = JSON.parse(JSON.stringify(config));
  //remove pictures
  //wave_home_presentation.waves = wave_home_presentation.waves.filter(x => x.name != 'activity_pictures');
  //wave_home_presentation.waves = wave_home_presentation.waves.filter(x => x.name !== 'activity_pictures').map( x => ({textMain: x.textMain, link: x.link, name: x.name}) );
  /*
  if (displayExamplesGlobal){
    wave_home_presentation.waves = wave_home_presentation.waves.map( x => ({textMain: x.textMain, link: x.link, name: x.name}) );
  }else{

  }
  */

  //console.log("wave_home");
  //console.log({config});
  //console.log("wave_home_presentation");
  //console.log(wave_home_presentation);

  const header  = config.header;
  const wave_container  = config.wave_container;
  //const wave_content  = config.wave_content;
  const footer  = config.footer;
  const waves_config = home ? wave_home_presentation.waves : config.waves;
  const wave_footer = waves ? waves.footer : undefined;
//  <Row className="wave_container_gap_footer"/>


/*
  const popover = (
    <Popover id="example_popover">
      <Popover.Header className="information_important" as="h3">Product examples</Popover.Header>
      <Popover.Body>
        <div className="example_block">
          <img className="example_img" alt="example_img" src={pub1}/>
          <img className="example_img" alt="example_img" src={pub1}/>
          <img className="example_img" alt="example_img" src={pub1}/>
          <img className="example_img" alt="example_img" src={pub1}/>
        </div>
      </Popover.Body>
    </Popover>
  );

  function Examples({children}){
    if(enable_presentation_examples && home){
      return (
        <OverlayTrigger defaultShow={true} trigger={['hover', 'focus', 'click']} placement="auto" overlay={popover} delay={{ hide: 800 }}>
          {children}
        </OverlayTrigger>
      )
    }else{
      return children
    }
  }

  {/*
  <Row className="wave_header">
    <Col xs={0} sm={0} lg={1} className="phone_hide"/>
    <Col xs={12} sm={12} lg={10} className="header">
        {/
        <Examples>
        /}
          <div className="exemplePopover">
            {header.img && (<img className="header_img" alt="header" src={header.img}/>)}
            {header.main && (<div className="header_main">{header.main}</div>)}
            {header.text && (<div className="header_text">{header.text}</div>)}
            {header.text2 && (<div className="header_text2">{header.text2}</div>)}
          </div>
        {/
        </Examples>
        /}
    </Col>
    <Col xs={0} sm={0} lg={1} className="phone_hide"/>
  </Row>
  /}
  <React.Fragment className="">
  </React.Fragment>

  {examples.map(layer => (
    <Bounce right key={layer.name+"_b"}>
      <PresentationImage key={layer.name+"_pi"} img_list={layer.img}/>
    </Bounce>
  ) )}

*/
  const PresentationExamples = ({examples}) => {
    return (
      <Row id="PresentationExamples" className="examples_presentation">
            {examples.map(layer => (
                <PresentationImage key={layer.name+"_pi"} img_list={layer.img}/>
            ) )}
      </Row>
    )
  }

  const ButtonDisplayExamples = () => {
    const doNotDisplayButton = !home;
    // console.log("doNotDisplayButton = " + doNotDisplayButton);

    if ( doNotDisplayButton ) return null;
    return (
      <Row className="examples_header">
        <Col xs={3} sm={9} lg={10}/>
        <Col xs={6} sm={3} lg={2}>
          <Button className="login_LinkContainer header_button" variant="outline-dark" onClick={() => toggleExample()}>{displayExamplesGlobal ? "Hide examples": "Show examples"}</Button>
        </Col>
      </Row>
    )
  }

  const PresentationImage = ({img_list}) => {
    return ( img_list.map(img => (
      <Col key={"PresentationImage_cnt_"+img[0]} className="exemple_col">
        <Reveal effect="fadeInUp" key={img[0]+"_b"}>
          <Link to={img[2]} className="wave_link">
            <img id={"PresentationImage_"+img[0]} className="img-fluid example_img" alt="example_img" src={img[1]}/>
          </Link>
        </Reveal>
      </Col>
    ) ) );
  }

  return (
    <React.Fragment>
      <div className="screen_background" style={wave_footer ? wave_footer.style : wave_footer}/>
      <div className="screen_background_white"/>

      <Header header={header} type_wave />

      <Row className="wave_background">
        <Row className="wave_container" style={wave_container.style}/>
        <Row className="wave_container_gap"/>
        <Row className="wave_content" style={wave_footer ? wave_footer.style : wave_footer}>
          { displayExamplesGlobal ?
            ( <PresentationExamples examples={examples}/> )
            : subjectColumns(waves_config, get_obj)
          }
          <ButtonDisplayExamples />
        </Row>
        {footer_support ?
          (<FooterSupport footer_style={footer.style}/>):null
        }
      </Row>
    </React.Fragment>
  );
}

export default Wave;
