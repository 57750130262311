// -*- coding: utf-8 -*-
import {
  warningCardTextStyle, warningCardStyle, warningStep1,
  LicenceNoAdapt,
  //LicenceForAdapt,
  } from './const.js';

const moduleAndBoardGame = 'This game is presented in the BOARD GAMES and the MODULES sections.';

/************

Modules

*/

const download_absurd_inventions = {
  name: 'download_absurd_inventions',
  link: 'AbsurdInventions',
  header: {
    activity: 'Modules',
    main: 'Absurd inventions',
    text: 'All steps here. Pick one or many.',
  },
  wave_container:{
      style: { height: '700px' }
  },
/*
  footer: {
    text: 'Both games are also presented in the BOARD GAMES section.',
    blocStyle: { position: "relative", top: "0px", left: "0px",},
    index: 7,
  },
*/
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro Absurd inventions',
      notes: '/teachers_notes/absurd_inventions_teachers_notes.pdf',
      img: require('static/illustrations/3_chat_TC.png'),
      title: 'OVERVIEW',
      textf: [
        ['This module is about identifying inventions made with everyday objects. '+
        'The steps follow a logical progression, from a global to a detailed approach. '+
        'Most of them can also be used individually. See the warnings for exceptions. '+
        'Please note that steps 3 and 4 are alternatives for the same reading activity with more or less difficult tasks.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Free teacher\'s notes are included in each pack.', 'style_text_bigger']],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's1',
      samples3: ['3_s1_1', require('static/swipe_PDFs/3_modules_absurd_inventions/Step_1_PDF.png')],
      samples4: [
        ['swipe_s1_2', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_1_PDF_2.jpg')],
        ['swipe_s1_1', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_1_PDF_1.jpg')],
        ['swipe_s1_3', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_1_PDF_3.jpg')],
        ['swipe_s1_4', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_1_PDF_4.jpg')],
      ],
      title: 'Step 1 - SPEAKING',
      textf: [
        ['14 PDF pack', 'style_red_bold'],
        ['\u2022 13 sheets (in A4 size)', 'style_red'],
        ['\u2022 1 key (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Describing pictures of inventions to find their names', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'absurd_inventions_1_speaking',
    },
    {
      name: 's2',
      samples3: ['3_s2_1', require('static/swipe_PDFs/3_modules_absurd_inventions/Step_2_PDF.png')],
      samples4: [
        ['swipe_s2_1', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_2_PDF_1.jpg')],
        ['swipe_s2_2', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_2_PDF_2.jpg')],
      ],
      title: 'Step 2 - READING',
      textf: [
        ['7 PDF pack', 'style_red_bold'],
        ['\u2022 5 sheets (in A4 size)', 'style_red'],
        ['\u2022 2 keys (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Global comprehension', 'style_text_bigger_bold'],
        ['Matching pictures with descriptions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'absurd_inventions_2_reading',
    },
    {
      name: 's3',
      samples3: ['3_s3_1', require('static/swipe_PDFs/3_modules_absurd_inventions/Step_3_PDF.png')],
      samples4: [
        ['swipe_s3_1', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_3_PDF_1.jpg')],
        ['swipe_s3_2', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_3_PDF_2.jpg')],
        ['swipe_s3_3', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_3_PDF_3.jpg')],
        ['swipe_s3_4', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_3_PDF_4.jpg')],
      ],
      title: 'Step 3 - READING',
      textf: [
        ['24 PDF pack', 'style_red_bold'],
        ['\u2022 12 sheets (in A4 size)', 'style_red'],
        ['\u2022 12 keys (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Detailed comprehension', 'style_text_bigger_bold'],
        ['Finding captions of pictures', 'style_text_bigger_thin'],
        ['', 'style_space'],
        ['Step 3 is an alternative to step 4. It is also a reading activity, with a less difficult task.', 'style_red'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'absurd_inventions_3_reading',
    },
    { name: 'blk1',},
    {
      name: 's4',
      samples3: ['3_s4_1', require('static/swipe_PDFs/3_modules_absurd_inventions/Step_4_PDF.png')],
      samples4: [
        ['swipe_s4_1', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_4_PDF_1.jpg')],
        ['swipe_s4_2', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_4_PDF_2.jpg')],
        ['swipe_s4_3', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_4_PDF_3.jpg')],
        ['swipe_s4_4', require('static/swipe_PDFs/4_modules_absurd_inventions/Step_4_PDF_4.jpg')],
      ],
      title: 'Step 4 - READING',
      textf: [
        ['24 PDF pack', 'style_red_bold'],
        ['\u2022 12 sheets (in A4 size)', 'style_red'],
        ['\u2022 12 keys (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Detailed comprehension', 'style_text_bigger_bold'],
        ['Finding captions of pictures', 'style_text_bigger_thin'],
        ['', 'style_space'],
        ['Step 4 is an alternative to step 3. It is also a reading activity, with a more difficult task.', 'style_red'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'absurd_inventions_4_reading',
    },
    {
      name: 'game',
      samples3: ['3_g1_1', require('static/swipe_PDFs/3_board_games_absurd_inventions/Game_1_PDF.png')],
      samples4: [
        ['swipe_g1_2', require('static/swipe_PDFs/4_modules_absurd_inventions/GAME_1_2.jpg')],
        ['swipe_g1_1', require('static/swipe_PDFs/4_modules_absurd_inventions/GAME_1_1.jpg')],
        ['swipe_g1_3', require('static/swipe_PDFs/4_modules_absurd_inventions/GAME_1_3.jpg')],
        ['swipe_g1_4', require('static/swipe_PDFs/4_modules_absurd_inventions/GAME_2.jpg')],
      ],
      title: 'GAME',
      textf: [
        ['9 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game key (1 sheet in A4 size)', 'style_red'],
        ['\u2022 picture cards (3 sheets in A4 size)', 'style_red'],
        ['\u2022 word cards (3 sheets in A4 size)', 'style_red'],
        ['\u2022 game template (1 sheet in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Card game', 'style_text_bigger_bold'],
        ['Collecting pairs of cards', 'style_text_bigger_thin'],
        ['(pictures and descriptions)', 'style_text_bigger_thin'],
        ['', 'style_space'],
        [moduleAndBoardGame, 'style_text_bigger_bold_warning'],
        ['', 'style_space'],
        warningCardStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'absurd_inventions_game',
    },
  ],
}


const download_crazy_animals = {
  name: 'download_crazy_animals',
  link: 'CrazyAnimals',
  header: {
    activity: 'Modules',
    main: 'Crazy animals',
    text: 'All steps here. Pick one or many.',
  },
  wave_container:{
      style: { height: '700px' }
  },
  /*
  footer: {
    text: 'Both games are also presented in the BOARD GAMES section.',
    blocStyle: { position: "relative", top: "0px", left: "0px",},
    index: 7,
  },
  */
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro Crazy animals',
      notes: '/teachers_notes/crazy_animals_teachers_notes.pdf',
      img: require('static/illustrations/4_dragon_TC.png'),
      title: 'OVERVIEW...',
      textf: [
        ['This module is about describing animals. It includes various steps following a logical progression. '+
        'In step 1, students learn or revise the vocabulary that will be necessary to speak or write about animals in the next steps. '+
        'Most steps can also be used individually.', 'style_text_bigger'],
        ['', 'style_space'],
        ['Free teacher\'s notes are included in each pack.', 'style_text_bigger']
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's1',
      samples3: ['3_s1_1', require('static/swipe_PDFs/3_modules_crazy_animals/Step_1_PDF.png')],
      samples4: [
        ['swipe_s1_1', require('static/swipe_PDFs/4_modules_crazy_animals/Step_1_PDF_1.jpg')],
        ['swipe_s1_2', require('static/swipe_PDFs/4_modules_crazy_animals/Step_1_PDF_2.jpg')],
        ['swipe_s1_3', require('static/swipe_PDFs/4_modules_crazy_animals/Step_1_PDF_3.jpg')],
        ['swipe_s1_4', require('static/swipe_PDFs/4_modules_crazy_animals/Step_1_PDF_4.jpg')],
      ],
      title: 'Step 1 - VOCABULARY',
      textf: [
        ['9 PDF pack', 'style_red_bold'],
        ['\u2022 6 sheets (in A4 size)', 'style_red'],
        ['\u2022 3 keys (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Learning or revising vocabulary about animals and animal body parts', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'crazy_animals_1_vocabulary',
    },
    {
      name: 's2',
      samples3: ['3_s2_1', require('static/swipe_PDFs/3_modules_crazy_animals/Step_2_PDF.png')],
      samples4: [
        ['swipe_s2_1', require('static/swipe_PDFs/4_modules_crazy_animals/Step_2_PDF_1.jpg')],
        ['swipe_s2_2', require('static/swipe_PDFs/4_modules_crazy_animals/Step_2_PDF_2.jpg')],
        ['swipe_s2_3', require('static/swipe_PDFs/4_modules_crazy_animals/Step_2_PDF_3.jpg')],
      ],
      title: 'Step 2 - READING',
      textf: [
        ['5 PDF pack', 'style_red_bold'],
        ['\u2022 3 sheets (in A4 size)', 'style_red'],
        ['\u2022 2 keys (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Detailed comprehension', 'style_text_bigger_bold'],
        ['Selecting the correct answer', 'style_text_bigger_thin'],
        ['Matching pictures with descriptions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'crazy_animals_2_reading',
    },
    {
      name: 's3',
      samples3: ['swipe_s3_1', require('static/swipe_PDFs/3_modules_crazy_animals/Step_3_PDF.png')],
      samples4: [
        ['swipe_s3_1', require('static/swipe_PDFs/4_modules_crazy_animals/Step_3_PDF_1.jpg')],
        ['swipe_s3_2', require('static/swipe_PDFs/4_modules_crazy_animals/Step_3_PDF_2.jpg')],
      ],
      title: 'Step 3 - SPEAKING',
      textf: [
        ['4 PDF pack', 'style_red_bold'],
        ['\u2022 4 self correcting sheets (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Asking and answering about animals to find their names', 'style_text_bigger'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'crazy_animals_3_speaking',
    },
    {
      name: 's4',
      samples3: ['swipe_s4_1', require('static/swipe_PDFs/3_modules_crazy_animals/Step_4_PDF.png')],
      samples4: [
        ['swipe_s4_1', require('static/swipe_PDFs/4_modules_crazy_animals/Step_4_PDF_1.jpg')],
      ],
      title: 'Step 4 - WRITING',
      textf: [
        ['2 PDF pack', 'style_red_bold'],
        ['\u2022 1 sheet (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Describing an animal', 'style_text_bigger_bold'],
        ['(with vocabulary help or not)', 'style_text_bigger_bold'],
        ['', 'style_space'],
        ['Step 4 is an alternative to step 5. It is also a writing activity, with a less difficult task.', 'style_red'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'crazy_animals_4_writing',
    },
    {
      name: 'game1',
      samples3: ['swipe_g1_1', require('static/swipe_PDFs/3_board_games_crazy_animals/Game_1_PDF.png')],
      samples4: [
        ['swipe_g1_2', require('static/swipe_PDFs/4_modules_crazy_animals/GAME_1_2.jpg')],
        ['swipe_g1_1', require('static/swipe_PDFs/4_modules_crazy_animals/GAME_1_1.jpg')],
      ],
      title: 'GAME 1',
      textf: [
        ['8 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 game key (1 sheet in A4 size)', 'style_red'],
        ['\u2022 word cards (3 sheets in A4 size)', 'style_red'],
        ['\u2022 picture cards (3 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Card game', 'style_text_bigger_bold'],
        ['Collecting pairs of cards', 'style_text_bigger'],
        ['(descriptions and animals)', 'style_text_bigger'],
        ['', 'style_space'],
        [moduleAndBoardGame, 'style_text_bigger_bold_warning'],
        ['', 'style_space'],
        warningCardTextStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'crazy_animals_game_1',
    },
    {
      name: 'game2',
      samples3: ['swipe_g2_1', require('static/swipe_PDFs/3_board_games_crazy_animals/Game_2_PDF.png')],
      samples4: [
        ['swipe_g2_3', require('static/swipe_PDFs/4_modules_crazy_animals/GAME_2_3.jpg')],
        ['swipe_g2_1', require('static/swipe_PDFs/4_modules_crazy_animals/GAME_2_1.jpg')],
        ['swipe_g2_2', require('static/swipe_PDFs/4_modules_crazy_animals/GAME_2_2.jpg')],
      ],
      title: 'GAME 2',
      textf: [
        ['7 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (1 sheet in A4 size)', 'style_red'],
        ['\u2022 word cards (4 sheets in A4 size)', 'style_red'],
        ['\u2022 picture cards (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Card game', 'style_text_bigger_bold'],
        ['Collecting groups of 3 cards', 'style_text_bigger'],
        ['(adjectives, body parts, animals)', 'style_text_bigger'],
        ['', 'style_space'],
        [moduleAndBoardGame, 'style_text_bigger_bold_warning'],
        ['', 'style_space'],
        warningCardTextStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'crazy_animals_game_2',
    },
  ],
}



const download_dressmeup = {
  name: 'download_dressmeup',
  link: 'DressMeUp',
  header: {
    activity: 'Modules',
    main: 'Hurry up, dress me up!',
    text: 'All steps here. Pick one or many.',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 6,
  photos_set: [
    {
      name: 'intro Hurry up, dress me up!',
      notes: '/teachers_notes/dress_me_up_teachers_notes.pdf',
      img: require('static/illustrations/3_Robert_TC.png'),
      title: 'OVERVIEW...',
      textf: [
        ['This module is about describing clothes. It includes various steps following a logical progression. '+
        'In step 1, students learn about the identity of various characters. '+
        'In the next steps, they find out which clothes belong to whom. '+
        'In step 5, they dress up the characters and describe them. Most steps can also be used individually. See the warnings for exceptions.',
        'style_text_bigger'],
        ['', 'style_space'],
        ['Free teacher\'s notes are included in each pack.', 'style_text_bigger']
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '00px',},
    },
    {
      name: 's1',
      samples3: ['3_s1_1', require('static/swipe_PDFs/3_modules_dress_me_up/Step_1_PDF.png')],
      samples4: [
        ['swipe_s1_1', require('static/swipe_PDFs/4_modules_dress_me_up/Step_1_PDF_1.jpg')],
        ['swipe_s1_2', require('static/swipe_PDFs/4_modules_dress_me_up/Step_1_PDF_2.jpg')],
        ['swipe_s1_3', require('static/swipe_PDFs/4_modules_dress_me_up/Step_1_PDF_3.jpg')],
        ['swipe_s1_4', require('static/swipe_PDFs/4_modules_dress_me_up/Step_1_PDF_4.jpg')],
      ],
      title: 'Step 1 - SPEAKING',
      textf: [
        ['8 PDF pack', 'style_red_bold'],
        ['\u2022 4 sheets (in A4 size)', 'style_red'],
        ['\u2022 4 keys (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Asking and answering', 'style_text_bigger_thin'],
        ['about the identity of various characters', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'dress_me_up_1_speaking',
    },
    {
      name: 's2',
      samples3: ['3_s2_1', require('static/swipe_PDFs/3_modules_dress_me_up/Step_2_PDF.png')],
      samples4: [
        ['swipe_s2_1', require('static/swipe_PDFs/4_modules_dress_me_up/Step_2_PDF_1.jpg')],
        ['swipe_s2_2', require('static/swipe_PDFs/4_modules_dress_me_up/Step_2_PDF_2.jpg')],
      ],
      title: 'Step 2 - VOCABULARY',
      textf: [
        ['2 PDF pack', 'style_red_bold'],
        ['\u2022 1 sheet (in A4 size)', 'style_red'],
        ['\u2022 1 key (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Learning or revising vocabulary', 'style_text_bigger_thin'],
        ['about clothes', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'dress_me_up_2_vocabulary',
    },
    {
      name: 's3',
      samples3: ['3_s3_1', require('static/swipe_PDFs/3_modules_dress_me_up/Step_3_PDF.png')],
      samples4: [
        ['swipe_s3_1', require('static/swipe_PDFs/4_modules_dress_me_up/Step_3_PDF_1.jpg')],
        ['swipe_s3_2', require('static/swipe_PDFs/4_modules_dress_me_up/Step_3_PDF_2.jpg')],
      ],
      title: 'Step 3 - READING',
      textf: [
        ['2 PDF pack', 'style_red_bold'],
        ['\u2022 1 sheet (in A4 size)', 'style_red'],
        ['\u2022 1 key (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Detailed comprehension', 'style_text_bigger_bold'],
        ['Matching clothes with characters', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningStep1,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'dress_me_up_3_reading',
    },
    { name: 'blk1',},
    {
      name: 's4',
      samples3: ['3_s4_1', require('static/swipe_PDFs/3_modules_dress_me_up/Step_4_PDF.png')],
      samples4: [
        ['swipe_s4_1', require('static/swipe_PDFs/4_modules_dress_me_up/Step_4_PDF_1.jpg')],
        ['swipe_s4_2', require('static/swipe_PDFs/4_modules_dress_me_up/Step_4_PDF_2.jpg')],
      ],
      title: 'Step 4 - SPEAKING',
      textf: [
        ['2 PDF pack', 'style_red_bold'],
        ['\u2022 1 sheet (in A4 size)', 'style_red'],
        ['\u2022 1 key (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Speaking about likes and clothes', 'style_text_bigger_thin'],
        ['', 'style_space'],
        warningStep1,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'dress_me_up_4_speaking',
    },
    {
      name: 's5',
      samples3: ['3_s5_1', require('static/swipe_PDFs/3_modules_dress_me_up/Step_5_PDF.png')],
      samples4: [
        ['swipe_s5_1', require('static/swipe_PDFs/4_modules_dress_me_up/Step_5_PDF_1.jpg')],
        ['swipe_s5_2', require('static/swipe_PDFs/4_modules_dress_me_up/Step_5_PDF_2.jpg')],
      ],
      title: 'Step 5 - WRITING & LISTENING',
      textf: [
        ['8 PDF pack', 'style_red_bold'],
        ['\u2022 8 sheets (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Describing a character (body and clothes)', 'style_text_bigger_thin'],
        ['Matching pictures with descriptions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'dress_me_up_5_writing',
    },
    { name: 'blk2',},
  ],
}



const download_so_much_stuff = {
  name: 'download_so_much_stuff',
  link: 'SoMuchStuff',
  header: {
    activity: 'Modules',
    main: 'So Much Stuff!',
    text: 'All steps here. Pick one or many.',
  },
  wave_container:{
      style: { height: '700px' }
  },
  nb_waves : 6,
  photos_set: [
    {
      name: 'SO MUCH STUFF!',
      notes: '/teachers_notes/so_much_stuff_teachers_notes.pdf',
      img: require('static/illustrations/8_maison_exterieur_TC.png'),
      title: 'OVERVIEW...',
      textf: [
        ['This module is about describing the inside of a house. It includes various progressive steps which are a preparation for the final games. '+
        'In the first steps, students learn or revise the vocabulary that will be necessary to describe the rooms of a house and its furniture or objects. '+
        'Then students practise localising and describing objects. '+
        'All steps can be used individually, depending on the language level of the students. '+
        'Please note that step 4 includes two options for the same reading activity with more or less difficult tasks.',
        'style_text_bigger'],
        ['', 'style_space'],
        ['Free teacher\'s notes are included in each pack.', 'style_text_bigger']
      ],
      blocStyle: { zIndex: '4', top: '0px', left: '0px',},
    },
    {
      name: 's1',
      samples3: ['3_s1_1', require('static/swipe_PDFs/3_modules_so_much_stuff/Step_1_PDF.png')],
      samples4: [
        ['swipe_s1_1', require('static/swipe_PDFs/4_modules_so_much_stuff/Step_1_PDF_1.jpg')],
        ['swipe_s1_2', require('static/swipe_PDFs/4_modules_so_much_stuff/Step_1_PDF_2.jpg')],
      ],
      title: 'Step 1 - VOCABULARY',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 3 sheets (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Learning or revising vocabulary', 'style_text_bigger_thin'],
        ['about house furniture', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'so_much_stuff_1_vocabulary',
    },
    {
      name: 's2',
      samples3: ['3_s2_1', require('static/swipe_PDFs/3_modules_so_much_stuff/Step_2_PDF.png')],
      samples4: [
        ['swipe_s2_1', require('static/swipe_PDFs/4_modules_so_much_stuff/Step_2_PDF_1.jpg')],
        ['swipe_s2_2', require('static/swipe_PDFs/4_modules_so_much_stuff/Step_2_PDF_2.jpg')],
      ],
      title: 'Step 2 - VOCABULARY',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 2 sheets (in A4 size)', 'style_red'],
        ['\u2022 1 key (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Learning or revising vocabulary', 'style_text_bigger_thin'],
        ['about house objects', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'so_much_stuff_2_vocabulary',
    },
    {
      name: 's3',
      samples3: ['3_s3_1', require('static/swipe_PDFs/3_modules_so_much_stuff/Step_3_PDF.png')],
      samples4: [
        ['swipe_s3_1', require('static/swipe_PDFs/4_modules_so_much_stuff/Step_3_PDF_1.jpg')],
        ['swipe_s3_2', require('static/swipe_PDFs/4_modules_so_much_stuff/Step_3_PDF_2.jpg')],
      ],
      title: 'Step 3 - SPEAKING',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 3 sheets (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Describing the place of objects on a picture', 'style_text_bigger_thin'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'so_much_stuff_3_speaking',
    },
    {
      name: 's4',
      samples3: ['3_s4_1', require('static/swipe_PDFs/3_modules_so_much_stuff/Step_4_PDF.png')],
      samples4: [
        ['swipe_s4_1', require('static/swipe_PDFs/4_modules_so_much_stuff/Step_4_PDF_1.jpg')],
        ['swipe_s4_2', require('static/swipe_PDFs/4_modules_so_much_stuff/Step_4_PDF_2.jpg')],
      ],
      title: 'Step 4 - READING',
      textf: [
        ['3 PDF pack', 'style_red_bold'],
        ['\u2022 3 sheets (in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Finding out objects based on descriptions', 'style_text_bigger_thin'],
        ['', 'style_space'],
        ['Please note this step includes two options for the same reading activity with more or less difficult tasks.', 'style_red'],
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'so_much_stuff_4_reading',
    },
    {
      name: 'game1_a4',
      samples3: ['swipe_g1_a4_1', require('static/swipe_PDFs/3_modules_so_much_stuff/GAME_1_A4_PDF.png')],
      samples4: [
        ['swipe_g1_a4_3', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_1_A4_3.jpg')],
        ['swipe_g1_a4_4', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_1_A4_4.jpg')],
        ['swipe_g1_a4_1', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_1_A4_1.jpg')],
        ['swipe_g1_a4_2', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_1_A4_2.jpg')],
      ],
      title: 'GAME 1 (A4)',
      textf: [
        ['11 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 object cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 lists of questions (4 sheets in A4 size)', 'style_red'],
        ['\u2022 plan of the house in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['\u2022 game board in 2 parts (2 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Answering questions about house objects', 'style_text_bigger'],
        ['', 'style_space'],
        [moduleAndBoardGame, 'style_text_bigger_bold_warning'],
        ['', 'style_space'],
        warningCardTextStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'so_much_stuff_game_1_A4',
    },
    {
      name: 'game1_a3',
      samples3: ['swipe_g1_1', require('static/swipe_PDFs/3_modules_so_much_stuff/GAME_1_A3_PDF.png')],
      samples4: [
        ['swipe_g1_3', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_1_A3_3.jpg')],
        ['swipe_g1_1', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_1_A3_1.jpg')],
        ['swipe_g1_2', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_1_A3_2.jpg')],
      ],
      title: 'GAME 1 (A3)',
      textf: [
        ['9 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 object cards (1 sheet in A4 size)', 'style_red'],
        ['\u2022 lists of questions (4 sheets in A4 size)', 'style_red'],
        ['\u2022 plan of the house (1 sheet in A3 size)', 'style_red'],
        ['\u2022 game board (1 sheet in A3 size)', 'style_red'],
        ['', 'style_space'],
        ['Board game', 'style_text_bigger_bold'],
        ['Answering questions about house objects', 'style_text_bigger'],
        ['', 'style_space'],
        [moduleAndBoardGame, 'style_text_bigger_bold_warning'],
        ['', 'style_space'],
        warningCardTextStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'so_much_stuff_game_1_A3',
    },
    {
      name: 'game2',
      samples3: ['swipe_g2_1', require('static/swipe_PDFs/3_modules_so_much_stuff/GAME_2_PDF.png')],
      samples4: [
        ['swipe_g2_2', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_2_2.jpg')],
        ['swipe_g2_1', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_2_1.jpg')],
        ['swipe_g2_3', require('static/swipe_PDFs/4_modules_so_much_stuff/GAME_2_3.jpg')],
      ],
      title: 'GAME 2',
      textf: [
        ['9 PDF pack', 'style_red_bold'],
        ['\u2022 game rules (2 sheets in A4 size)', 'style_red'],
        ['\u2022 vocabulary sheet (1 sheet in A4 size)', 'style_red'],
        ['\u2022 room cards (3 sheets in A4 size)', 'style_red'],
        ['\u2022 drawing cards (3 sheets in A4 size)', 'style_red'],
        ['', 'style_space'],
        ['Card game', 'style_text_bigger_bold'],
        ['Finding the place of objects on pictures', 'style_text_bigger'],
        ['', 'style_space'],
        [moduleAndBoardGame, 'style_text_bigger_bold_warning'],
        ['', 'style_space'],
        warningCardTextStyle,
        ['', 'style_space'],
        LicenceNoAdapt,
      ],
      documentId: 'so_much_stuff_game_2',
    },
  ],
}

export {
  download_crazy_animals, download_dressmeup, download_absurd_inventions, download_so_much_stuff,
};
