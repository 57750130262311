// -*- coding: utf-8 -*-

import React, { useEffect } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import HeaderUserInfo from '../comp/HeaderUserInfo';
import HeaderSupport from '../comp/HeaderSupport';
import HeaderPolicies from '../comp/HeaderPolicies';

import ExpandableWave from '../comp/ExpandableWave';
import FooterSupport from '../comp/FooterSupport';

const Support = ({waveNoLink, no_extension_color, support, policies, userInfo, purchaseHistory, waves, hideSearch, children, title, footer_support}) => {

  useEffect(() => {
    hideSearch();
  });

  const wave_container = waves.wave_container;
  const wave_footer = waves.footer;

  const WaveNoLink = ({children, title, className}) => {
    return (
      <React.Fragment>
        <div className="screen_background" style={wave_footer && wave_footer.style}/>
        <div className="screen_background_white"/>
        <Row className="wave_background">
          <Row className="wave_container" style={wave_container.style}>
          </Row>
          {title}
          <Row className={className} style={wave_footer && wave_footer.style}>
            {children}
          </Row>
          {footer_support ?
            (<FooterSupport/>):null
          }
        </Row>
      </React.Fragment>
    );
  }

  const WaveAndLinks = ({children, className, gap}) => {
    const footer_style = wave_footer && wave_footer.style;

    const content = (
        <React.Fragment>
          {userInfo ? (<HeaderUserInfo/>) : support ? (<HeaderSupport/>) : (<HeaderPolicies/>) }
          <Row className="wave_container_gap_header"/>
          <Row className={className} style={footer_style}>
            <Col xs={1} lg={2}/>
            <Col xs={10} lg={10}>
              {children}
            </Col>
          </Row>
          {footer_support ?
            (<FooterSupport/>):null
          }
        </React.Fragment>
      );
    return (<ExpandableWave content={content} wave_container_style={wave_container.style} footer_style={footer_style} no_extension_color gap={gap}/>);
  };

  const WaveAndLinksPurchaseHistory = ({children, className}) => {
    return (
      <React.Fragment>
        <div className="screen_background" style={wave_footer && wave_footer.style}/>
        <div className="screen_background_white"/>
        <Row className="wave_background">
          <Row className="wave_container" style={wave_container.style}>
          </Row>
          <HeaderUserInfo/>
          <Row className="wave_container_gap_header"/>
          <Row className={className} style={wave_footer && wave_footer.style}>
            <Col xs={0} lg={2} className="phone_hide"/>
            <Col xs={12} lg={10}>
              {children}
            </Col>
          </Row>
          {footer_support ?
            (<FooterSupport/>):null
          }
        </Row>
      </React.Fragment>
    );
  }

  if (waveNoLink)
    return WaveNoLink({children: children, className: "wave_content", title: title, gap: undefined});

  if (userInfo)
    return WaveAndLinks({children: children, className: "wave_content", gap: undefined});

  if (support || policies)
    return WaveAndLinks({children: children, className: "wave_content"});

  if (purchaseHistory)
    return WaveAndLinksPurchaseHistory({children: children, className: "wave_content"});

  if (no_extension_color)
    return (<ExpandableWave content={children} wave_container_style={wave_container.style} footer_style={wave_footer && wave_footer.style} no_extension_color/>);

  return (<ExpandableWave content={children} wave_container_style={wave_container.style} footer_style={wave_footer && wave_footer.style}/>);
}

export default Support;
