import React, { useState, useContext } from 'react';

import { useServerData } from './comp/useServerData.js';
import { useCart } from './comp/useCart.js';
import { useSearchFacetFilterContext } from './comp/SearchContext';

export const AppContext = React.createContext();
export const useApp = () => useContext(AppContext);

export const AppProvider = ({
  children,
}) => {
  const [searchVisible, setSearchVisible] = useState(false);
  const [searchActivated, setSearchActivated] = useState(false);
  const [checkoutUrl, setCheckoutUrl] = useState(undefined);

  const [cart, addToCart, removeFromCart, removeAllDocFromCart, purgeCart] = useCart(false);
  const [documentMetadata, searchFacets, dataBoughtDoc, setDataBoughtDocReload] = useServerData();
  const searchFacetFilterContext = useSearchFacetFilterContext(setSearchActivated, searchFacets);

  function toggleSearch() {
    setSearchVisible(!searchVisible);
	}

  function hideSearch(){
    setSearchVisible(false);
  }

  return (
    <AppContext.Provider
      value={{
        searchVisible, setSearchVisible,
        searchActivated, setSearchActivated,

        cart, addToCart, removeFromCart, removeAllDocFromCart, purgeCart,
        checkoutUrl, setCheckoutUrl,
        documentMetadata, searchFacets, dataBoughtDoc, setDataBoughtDocReload,
        searchFacetFilterContext,

        toggleSearch, hideSearch,

      }}
    >
      {children}
    </AppContext.Provider>
  );
};
