// -*- coding: utf-8 -*-

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { LinkContainer } from 'react-router-bootstrap';
import Button from 'react-bootstrap/Button';

const HeaderPolicies = ({children}) => {
  return (
    <React.Fragment>
      <Row className="information_header_container">
        <Col xs={2}/>
        <Col xs={10}>
          <LinkContainer className="inf_LinkContainer" to="/Policies/GeneralTermsAndConditions"><Button size="lg" variant="light">General terms and conditions</Button></LinkContainer>
          <LinkContainer className="inf_LinkContainer" to="/Policies/DataProtectionStatement"><Button size="lg" variant="light">Data protection statement</Button></LinkContainer>
          <LinkContainer className="inf_LinkContainer" to="/Policies/Licensing"><Button size="lg" variant="light">Licensing</Button></LinkContainer>
        </Col>
      </Row>
    </React.Fragment>
  );
}
export default HeaderPolicies;
