// -*- coding: utf-8 -*-

import React from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ContactMail } from '../const.js';

import Support from './Support';

const About = ({waves, hideSearch}) => {

  const text = (
    <Row className="information_header_container">
      <Row className="information_text_header">
        <Col xs={2}/>
        <Col xs={10}>
            ABOUT US
        </Col>
      </Row>
      <Row className="wave_container_gap_header"/>
      <Row className="information_text_container_about">
        <Col xs={2}/>
        <Col xs={9} className="information_text_container information_text_scroll">
          <div >
            <div className="information_title">
              The designer
            </div>
            <div className="information_text">
Hi there, I’m Nanda and l love languages! I taught English, Italian and French to kids and teenagers for over 20 years.
I also speak Portuguese with my family.
I’ve lived in various countries: Portugal, Canada, the USA. I currently live in a small village in Switzerland.

I also love anything that has to do with creativity, especially graphic arts.
As a kid, I used to spend my time drawing.
Later as a student, I picked languages instead of art, but I’ve always felt something was missing. Today, I’m glad my love for drawing stuck around!

A few years ago, I took up a graphic designer course that opened up a whole new panel of graphic tools and creative perspectives.
From that point on, the idea of creating something truly mine, from scratch, started growing.

So eventually, Gameleon was a way to bring together my passions for languages and graphic arts.
</div>
<p/>
      <div className="information_text">
Now I’d like to share all of these games and activities with you.
I hope you enjoy them as much as I did while creating them!
I’d be happy to get any feedback and new ideas.
Please feel free to drop a line at: <ContactMail/>
</div>

<div className="information_title">
The IT engineer
</div>
<div className="information_text">
Alex is not only my partner in this great adventure, but also my husband.
If it wasn’t for him, Gameleon would probably still be an unfinished project.
Even though Alex has a strong IT and engineering background, it wasn’t easy to make Gameleon work as a new website.
All the material available here – as well as the website itself – is made from scratch.
I designed the website, the activities and games. And Alex took care of the programming part.
He created the website from the start, making sure that it would work.
And it wasn’t an easy process since no templates were used! So I owe it to him that my long-time dream finally came true!
</div>
<p/>
<div className="information_text">
If you like our work, respect our licensing and we will be able to add more content and games to our site! Thanks!
            </div>
          </div>
        </Col>
        <Col xs={1}/>
      </Row>
    </Row>
  );

  return (
    <Support no_extension_color waves={waves} hideSearch={hideSearch} children={text}/>
  );
}

export default About;
