// -*- coding: utf-8 -*-

import React from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';

//https://www.npmjs.com/package/react-responsive-carousel

function PdfRespCarousel({photos, imgWidth, pageLevel, children}) {

  function carousel(){
    return (
      <Carousel className="carousel_block" useKeyboardArrows={true} dynamicHeight={false} showThumbs={false} infiniteLoop={true} showStatus={false} >
        {photos.map(([currPhotoName, currPhotoImg, currWidth, currHeight])=>
          <div key={currPhotoName} className="carousel_img">
              <img id={"img_"+currPhotoName} alt={currPhotoName} src={currPhotoImg}/>
          </div>
        )}
      </Carousel>
    )
  }

  return (
    <React.Fragment>
        {carousel()}
    </React.Fragment>
  );
};

export default PdfRespCarousel;
